import { IsAuthorized } from '../authentication/isAuthorized'
import newFileFlagSvg from '../../ui-kit/assets/img/icon/new-file-flag.svg'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Grid } from '@mui/material'
import { Button } from 'devextreme-react/button'
import {
  DataGrid,
  Column,
  Sorting,
  Selection,
  Scrolling,
  LoadPanel,
  HeaderFilter,
  SearchPanel,
  Item,
  Toolbar,
  Paging,
} from 'devextreme-react/data-grid'
import './investorFiles.scss'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { investorFilesAtom, investorRelationsAtom } from '../../state/atom'
import { EventInfo } from 'devextreme/events'
import dxDataGrid from 'devextreme/ui/data_grid'
import { useFileClientApi } from '../../hooks/use-file-api'
import { File } from '../../api-client/investor-portal-client'
import { DateFormat } from '../../model/date-formats'

import {
  formatDate,
  getFileType,
  getHeaderFilterDates,
  getEntityName,
  getTaxYear,
  getPartnershipName,
  getFormattedDateWithNeverOption,
  getFormattedDateWithOutTimeStampWithNeverOption,
} from '../investor-files/fileUtilities'
import { ICellData, dgheight } from '../../model/file-model'
import { HeaderFilterDataSourceOptions } from '../../../types/types'
import { CounterDisplay } from '../reuasble-components/fileCountDisplay'
import { useCommonDialogs } from '../modal/commonDialog/common-dialog-operations'
import { useParams } from 'react-router-dom'
import { getInvestorLandingRoute } from '../utility/route-creation'
import produce from 'immer'
import { clearNewStatusOnInvestorFile } from '../utility/new-file-session-state'
import { useNavigate } from '../../hooks/useNavigationGuard'
import { permissionRequestAny } from '../../access-manager/permission-request-creation'
import { INVESTOR } from '../../model/permissions'

/** Updates the list of dates displayed in the date header filter
 * Why outside: To avoid re-render we kept outside of investorFile component.
 */
function createdDateHeaderFilter(options: HeaderFilterDataSourceOptions) {
  options.dataSource.paginate = false
  options.dataSource.postProcess = getHeaderFilterDates
}

/** calculating what to display in status column and header filter */
const calculateStatusColumn = (cellData: File) => {
  if (cellData.downloadStatus === 'downloadsuccess') {
    return 'Downloaded'
  } else {
    return 'Ready for Download'
  }
}

/**function to display new flag */
const isNewDisplay = (cellData: ICellData<File>) => {
  const fileIdsArr: number[] = []
  const fileId: number | undefined = cellData.data?.id
  fileIdsArr.push(fileId!)
  // calling API to disable newflag
  if (cellData.data?.isNew) {
    return (
      <span>
        <img src={newFileFlagSvg} width={25} height={25} alt='new icon' />
      </span>
    )
  }
}

/** Styling of Status column */
const setStatusStyle = (cellData: ICellData<File>) => {
  let cellClass = 'statusColumnBlue'
  let newStatus = 'Ready for Download'
  if (cellData.data?.downloadStatus === 'downloadsuccess') {
    cellClass = 'statusColumnGreen'
    newStatus = 'Downloaded'
  }
  return <span className={cellClass}>{newStatus}</span>
}

/** Set date added column value by formatting the date using momment.js library
 * Display the created date field as tooltip
 */
const setDateAdded = (cellData: ICellData<File>) => {
  if (cellData.rowType === 'data' && cellData.data?.createdDate) {
    const createdDate = new Date(cellData.data?.createdDate)
    const displayDate = formatDate(createdDate)
    const titleDate = getFormattedDateWithNeverOption(createdDate,DateFormat.longDateTimeFormat) 
    return <span title={titleDate}>{displayDate}</span>
  }
  return ''
}

//calculate the Ceated date for 'DateAdded' column
const calculateCreatedDate = (cellData: File) => {
  return getFormattedDateWithNeverOption(cellData.createdDate,DateFormat.isoDateTimeFormat)
}

/** Calculate Matching Criteria colum Filter expression for date filters */
function calculateCreatedDateFilterExpression(this: Column, value: any, selectedFilterOperations: string, target: string) {
  if (target === 'headerFilter') {
    return [[getFormattedDate, '=', value]];
  }

  return this.defaultCalculateFilterExpression(value, selectedFilterOperations, target);
}

function getFormattedDate(rowData: File) {
  return getFormattedDateWithOutTimeStampWithNeverOption(rowData.createdDate,DateFormat.shortDateFormat)
}

export const InvestorFiles = () => {
  const { files, isLoading } = useRecoilValue(investorFilesAtom)
  const dataGridRef = useRef<DataGrid>(null)
  const [documentCount, setDocumentCount] = useState<number>(0)
  const clientApi = useFileClientApi()
  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const [isDownloadDisable, setIsDownloadDisable] = useState<boolean>(true)
  const commonDialog = useCommonDialogs()
  const navigate = useNavigate()
  const [investorDocuments, setInvestorDocuments] = useState<File[]>([])
  const setInvestorFilesRecoilState = useSetRecoilState(investorFilesAtom)
  const { investorEntities, hasLoaded } = useRecoilValue(investorRelationsAtom)

  const { partnerId, groupId } = useParams() as {
    groupId: string
    partnerId: string
  }

  // Filter partnerships based on the client group id
  const clientPartnerships = useMemo(
    () =>
      investorEntities.investorPartnershipsView?.filter((i) =>
        i.clientGroupIds?.includes(parseInt(groupId))
      ),
    [investorEntities?.investorPartnershipsView, groupId]
  )

  /** Filtering the investor files for current (selected from landing page) investor and group */
  const filterFilesByPartnerAndGroupId = useCallback(
    (files: File[]): File[] =>
      files.filter((file) => {
        //condition to ignore if partnerEntity not mached
        if (
          file.partnerEntityId !== parseInt(partnerId) &&
          file.partnerEntityId! > 0
        ) {
          return false
        }

        // Finding the maching partnershipId
        return clientPartnerships?.some(
          (p) => p.partnershipId === file.partnershipEntityId
        )
      }),
    [partnerId, clientPartnerships]
  )

  useEffect(() => {
    //checking the partnerId from param value
    //if no then we are binding data without filter
    if (partnerId) {
      //if yes then we are filtering the record using partnerId and groupId
      setInvestorDocuments(files ? filterFilesByPartnerAndGroupId(files) : [])
    } else {
      setInvestorDocuments(files)
    }
  }, [files, filterFilesByPartnerAndGroupId, partnerId])

  /** Sets filtered records displayed in grid view */
  const setFilteredAndSortedRowsCount = useCallback(() => {
    const grid = dataGridRef.current!.instance
    const filterExpr = grid.getCombinedFilter(true)
    const dataSource = grid.getDataSource()
    const loadOptions = dataSource.loadOptions()
    dataSource.store().load({
      filter: filterExpr,
      sort: loadOptions.sort,
      group: loadOptions.group,
    })
  }, [dataGridRef])

  /** Get the list of records displayed in the InvestorFile datagrid */
  const onContentReady = (e: EventInfo<dxDataGrid>) => {
    setDocumentCount(e.component.totalCount())
    setFilteredAndSortedRowsCount()
  }

  /** enable and disable download based on selected checkboxes */
  const onSelectionChanged = (data: any) => {
    if (data.selectedRowsData.length > 0) {
      setIsDownloadDisable(false)
      setSelectedFiles(data.selectedRowsData)
    } else {
      setIsDownloadDisable(true)
    }
  }

  const downloadFile = useCallback(
    async (file: File) => {
      try {
        await clientApi.downloadFile(file)

        // Ensure the New flag is removed in our session storage.
        clearNewStatusOnInvestorFile(file.id!)

        /** updating the download status value for current file in recoil state */
        setInvestorFilesRecoilState(
          produce((draft) => {
            const fileIndex = draft.files.findIndex(
              (v) => v.externalFileId === file.externalFileId
            )
            if (fileIndex >= 0) {
              const downloadedFile = draft.files[fileIndex]
              downloadedFile.downloadStatus = 'downloadsuccess'
              downloadedFile.isNew = false
            }
          })
        )
      } catch (err) {
        commonDialog.showDialog({
          dialogType: 'error',
          title: 'Download Error',
          content: `Error downloading file: ${err}`,
        })
      }
    },
    [commonDialog, clientApi, setInvestorFilesRecoilState]
  )

  const downloadMultipleFiles = useCallback(
    async (selectedFiles: File[]) => {
      try {
        await clientApi.bulkDownloadFiles(selectedFiles)
        /** updating the download status value for all selected files in recoil state */
        selectedFiles.forEach((file: File) => {
          setInvestorFilesRecoilState(
            produce((draft) => {
              const fileIndex = draft.files.findIndex(
                (v) => v.externalFileId === file.externalFileId
              )
              const downloadedFile = draft.files[fileIndex]
              downloadedFile.downloadStatus = 'downloadsuccess'
              downloadedFile.isNew = false
            })
          )
          // Ensure the New flag is removed in our session storage
          clearNewStatusOnInvestorFile(file.id!)
        })
      } catch (err) {
        commonDialog.showDialog({
          title: 'Download Error',
          dialogType: 'error',
          content: `Error downloading file: ${err}`,
        })
      }
    },
    [commonDialog, clientApi, setInvestorFilesRecoilState]
  )

  /** making filename column a clickable download button */
  const addDownloadLink = (cellData: ICellData<File>) => {
    return (
      <span>
        <Button
          data-role='link-button'
          onClick={() => downloadFile(cellData.data!)}
          stylingMode='text'
          className='download-file-button'
        >
          {cellData.value}
        </Button>
      </span>
    )
  }

  return (
    <IsAuthorized permissions={[permissionRequestAny(INVESTOR)]}>
      <div className='ip-table investor-files-container'>
        <DataGrid
          showBorders={true}
          keyExpr='externalFileId'
          noDataText={
            (isLoading || !hasLoaded) && investorDocuments.length === 0
              ? 'Loading...'
              : !isLoading && hasLoaded && investorDocuments.length === 0
              ? 'No Data Found.'
              : ''
          }
          onContentReady={onContentReady}
          dataSource={investorDocuments}
          ref={dataGridRef}
          onSelectionChanged={onSelectionChanged}
          columnAutoWidth={false}
          height={dgheight}
          data-testid='investordatagrid'
          renderAsync={true}
          repaintChangesOnly={true}
        >
          <SearchPanel visible={true} width='400px' />
          <Scrolling mode='virtual' rowRenderingMode='virtual' />
          <Paging defaultPageSize={10} />
          <Selection mode='multiple' showCheckBoxesMode='always' />
          <Sorting mode='single' />
          <LoadPanel enabled={true} />
          <HeaderFilter visible={true} allowSearch={true} />
          <Toolbar>
            <Item location='before'>
              <Grid
                container
                item
                xs={10}
                minWidth={800}
                justifyContent={'flex-start'}
              >
                <Grid
                  item
                  xs={0.5}
                  className={!partnerId ? 'hide-back-arrow' : 'show-back-arrow'}
                >
                  <Button
                    onClick={() => navigate(getInvestorLandingRoute())}
                    stylingMode='outlined'
                    data-testid='btnBack'
                  >
                    <span className='dx-icon-arrowleft'></span>
                  </Button>
                </Grid>
                <Grid item xs={1.5} className='document-count-style'>
                  <CounterDisplay
                    count={documentCount}
                    isVisible={!isLoading}
                    title='Documents'
                  />
                </Grid>
                <Grid item xs={8}>
                  <label>{/* Empty grid for stying purpose */}</label>
                </Grid>
              </Grid>
            </Item>
            <Item name='searchPanel' data-testid='searchbar' />
            <Item location='after' cssClass='item-multiple-download-button'>
              <Button
                type='default'
                width='150px'
                stylingMode='contained'
                disabled={isDownloadDisable}
                icon='download'
                text='Download'
                onClick={() => {
                  if (!isDownloadDisable) {
                    downloadMultipleFiles(selectedFiles)
                  }
                }}
                id='multipleDownloadButton'
              />
            </Item>
          </Toolbar>
          <Column
            dataField='externalFileName'
            caption='File Name'
            allowSearch={true}
            allowSorting={true}
            cellRender={addDownloadLink}
            width={'22%'}
          ></Column>
          <Column cellRender={isNewDisplay} width={'4%'}></Column>
          <Column
            caption='File Type/Tag'
            allowSearch={true}
            allowSorting={true}
            allowFiltering={true}
            calculateCellValue={getFileType}
            width={'8%'}
          ></Column>
          <Column
            caption='Tax Year'
            allowSearch={true}
            allowSorting={true}
            allowFiltering={true}
            calculateCellValue={getTaxYear}
            width={'8%'}
          ></Column>
          <Column
            caption='Status'
            allowSearch={true}
            allowSorting={true}
            allowFiltering={true}
            cellRender={setStatusStyle}
            calculateCellValue={calculateStatusColumn}
            width={'15%'}
          ></Column>
          <Column
            caption='Investor'
            allowSearch={true}
            allowSorting={true}
            allowFiltering={true}
            calculateCellValue={getEntityName}
            width={'14%'}
          ></Column>
          <Column
            caption='Date Added'
            allowSearch={true}
            allowSorting={true}
            cellRender={setDateAdded}
            data-includeTooltip={false}
            calculateCellValue={calculateCreatedDate}
            allowFiltering={true}
            width={'11%'}
            defaultSortOrder='desc'
            calculateFilterExpression={calculateCreatedDateFilterExpression}
          >
            <HeaderFilter dataSource={createdDateHeaderFilter} />
          </Column>
          <Column
            caption='Investment'
            allowSearch={true}
            allowSorting={true}
            calculateCellValue={getPartnershipName}
            allowFiltering={true}
            width={'18%'}
          ></Column>
        </DataGrid>
      </div>
    </IsAuthorized>
  )
}
