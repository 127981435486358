import { Button } from 'devextreme-react'
import { getLandingPageRoute } from '../utility/route-creation'
import { useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import {
  entityGroupPartnershipStateAtom,
  appSettingsAtom,
} from '../../state/atom'
import { EnabledFeature } from '../authentication/enabledFeature'
import { Grid } from '@mui/material'
import { StateManagerInvestorPage } from './state-manager-investor-page'
import { StateManagerEntityPage } from './state-manager-entity-page'
import { useNavigate } from '../../hooks/useNavigationGuard'
import { CounterDisplay } from '../reuasble-components/fileCountDisplay'
import { useCallback, useState } from 'react'
import * as Permissions from '../../model/permissions'
import { permissionRequestClientById } from '../../access-manager/permission-request-creation'
import { useAuthorizedPermission } from '../../access-manager/use-authorized-permission'

/** State Manager Election Page */
export const StateManagerElection = () => {
  const navigate = useNavigate()
  const { groupId, taxYear } = useParams() as {
    groupId: string
    taxYear: string
  }
  const groupIdValue = parseInt(groupId)
  // Enable StateManager Page with State Elections based on the flag from recoil state
  const entityClientGroup = useRecoilValue(entityGroupPartnershipStateAtom)
  const appState = useRecoilValue(appSettingsAtom)
  const clientState =
    entityClientGroup && !isNaN(groupIdValue)
      ? entityClientGroup[groupIdValue]
      : undefined
  const elections = !!clientState?.featureFlag?.stateElections
  const [investorCount, setInvestorCount] = useState<number>(0)

  /** Create Request to check whether the Page it authorized when retrieved
   Redirect to the Landing Page if there is no permission */
   useAuthorizedPermission([
    permissionRequestClientById(
      Permissions.PAGE_VISIBILITY_STATEMAN ,
      groupIdValue
    ),
  ])

  // Callback function to update counter count
  const updateCounterCount = useCallback((newCount: number) => {
    setInvestorCount(newCount)
  }, [])

  /** To set the current view */
  const appStateKey = `StateManagerAdmin_${groupId}_${taxYear}`
  const currentView = appState?.viewOptions[appStateKey]
    ? appState?.viewOptions[appStateKey]
    : 'entity'
  return (
    <EnabledFeature isFeatureEnabled={elections!}>
      <div className='state-manager-entity-view'>
        <div className='align-header'>
          <Grid container spacing={1}>
            <Grid item xs={2} className='status-button-container'>
              <Button
                onClick={() => navigate(getLandingPageRoute())}
                stylingMode='outlined'
                data-testid='Backbutton'
              >
                <span className='dx-icon-arrowleft'></span>
              </Button>
              {currentView === 'entity' ? (
                <span className='entity-span'> State Manager</span>
              ) : (
                <CounterDisplay
                  count={investorCount}
                  isVisible={true}
                  title='State Manager'
                />
              )}
            </Grid>
            <Grid item xs={1.5}></Grid>
          </Grid>
        </div>
        {currentView === 'entity' ? (
          <StateManagerEntityPage />
        ) : (
          <StateManagerInvestorPage updateCounterCount={updateCounterCount} />
        )}
      </div>
    </EnabledFeature>
  )
}
