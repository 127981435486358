import { useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import { clientAndPartnershipStateAtom } from '../../../state/atom'
import { getEntityManagerRoute } from '../../utility/route-creation'
import { useNavWidgetPageItems } from '../nav-hooks'
import { CLIENT_GROUP_MENU_LEVEL } from '../widget-levels'
import { REACT_APP_FEATURES_ENTITYMANAGER } from '../../.././envVariables'
import * as Permissions from '../../../model/permissions'
import {
  useHasPermission,
} from '../../../access-manager/use-permission'
import { permissionRequestClientById } from '../../../access-manager/permission-request-creation'

export const useEntityManagerNavWidget = () => {
  const clientAndPartnership = useRecoilValue(clientAndPartnershipStateAtom)

  const hasPermission = useHasPermission()

  const hasClientAccess = !!(
    clientAndPartnership.clientGroupId &&
    hasPermission(
      permissionRequestClientById(
        Permissions.PAGE_VISIBILITY_ENTITYMAN,
        clientAndPartnership.clientGroupId
      )
    )
  )

  // enable entity manager feature based on environment variable
  const enableEntityManager = REACT_APP_FEATURES_ENTITYMANAGER ?? false

  /** configuring path to redirect */
  const navPathEntity = useMemo(() => {
    let route: string | undefined
    if (clientAndPartnership.clientGroupId) {
      route = getEntityManagerRoute(clientAndPartnership.clientGroupId)
    } else {
      route = undefined
    }
    return route
  }, [clientAndPartnership.clientGroupId])

  /** adding an item to nav widget*/
  const navItems = useMemo(
    () => [
      {
        label: 'Entity Manager',
        level: CLIENT_GROUP_MENU_LEVEL,
        visibilityPaths:
          enableEntityManager && hasClientAccess && 'admin/groups/:groupId/*',
        selectionPath: navPathEntity + '/*',
        navigationPath: navPathEntity,
        sortOrder: 0,
      },
    ],
    [enableEntityManager, navPathEntity, hasClientAccess]
  )

  useNavWidgetPageItems(navItems)
}
