import { SelectBoxOptions } from '../../../types/types'

/** Temporary DataType for Entity Table */
export interface EntityViewModel {
  isRsmClient?: boolean
  id?: number
  entityName?: string
  associatedEntities?: string[]
  entityType?: string
  emails?: string[]
}

/** Tab properties */
interface ITabDetail {
  id: number
  text: string
}

//Represents the  entity types
export type EntityType = 'allocating-entity' | 'investor' | undefined

//Represents the  view state
export type ViewState = 'entity-selector' | 'entity-editor' | undefined

//Represents the bulk upload view state
export type BulkUploadViewState = 'entity-selector' | 'file-upload' | undefined

/**Interface for EntityManagerState */
export interface EntityManagerState {
  entityType?: EntityType
  entityData?: EntityViewModel
  viewState?: ViewState
}

/**Interface for Bulk entity upload detail*/
export interface BulkUploadDetail {
  isRsmClient?: boolean
  clientGroupId?: number
  overrideEmail?: boolean
}

/**Interface for BulkUploadEntityManagerState */
export interface BulkUploadEntityManagerState {
  entityType?: EntityType
  bulkUploadDetail?: BulkUploadDetail
  viewState?: BulkUploadViewState
}

/**  Available entity types for the radio group buttons.*/
export const getEntityTypeItems = (disableInvestor: boolean, disableAllocatingEntity: boolean) => {
  return [
    { label: 'Allocating Entity', value: 'allocatingEntity', disabled: !!disableAllocatingEntity },
    { label: 'Investor', value: 'investor', disabled: !!disableInvestor },
  ]
}

/**  Available business types for the radio group buttons.*/
export const businessTypeItems: SelectBoxOptions[] = [
  { label: 'Individual', value: 'Individual' },
  { label: 'Business', value: 'Business' },
]

/**  SSN options */
export const ssnSelectOptions: SelectBoxOptions[] = [
  { label: 'Applied For', value: 'Applied For' },
  { label: 'Foreign', value: 'Foreign' },
  { label: 'Tax Exempt', value: 'Tax Exempt' },
]

/** Tax classification types */
export const taxClassificationTypes: SelectBoxOptions[] = [
  { label: 'Individual', value: 'Individual' },
  { label: 'Corporation', value: 'Corporation' },
  { label: 'Partnership', value: 'Partnership' },
  { label: 'S Corporation', value: 'S Corporation' },
  { label: 'Estate', value: 'Estate' },
  { label: 'Trust', value: 'Trust' },
  { label: 'Grantor Trust', value: 'Grantor Trust' },
  { label: 'Exempt Organization', value: 'Exempt Organization' },
  { label: 'Limited Liability Company', value: 'Limited Liability Company' },
  {
    label: 'Limited Liability Partnership',
    value: 'Limited Liability Partnership',
  },
  { label: 'IRA', value: 'IRA' },
]

/**Tab Details for Add Edit Entity Modal */
export const entityTabs: ITabDetail[] = [
  {
    id: 0,
    text: 'Entity Information',
  },
  {
    id: 1,
    text: 'Email Address',
  },
  {
    id: 2,
    text: 'Tax Address',
  },
  {
    id: 3,
    text: 'Compliance',
  },
]

/** List pf countries */
export const countryTypes = [
  { label: 'United States of America', value: 'US' },
  { label: 'Afghanistan', value: 'AF' },
  { label: 'Akrotira Sovereign Base Area', value: 'AX' },
  { label: 'Aland Island', value: 'XI' },
  { label: 'Albania', value: 'AL' },
  { label: 'Algeria', value: 'AG' },
  { label: 'American Samoa', value: 'AQ' },
  { label: 'Anarctic Land', value: 'CL' },
  { label: 'Andorra', value: 'AN' },
  { label: 'Angola', value: 'AO' },
  { label: 'Anguilla', value: 'AV' },
  { label: 'Antarctica', value: 'AY' },
  { label: 'Antigua & Barbuda', value: 'ANB' },
  { label: 'Argentina', value: 'AR' },
  { label: 'Armenia', value: 'AM' },
  { label: 'Aruba', value: 'AA' },
  { label: 'Ascension', value: 'XA' },
  { label: 'Ashmore and Cartier Islands', value: 'AI' },
  { label: 'Australia', value: 'AS' },
  { label: 'Austria', value: 'AU' },
  { label: 'Azerbaijan', value: 'AJ' },
  { label: 'Azores', value: 'XZ' },
  { label: 'Bahamas', value: 'BF' },
  { label: 'Bahrain', value: 'BA' },
  { label: 'Baker Island', value: 'FQ' },
  { label: 'Balearic Islands', value: 'BI' },
  { label: 'Bangladesh', value: 'BG' },
  { label: 'Barbados', value: 'BB' },
  { label: 'Barbuda', value: 'AC' },
  { label: 'Bassas da India', value: 'BS' },
  { label: 'Belarus', value: 'BO' },
  { label: 'Belgium', value: 'BE' },
  { label: 'Belize', value: 'BH' },
  { label: 'Benin', value: 'BN' },
  { label: 'Bermuda', value: 'BD' },
  { label: 'Bhutan', value: 'BT' },
  { label: 'Bolivia', value: 'BL' },
  { label: 'Bonaire & Curacao', value: 'CC' },
  { label: 'Bosnia-Herzegovina', value: 'BK' },
  { label: 'Botswana', value: 'BC' },
  { label: 'Bouvet Island', value: 'BV' },
  { label: 'Brazil', value: 'BR' },
  { label: 'British Indian Ocean Territory', value: 'IO' },
  { label: 'British Virgin Islands', value: 'VI' },
  { label: 'Brunei', value: 'BX' },
  { label: 'Bulgaria', value: 'BU' },
  { label: 'Burkina Faso', value: 'UV' },
  { label: 'Burma', value: 'BM' },
  { label: 'Burundi', value: 'BY' },
  { label: 'Cambodia', value: 'CB' },
  { label: 'Cameroon', value: 'CM' },
  { label: 'Canada', value: 'CA' },
  { label: 'Canary Islands', value: 'XY' },
  { label: 'Cape Verde', value: 'CV' },
  { label: 'Cartier Islands', value: 'AT' },
  { label: 'Cayman Islands', value: 'CJ' },
  { label: 'Central African Republic', value: 'CT' },
  { label: 'Chad', value: 'CD' },
  { label: 'Channel Islands', value: 'XC' },
  { label: 'Chile', value: 'CI' },
  { label: 'China', value: 'CH' },
  { label: 'Christmas Island', value: 'KT' },
  { label: 'Clipperton Island', value: 'IP' },
  { label: 'Cocos (Keeling) Islands', value: 'CK' },
  { label: 'Colombia', value: 'CO' },
  { label: 'Comoros', value: 'CN' },
  { label: 'Congo (Brazzaville)', value: 'CF' },
  { label: 'Congo (Kinshasa)', value: 'CON' },
  { label: 'Cook Islands', value: 'CW' },
  { label: 'Coral Sea Islands', value: 'CR' },
  { label: 'Corsica', value: 'VP' },
  { label: 'Costa Rica', value: 'CS' },
  { label: "Cote D'Ivoire (Ivory Coast)", value: 'IV' },
  { label: 'Croatia', value: 'HR' },
  { label: 'Cuba', value: 'CU' },
  { label: 'Curacao', value: 'UC' },
  { label: 'Cyprus', value: 'CY' },
  { label: 'Czech Republic', value: 'EZ' },
  { label: 'Denmark', value: 'DA' },
  { label: 'Dhekelia', value: 'DX' },
  { label: 'Djibouti', value: 'DJ' },
  { label: 'Dominica', value: 'DO' },
  { label: 'Dominican Republic', value: 'DR' },
  { label: 'Dubai', value: 'DB' },
  { label: 'East Timor', value: 'ES' },
  { label: 'Ecuador', value: 'EC' },
  { label: 'Egypt', value: 'EG' },
  { label: 'El Salvador', value: 'EL' },
  { label: 'Equatorial Guinea', value: 'EK' },
  { label: 'Eritrea', value: 'ER' },
  { label: 'Estonia', value: 'EN' },
  { label: 'Ethiopia', value: 'ET' },
  { label: 'Falkland Islands (Islas Malvinas)', value: 'FK' },
  { label: 'Faroe Islands', value: 'FO' },
  { label: 'Federated States of Micronesia', value: 'FT' },
  { label: 'Fiji', value: 'FJ' },
  { label: 'Finland', value: 'FI' },
  { label: 'France', value: 'FR' },
  { label: 'French Guiana', value: 'FG' },
  { label: 'French Polynesia', value: 'FP' },
  { label: 'French Southern and Antarctic Lands', value: 'FS' },
  { label: 'Gabon', value: 'GB' },
  { label: 'Georgia', value: 'GG' },
  { label: 'Germany', value: 'GM' },
  { label: 'Ghana', value: 'GH' },
  { label: 'Gibraltar', value: 'GI' },
  { label: 'Greece', value: 'GR' },
  { label: 'Greenland', value: 'GL' },
  { label: 'Grenada', value: 'GJ' },
  { label: 'Guam', value: 'GQ' },
  { label: 'Guatemala', value: 'GT' },
  { label: 'Guernsey', value: 'GK' },
  { label: 'Guinea', value: 'GV' },
  { label: 'Guinea-Bissau', value: 'PU' },
  { label: 'Guyana', value: 'GY' },
  { label: 'Haiti', value: 'HA' },
  { label: 'Heard Island and McDonald Islands', value: 'HM' },
  { label: 'Holy See', value: 'VT' },
  { label: 'Honduras', value: 'HO' },
  { label: 'Hong Kong', value: 'HK' },
  { label: 'Howland Island', value: 'HQ' },
  { label: 'Hungary', value: 'HU' },
  { label: 'Iceland', value: 'IC' },
  { label: 'Inactive - MW', value: 'MW' },
  { label: 'Inactive - TC', value: 'TC' },
  { label: 'India', value: 'IN' },
  { label: 'Indonesia', value: 'ID' },
  { label: 'Iran', value: 'IR' },
  { label: 'Iraq', value: 'IZ' },
  { label: 'Ireland', value: 'EI' },
  { label: 'Israel', value: 'IS' },
  { label: 'Italy', value: 'IT' },
  { label: 'Jamaica', value: 'JM' },
  { label: 'Jan Mayen', value: 'JN' },
  { label: 'Japan', value: 'JP' },
  { label: 'Jarvis Island', value: 'DQ' },
  { label: 'Jersey', value: 'JE' },
  { label: 'Johnston Atoll', value: 'JQ' },
  { label: 'Jordan', value: 'JO' },
  { label: 'Juan De Nova Island', value: 'JU' },
  { label: 'Kazakhstan', value: 'KZ' },
  { label: 'Kenya', value: 'KE' },
  { label: 'Kingman Reef', value: 'KQ' },
  { label: 'Kiribati', value: 'KR' },
  { label: 'Korea, Democratic Peoples Republic of (North)', value: 'KN' },
  { label: 'Korea, Republic of (South)', value: 'KS' },
  { label: 'Kosovo', value: 'KV' },
  { label: 'Kurile Islands', value: 'RS' },
  { label: 'Kuwait', value: 'KU' },
  { label: 'Kyrgyzstan', value: 'KG' },
  { label: 'Laos', value: 'LA' },
  { label: 'Latvia', value: 'LG' },
  { label: 'Lebanon', value: 'LE' },
  { label: 'Lesotho', value: 'LT' },
  { label: 'Liberia', value: 'LI' },
  { label: 'Libya', value: 'LY' },
  { label: 'Liechtenstein', value: 'LS' },
  { label: 'Lithuania', value: 'LH' },
  { label: 'Luxembourg', value: 'LU' },
  { label: 'Macau', value: 'MC' },
  { label: 'Macedonia', value: 'MK' },
  { label: 'Madagascar', value: 'MA' },
  { label: 'Malawi', value: 'MI' },
  { label: 'Malaysia', value: 'MY' },
  { label: 'Maldives', value: 'MV' },
  { label: 'Mali', value: 'ML' },
  { label: 'Malta', value: 'MT' },
  { label: 'Man, Isle of', value: 'IM' },
  { label: 'Marshall Islands', value: 'RM' },
  { label: 'Mauritania', value: 'MR' },
  { label: 'Mauritius', value: 'MP' },
  { label: 'McDonald Island', value: 'MS' },
  { label: 'Mexico', value: 'MX' },
  { label: 'Micronesia, Federated States', value: 'FM' },
  { label: 'Midway Islands', value: 'MQ' },
  { label: 'Moldova', value: 'MD' },
  { label: 'Monaco', value: 'MN' },
  { label: 'Mongolia', value: 'MG' },
  { label: 'Montenegro', value: 'MJ' },
  { label: 'Montserrat', value: 'MH' },
  { label: 'Morocco', value: 'MO' },
  { label: 'Mozambique', value: 'MZ' },
  { label: 'Myanmar', value: 'XM' },
  { label: 'Namibia', value: 'WA' },
  { label: 'Nauru', value: 'NR' },
  { label: 'Navassa Island', value: 'BQ' },
  { label: 'Nepal', value: 'NP' },
  { label: 'Netherlands', value: 'NL' },
  { label: 'Netherlands Antilles', value: 'NT' },
  { label: 'New Caledonia', value: 'NC' },
  { label: 'New Zealand', value: 'NZ' },
  { label: 'Nicaragua', value: 'NU' },
  { label: 'Niger', value: 'NG' },
  { label: 'Nigeria', value: 'NI' },
  { label: 'Niue', value: 'NE' },
  { label: 'Norfolk Island', value: 'NF' },
  { label: 'Northern Ireland', value: 'XN' },
  { label: 'Northern Mariana Islands', value: 'CQ' },
  { label: 'Norway', value: 'NO' },
  { label: 'Oman', value: 'MU' },
  { label: 'Other Country', value: 'XX' },
  { label: 'Pakistan', value: 'PK' },
  { label: 'Palau', value: 'PS' },
  { label: 'Palmyra Atoll', value: 'LQ' },
  { label: 'Panama', value: 'PM' },
  { label: 'Papua-New Guinea', value: 'PNG' },
  { label: 'Paracel Islands', value: 'PF' },
  { label: 'Paraguay', value: 'PA' },
  { label: 'Peru', value: 'PE' },
  { label: 'Philippines', value: 'RP' },
  { label: 'Pitcairn Islands', value: 'PC' },
  { label: 'Poland', value: 'PL' },
  { label: 'Portugal', value: 'PO' },
  { label: 'Principe', value: 'PP' },
  { label: 'Puerto Rico', value: 'RQ' },
  { label: 'Qatar', value: 'QA' },
  { label: 'Reunion', value: 'RE' },
  { label: 'Romania', value: 'RO' },
  { label: 'Russia', value: 'RU' },
  { label: 'Rwanda', value: 'RW' },
  { label: 'Ryukyu Islands', value: 'JA' },
  { label: 'Saint Barthelemy', value: 'TB' },
  { label: 'Saint Maarten', value: 'NN' },
  { label: 'Saint Martin', value: 'RN' },
  { label: 'Samoa', value: 'WS' },
  { label: 'San Marino', value: 'SM' },
  { label: 'Sao Tome and Principe', value: 'TP' },
  { label: 'Saudi Arabia', value: 'SA' },
  { label: 'Scotland', value: 'XS' },
  { label: 'Senegal', value: 'SG' },
  { label: 'Serbia', value: 'RI' },
  { label: 'Seychelles', value: 'SE' },
  { label: 'Sierra Leone', value: 'SL' },
  { label: 'Singapore', value: 'SN' },
  { label: 'Slovakia', value: 'LO' },
  { label: 'Slovenia', value: 'SI' },
  { label: 'Solomon Islands', value: 'BP' },
  { label: 'Somalia', value: 'SO' },
  { label: 'South Africa', value: 'SF' },
  { label: 'South Georgia and the South Sandwich Islands', value: 'SX' },
  { label: 'South Sudan', value: 'OD' },
  { label: 'Spain', value: 'SP' },
  { label: 'Spratly Islands', value: 'PG' },
  { label: 'Sri Lanka', value: 'CE' },
  { label: 'St. Helena', value: 'SH' },
  { label: 'St. Kitts and Nevis', value: 'SC' },
  { label: 'St. Lucia Island', value: 'ST' },
  { label: 'St. Pierre and Miquelon', value: 'SB' },
  { label: 'St. Vincent and the Grenadines', value: 'VC' },
  { label: 'Sudan', value: 'SU' },
  { label: 'Suriname', value: 'NS' },
  { label: 'Svalbard', value: 'SV' },
  { label: 'Swaziland', value: 'WZ' },
  { label: 'Sweden', value: 'SW' },
  { label: 'Switzerland', value: 'SZ' },
  { label: 'Syria', value: 'SY' },
  { label: 'Taiwan', value: 'TW' },
  { label: 'Tajikistan', value: 'TI' },
  { label: 'Tanzania', value: 'TZ' },
  { label: 'Thailand', value: 'TH' },
  { label: 'The Gambia', value: 'GA' },
  { label: 'Togo', value: 'TO' },
  { label: 'Tokelau', value: 'TL' },
  { label: 'Tonga', value: 'TN' },
  { label: 'Tortola', value: 'TT' },
  { label: 'Trinidad and Tobago', value: 'TD' },
  { label: 'Tristan Da Cunha', value: 'XT' },
  { label: 'Tunisia', value: 'TS' },
  { label: 'Turkey', value: 'TU' },
  { label: 'Turkmenistan', value: 'TX' },
  { label: 'Turks and Caicos Islands', value: 'TK' },
  { label: 'Tuvalu', value: 'TV' },
  { label: 'Uganda', value: 'UG' },
  { label: 'Ukraine', value: 'UP' },
  { label: 'United Arab Emirates', value: 'AE' },
  {
    label: 'United Kingdom (England, Northern Ireland, Scotland, and Wales)',
    value: 'UK',
  },
  { label: 'Uruguay', value: 'UY' },
  { label: 'Uzbekistan', value: 'UZ' },
  { label: 'Vanuatu', value: 'NH' },
  { label: 'Venezuela', value: 'VE' },
  { label: 'Vietnam', value: 'VM' },
  { label: 'Virgin Islands (US)', value: 'VQ' },
  { label: 'Wake Island', value: 'WQ' },
  { label: 'Wales', value: 'XW' },
  { label: 'Wallis and Futuna', value: 'WF' },
  { label: 'Western Sahara', value: 'WI' },
  { label: 'Yemen (Aden)', value: 'YM' },
  { label: 'Yugoslavia', value: 'YI' },
  { label: 'Zaire', value: 'CG' },
  { label: 'Zambia', value: 'ZA' },
  { label: 'Zimbabwe', value: 'ZI' },
]

/** List of US states */
export const stateTypes = [
  { value: 'AA', label: 'Armed Forces America' },
  { value: 'AE', label: 'Armed Forces' },
  { value: 'AP', label: 'Armed Forces Pacific' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AL', label: 'Alabama' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DC', label: 'Washington DC' },
  { value: 'DE', label: 'Delaware' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'GU', label: 'Guam' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'IA', label: 'Iowa' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MD', label: 'Maryland' },
  { value: 'ME', label: 'Maine' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MT', label: 'Montana' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NY', label: 'New York' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VA', label: 'Virginia' },
  { value: 'VI', label: 'Virgin Islands' },
  { value: 'VT', label: 'Vermont' },
  { value: 'WA', label: 'Washington' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WY', label: 'Wyoming' },
]

/** Represents the is email Override  */
export type overrideType = 'append' | 'replace' | undefined

/** Is email override radio button options.*/
export const emailOverrideItems: SelectBoxOptions[] = [
  { label: 'Replace', value: 'replace' },
  { label: 'Append', value: 'append' },
]