import { useState } from 'react'
import { ButtonFunction, Modal } from '../../modal/modalBase'
import {
  EntityType,
  emailOverrideItems,
  overrideType,
  getEntityTypeItems,
} from '../entity-utils'
import RadioGroup from 'devextreme-react/radio-group'
import './upload-entity-type.scss'
import { useRecoilValue } from 'recoil'
import { clientAndPartnershipStateAtom } from '../../../state/atom'
import { ValueChangedInfo } from 'devextreme/ui/editor/editor'
import { useParams } from 'react-router-dom'
import { useEntityManagerPermissions } from '../use-entity-manager-permissions'

interface UploadEntityTypeProps {
  onEntityTypeAndOverrideSelected: (
    entityType: EntityType,
    isOverride: boolean
  ) => void // Callback when an entity type is selected.
  onCancel: () => void
}

/** EntityTypeSelector to add new entity. */
export const UploadEntityType = ({
  onEntityTypeAndOverrideSelected,
  onCancel,
}: UploadEntityTypeProps) => {
  const [selectedEntityType, setSelectedEntityType] =
    useState<EntityType>('investor')
  const [selectedEmailOverrideType, setSelectedEmailOverrideType] =
    useState<overrideType>('append')

  const clientGroupInfo = useRecoilValue(clientAndPartnershipStateAtom)
  const [isOverride, setIsOverride] = useState<boolean>(false)

  // Function to update the selected entity type and invoke the callback.
  const updateEntityType = () => {
    if (selectedEntityType) {
      onEntityTypeAndOverrideSelected(selectedEntityType, isOverride)
    }
  }

  // Get group id
  const { groupId } = useParams() as {
    groupId: string
  }
  const groupIdValue = parseInt(groupId)

  const permissions = useEntityManagerPermissions(groupIdValue)

  // Create the buttons for the Modal.
  const buttonFunctions: ButtonFunction[] = [
    {
      label: 'Cancel',
      onClick: () => {
        onCancel()
      },
      isDefaultAction: true,
      buttonProps: {
        className: 'cancel-btn',
        stylingMode: 'contained',
        type: 'normal',
      },
    },
    {
      label: 'Next',
      onClick: () => {
        updateEntityType()
      },
      buttonProps: {
        className: 'next-btn',
        stylingMode: 'contained',
        type: 'default',
      },
      isDisabled: !selectedEntityType,
    },
  ]

  /** Handle override radio button click  */
  const handleEmailOverrideRadio = (e: ValueChangedInfo) => {
    setSelectedEmailOverrideType(e.value)
    setIsOverride(e.value === 'replace')
  }

  const entityTypeItems = getEntityTypeItems(
    !permissions.canUploadBulkInvestorSheet,
    !permissions.canUploadBulkAllocatingSheet
  )

  return (
    <Modal
      visible={true}
      title={'Upload Bulk - ' + clientGroupInfo.clientGroup?.name}
      maxHeight={280}
      maxWidth={500}
      showCloseButtonInTitle={true}
      buttonFunctions={buttonFunctions}
      preventModalClose={true}
      className='add-entity-modal'
    >
      <div className='add-entity-container'>
        <div className='label-font'>Allocating Entity or Investor</div>
        <RadioGroup
          items={entityTypeItems}
          value={selectedEntityType}
          valueExpr='value'
          displayExpr='label'
          onValueChanged={(e) => setSelectedEntityType(e.value)}
          layout='horizontal'
          focusStateEnabled={false}
          className='add-entity-radio'
        />
      </div>
      <div className='mail-override-container'>
        <div className='label-font'>Email Addresses</div>
        <RadioGroup
          items={emailOverrideItems}
          value={selectedEmailOverrideType}
          valueExpr='value'
          displayExpr='label'
          onValueChanged={(e) => {
            handleEmailOverrideRadio(e)
          }}
          layout='horizontal'
          focusStateEnabled={false}
          className='mail-override-radio'
        />
      </div>
    </Modal>
  )
}
