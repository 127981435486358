import produce from 'immer'
import { useParams } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import { filesClientApi } from '../api-client/investor-portal-client-runtime'
import {
  userEntityGroupState,
  entityGroupPartnershipStateAtom,
  userProfileFromIdmAtom,
  clientAndPartnershipStateAtom,
  LoadingStateEnum,
} from '../state/atom'
import { EntityClients } from '../api-client/investor-portal-client'
import { useEffect } from 'react'
import { DISMOUNT_ABORT_REASON } from '../components/utility/abort-constants'
import { getApiCallId, isLatestApiCall } from './api-state-management'
import { usePermissions } from '../access-manager/use-permission'
import { permissionRequestAny } from '../access-manager/permission-request-creation'
import { ADMIN } from '../model/permissions'

/** Returns the information specified by the clientId and groupId in the URL. */
export function useClientAndPartnershipFromUrl() {
  const [userEntityGroups, setUserEntityGroups] =
    useRecoilState(userEntityGroupState)
  const [entityGroupPartnerships, setEntityGroupPartnerships] = useRecoilState(
    entityGroupPartnershipStateAtom
  )
  const { userProfileResult } = useRecoilValue(userProfileFromIdmAtom)
  const [clientPartnerships, setClientPartnerships] = useRecoilState(
    clientAndPartnershipStateAtom
  )

  const isAdmin = usePermissions([permissionRequestAny(ADMIN)])

  // Get the groupId and clientId from the URL.
  const { groupId, partnershipId } = useParams() as {
    groupId: string
    partnershipId: string
  }

  // Convert the client ID and group ID to numbers.
  const partnershipIdNumber = parseInt(partnershipId)
  const groupIdNumber = parseInt(groupId)

  const userId: number = userProfileResult.id ? userProfileResult.id : 0

  // This effect only needs to be called once, since it's getting all of the client groups
  //  for the current user.  Those should not change throughout the session.
  useEffect(() => {
    async function updateClientGroups() {
      const callId = getApiCallId('updateClientGroups-useClientAndPartnershipFromUrl')

      // Update the state, indicating that we're getting the values from the server.
      setUserEntityGroups((userEntityGroups) => {
        return produce(userEntityGroups, (draft) => {
          draft.isLoading = true
        })
      })

      //check userId should be greater then 0
      if (userId > 0) {
        // Get the groups for this user.
        const groups = await filesClientApi.entityGroups_GetClientsGroups(
          userId
        )

        if (isLatestApiCall(callId)) {
          // Update the groups in the state.
          setUserEntityGroups((userEntityGroups) => {
            return produce(userEntityGroups, (draft) => {
              draft.isInitialized = true
              draft.isLoading = false
              draft.groups = groups
            })
          })
        }
      }
    }

    // We only want to update the client groups if the state isn't loading and
    //  isn't initialized.
    //  isn't undefined userProfileResult.id
    if (
      !userEntityGroups.isInitialized &&
      !userEntityGroups.isLoading &&
      userId > 0
    ) {
      updateClientGroups()
    }
  }, [userId])

  // This effect handles getting the partnerships for the currently selected entity group.
  useEffect(() => {
    const abortController = new AbortController()

    async function updatePartnerships() {
      // If the entity groups aren't ready yet, then we can't do anything.
      if (!userEntityGroups.isInitialized) {
        return
      }

      // This may not be the best function to use for the call ID
      //  but we can't use useEffect, and it's safe enough.
      const callId = getApiCallId('updatePartnerships-useClientAndPartnershipFromUrl')

      // Get the state for the current client.
      const clientState = entityGroupPartnerships[groupIdNumber]

      // If we don't have one, and it's not being loaded, then we need to load it.
      if (!clientState || clientState.loadingState !== LoadingStateEnum.Loading) {
        setClientPartnerships(
          produce((draft) => {
            draft.partnership = undefined
            draft.partnershipId = undefined
          })
        )

        // Update the state to indicate that we're currently loading the value.
        setEntityGroupPartnerships(
          produce((draft) => {
            if (!clientState) {
              draft[groupIdNumber] = {
                clients: [],
                featureFlag: {},
                loadingState: LoadingStateEnum.Loading,
              }
            } else {
              draft[groupIdNumber].loadingState = LoadingStateEnum.Loading
            }
          })
        )

        let response: EntityClients
        try {
          response = await filesClientApi.entityGroups_GetEntityByGroupId(
            groupIdNumber,
            abortController.signal
          )

          if (isLatestApiCall(callId)) {
            // Update the values with the new response.
            setEntityGroupPartnerships(
              produce((draft) => {
                draft[groupIdNumber].loadingState = LoadingStateEnum.Loaded
                draft[groupIdNumber].clients = response.clients!
                draft[groupIdNumber].featureFlag = response.featureFlags!
              })
            )
          }
        } catch (err) {
          if (isLatestApiCall(callId)) {
            // Catch any error from the server.  This probably signifies that the user does not have
            //  access to the desired client group (clientIdNumber).
            // If the call was aborted, then we're not going to set this as an error.  We'll just
            //  reinitialize the state.
            if (err && /^\s*abort\s*:/i.test(err.toString())) {
              setEntityGroupPartnerships(
                produce(draft => {
                  // Using any, because there's no other way to reinitialize the state.
                  draft[groupIdNumber] = undefined as any
                }))
            } else {
              setEntityGroupPartnerships(
                produce((draft) => {
                  draft[groupIdNumber].loadingState = LoadingStateEnum.Error
                })
              )

            }
          }

          // Exit early.  More should probably be done after this, but the current implementation
          //  solves the bug immediately at hand.
          console.error(err)
          console.error(
            `Error occurred during the entityGroups_GetEntityByGroupId call.  The user may not have access to the desired client group ${groupIdNumber}.`
          )
          return
        }

        // Get the client group and active partnership.
        const clientGroup = userEntityGroups.groups.find(
          (g) => g.id === groupIdNumber
        )
        const partnership = response?.clients?.find((p) => p.id === partnershipIdNumber)

        if (isLatestApiCall(callId)) {
          setClientPartnerships(
            produce((draft) => {
              draft.clientGroup = clientGroup
              draft.clientGroupId = groupIdNumber
              draft.clientId = partnership?.clientId
              draft.partnership = partnership
              draft.partnershipId = partnership?.id
            })
          )
        }
      }
    }

    // load partnerships for ADMIN user
    if (isAdmin) {
      // Execute the update function defined above, but only if the group ID number is valid.
      if (isNaN(groupIdNumber)) {
        setClientPartnerships(
          produce((draft) => {
            draft.clientGroup = undefined
            draft.clientGroupId = undefined
            draft.partnership = undefined
            draft.partnershipId = undefined
          })
        )
      } else if (
        (clientPartnerships.clientGroupId !== groupIdNumber &&
          partnershipIdNumber !== undefined) ||
        (clientPartnerships.clientGroupId !== groupIdNumber &&
          (clientPartnerships.clientId !== undefined ||
            clientPartnerships.partnershipId !== partnershipIdNumber)) ||
        (clientPartnerships.clientGroupId === groupIdNumber &&
          (clientPartnerships.clientId !== undefined ||
            clientPartnerships.partnershipId !== partnershipIdNumber))
      ) {
        updatePartnerships()
      } else if (isNaN(partnershipIdNumber)) {
        setClientPartnerships(
          produce((draft) => {
            draft.clientId = undefined
            draft.partnership = undefined
            draft.partnershipId = undefined
          })
        )
      }
    }

    return () => {
      abortController.abort(DISMOUNT_ABORT_REASON)
    }
  }, [userEntityGroups.isInitialized, groupIdNumber, partnershipIdNumber, isAdmin])
}
