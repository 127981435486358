import {
  REACT_APP_ENGAGEMENTMANAGERURL,
  REACT_APP_INVESTORPORTALURL,
} from '../envVariables'
import { setApiWrapperName } from '../hooks/api-state-management'
import { EntityManagerClient } from './entity-manager-client-v3'
import { InvestorPortalClient } from './investor-portal-client'

function updateApiClientNames(api: object): void {
  const targetObject: { [n: string]: any } = api as any

  for (let n in targetObject) {
    // Get the current property.
    const prop = targetObject[n]

    // We only care about functions.  If it is, make sure it has a name.
    if (typeof prop === 'function') {
      setApiWrapperName(prop, n)
    }
  }
}

updateApiClientNames(InvestorPortalClient)
updateApiClientNames(EntityManagerClient)

export const filesClientApi = new InvestorPortalClient(
  REACT_APP_INVESTORPORTALURL,
)

export const entityClientApi = new EntityManagerClient(
  REACT_APP_ENGAGEMENTMANAGERURL,
)
