import { useCallback, useEffect, useRef, useState } from 'react'
import {
  DataGrid,
  Column,
  Sorting,
  Selection,
  Scrolling,
  LoadPanel,
  HeaderFilter,
  SearchPanel,
  Item,
  Toolbar,
} from 'devextreme-react/data-grid'
import { dgheight, ICellData, MenuProps } from '../../model/file-model'
import { useParams } from 'react-router-dom'
import { PartnershipDetails } from '../../api-client/investor-portal-client'
import { DateFormat } from '../../model/date-formats'
import {
  formatDate,
  getHeaderFilterDates,
  getFormattedDateWithNeverOption,
  getFormattedDateWithOutTimeStampWithNeverOption,
} from '../investor-files/fileUtilities'
import { useRecoilValue } from 'recoil'
import { Grid } from '@mui/material'
import { ContextMenu, Button } from 'devextreme-react'
import {
  getLandingPageRoute,
  getParentFileListRoute,
} from '../utility/route-creation'
import { partnershipDetailsListAtom } from '../../state/atom'
import { useFileClientApi } from '../../hooks/use-file-api'
import './client-group-status.scss'
import dxDataGrid, { RowPreparedEvent } from 'devextreme/ui/data_grid'
import { EventInfo } from 'devextreme/events'
import { HeaderFilterDataSourceOptions } from '../../../types/types'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import { ActivtyReport } from '../investor-files/activityReport'
import { taxYearState } from '../../common-components/banners/tax-year-control/tax-year-control-state'
import { ManageEmailTemplateDialog } from '../modal/emailTemplateDialog/manageEmailTemplateDialog'
import { ClearFiltersButton } from '../../common-components/clear-filter/clear-filters-button'
import { useNavigate } from '../../hooks/useNavigationGuard'
import { DISMOUNT_ABORT_REASON } from '../utility/abort-constants'
import { useClientGroupPermissionFunctions } from './use-client-group-permissions-functions'

/** Set context menu text and css class */
export function renderMenuItem(e: MenuProps) {
  let icon = <span className='material-icons-outlined'>{e.icon}</span>
  switch (e.icon) {
    case 'textdocument':
      icon = <DescriptionOutlinedIcon />
      break
  }
  return (
    <>
      {icon}
      {e.text}
    </>
  )
}

export const ClientGroupStatusTable = () => {
  const { details: partnershipDetails, isLoading } = useRecoilValue(
    partnershipDetailsListAtom
  )
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const { groupId } = useParams() as { groupId: string }
  const groupIdValue = parseInt(groupId)
  const { taxyear } = useRecoilValue(taxYearState)
  const dataGridRef = useRef<DataGrid>(null)
  const clientApi = useFileClientApi()
  const [showActivityReportModal, setShowActivityReportModal] = useState(false)
  const [showEmailTemplate, setShowEmailTemplate] = useState(false)

  const {
    userCanAccessAllocatingEntity,
    userCanAccessInvestorReport,
    userCanEditClientTemplate,
  } = useClientGroupPermissionFunctions()

  useEffect(() => {
    const abortController = new AbortController()
    setIsDataLoading(true)
    clientApi
      .getClientGroupDetails(
        parseInt(groupId),
        taxyear!,
        abortController.signal
      )
      .finally(() => setIsDataLoading(false))
    /** Clear Filter in DataGrid Based on Group Id */
    dataGridRef.current?.instance.clearFilter()
    return () => {
      abortController.abort(DISMOUNT_ABORT_REASON)
    }
  }, [groupId, taxyear])

  const setLastActivity = (cellData: ICellData<PartnershipDetails>) => {
    if (cellData.rowType === 'data' && cellData.data?.lastActivityDate) {
      const lastActivityDate = new Date(cellData.data?.lastActivityDate)
      const displayDate =
        lastActivityDate.getFullYear() <= 1
          ? 'never'
          : formatDate(lastActivityDate)
      const titleDate = getFormattedDateWithNeverOption(
        lastActivityDate,
        DateFormat.longDateTimeFormat
      )
      return <span title={titleDate}>{displayDate}</span>
    }
    return ''
  }

  /** Set Last Activity column value for sorting  */
  const setLastActivitySortData = (file: PartnershipDetails) => {
    const lastActivityDate = new Date(file.lastActivityDate!)
    if (lastActivityDate.getFullYear() <= 1) {
      return -1
    }
    return lastActivityDate.valueOf()
  }

  //calculate the last activity date for 'lastActivityDate' column
  const calculateLastActivityDate = (cellData: PartnershipDetails) => {
    return getFormattedDateWithNeverOption(
      cellData.lastActivityDate,
      DateFormat.isoDateTimeFormat
    )
  }

  /** Updates the list of dates displayed in the date header filter */
  function createdDateHeaderFilter(options: HeaderFilterDataSourceOptions) {
    options.dataSource.paginate = false
    options.dataSource.postProcess = getHeaderFilterDates
  }

  /** Calculate Matching Criteria colum Filter expression for 'DateAdded' column filter */
  function calculateLastActivityDateFilterExpression(
    this: Column,
    value: any,
    selectedFilterOperations: string,
    target: string
  ) {
    if (target === 'headerFilter') {
      return [[getFormattedDate, '=', value]]
    }

    return this.defaultCalculateFilterExpression(
      value,
      selectedFilterOperations,
      target
    )
  }

  function getFormattedDate(rowData: PartnershipDetails) {
    return getFormattedDateWithOutTimeStampWithNeverOption(
      rowData.lastActivityDate,
      DateFormat.shortDateFormat
    )
  }

  /** Add link to parent files in the File Name column */
  const addParentFileLink = (cellData: ICellData<PartnershipDetails>) => {
    return (
      <Button
        data-role='link-button'
        onClick={() => {
          navigate(
            getParentFileListRoute(groupId, cellData.data!.partnershipId!)
          )
        }}
        style={{ textTransform: 'none' }}
        stylingMode='text'
      >
        {cellData.value}
      </Button>
    )
  }

  /** Sets filtered records displayed in grid view */
  const setFilteredAndSortedRowsCount = useCallback(() => {
    const grid = dataGridRef.current!.instance
    const filterExpr = grid.getCombinedFilter(true)
    const dataSource = grid.getDataSource()
    const loadOptions = dataSource.loadOptions()
    dataSource.store().load({
      filter: filterExpr,
      sort: loadOptions.sort,
      group: loadOptions.group,
    })
  }, [])

  /** Get the list of records displayed in the child datagrid */
  const onContentReady = (e: EventInfo<dxDataGrid>) => {
    setFilteredAndSortedRowsCount()
  }

  const masterContextMenu: MenuProps[] = [
    {
      text: 'Manage Email Template',
      icon: 'create',
      action: () => {
        setShowEmailTemplate(true)
      },
    },
    {
      text: 'Investor Activity Report',
      icon: 'textdocument',
      disabled: !userCanAccessInvestorReport(groupIdValue),
      action: () => {
        setShowActivityReportModal(true)
      },
    },
  ]

  // setting activity Report Modal to false
  const activityReportModalClose = () => {
    setShowActivityReportModal(false)
  }

  /** Disable the rows if the user does not have permissions to it as EntityAdmin */
  const onRowPrepared = (e: RowPreparedEvent<PartnershipDetails, any>) => {
    if (!isLoading) {
      if (e && e.data) {
        const canBeAccessed = userCanAccessAllocatingEntity(
          e.data.partnershipId!
        )
        if (!canBeAccessed) {
          e.rowElement.style.opacity = '0.5'
          e.rowElement.style.pointerEvents = 'none'
        }
      }
    }
  }

  return (
    <div className='ip-table'>
      <DataGrid
        ref={dataGridRef}
        showBorders={true}
        keyExpr='partnershipId'
        noDataText={isLoading ? 'Loading...' : 'No Data Found.'}
        dataSource={partnershipDetails}
        columnAutoWidth={false}
        height={dgheight}
        repaintChangesOnly={true}
        showColumnLines={false}
        onContentReady={onContentReady}
        showRowLines={true}
        wordWrapEnabled={false}
        onRowPrepared={onRowPrepared}
        allowColumnResizing={true}
      >
        <SearchPanel visible={true} width='400px' />
        <Scrolling mode='virtual' rowRenderingMode='virtual' />
        <Selection mode='multiple' showCheckBoxesMode='always' />
        <Sorting mode='single' />
        <LoadPanel enabled={false} />
        <HeaderFilter visible={true} allowSearch={true} />
        <Toolbar>
          <Item location='before'>
            <Grid
              container
              item
              xs={10}
              minWidth={800}
              justifyContent={'flex-start'}
            >
              <Grid item xs={1} className='status-button-container'>
                <Button
                  onClick={() => navigate(getLandingPageRoute())}
                  stylingMode='outlined'
                  data-testid='btnBack'
                >
                  <span className='dx-icon-arrowleft'></span>
                </Button>
              </Grid>
              <Grid item xs={2} className='status-label-container'>
                <span className='status-label'>Document Status</span>
              </Grid>
            </Grid>
          </Item>
          {/* CLEAR FILTERS */}
          <Item name='clearFilters'>
            <ClearFiltersButton gridRef={dataGridRef} />
          </Item>
          <Item name='searchPanel' />
          <Item location='after'>
            <Grid container minWidth={65} justifyContent={'flex-end'}>
              <Button
                stylingMode='outlined'
                id='masterActionBtn'
                data-testid='btnMasterAction'
              >
                <span className='dx-icon-overflow'></span>
              </Button>
              <ContextMenu
                width={150}
                dataSource={masterContextMenu}
                showEvent='mouseenter'
                target='#masterActionBtn'
                itemRender={renderMenuItem}
                onItemClick={(e) => {
                  const item = e.itemData as MenuProps | undefined
                  if (item?.action) {
                    item.action()
                  }
                }}
              />
            </Grid>
          </Item>
        </Toolbar>
        <Column
          dataField='partnershipName'
          caption='Allocating Entity Name'
          cellRender={addParentFileLink}
          allowSearch={true}
          allowSorting={true}
          defaultSortOrder='asc'
          minWidth={'48%'}
        ></Column>
        <Column
          dataField='documentCount'
          caption='# of Documents'
          alignment='left'
          allowSearch={false}
          allowSorting={true}
          width={'12%'}
        />
        <Column
          dataField='numberOfInvestors'
          caption='# of Investors'
          alignment='left'
          allowSearch={false}
          allowSorting={true}
          width={'10%'}
        />
        <Column
          dataField='numberOfContacts'
          caption='# of Contacts'
          alignment='left'
          allowSearch={false}
          allowSorting={true}
          width={'10%'}
        />
        <Column
          calculateCellValue={calculateLastActivityDate}
          caption='Last Activity'
          alignment='center'
          cellRender={setLastActivity}
          calculateSortValue={setLastActivitySortData}
          allowSearch={false}
          allowSorting={true}
          allowFiltering={true}
          width={'20%'}
          calculateFilterExpression={calculateLastActivityDateFilterExpression}
        >
          <HeaderFilter dataSource={createdDateHeaderFilter} />
        </Column>
      </DataGrid>
      {showActivityReportModal && (
        <ActivtyReport
          entityGroupId={groupIdValue}
          onCancel={activityReportModalClose}
          onConfirm={activityReportModalClose}
        ></ActivtyReport>
      )}
      {showEmailTemplate && (
        <ManageEmailTemplateDialog
          onCancel={() => setShowEmailTemplate(false)}
          partnershipId={0}
          entityGroupId={groupIdValue}
          parentFileId={0}
        ></ManageEmailTemplateDialog>
      )}
    </div>
  )
}
