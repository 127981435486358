import { useMemo } from 'react'
import { useHasPermission } from '../../access-manager/use-permission'
import * as permissions from '../../model/permissions'
import { PermissionRequestLevels } from '../../access-manager/permission-models'

/** Hook that will provide functions used to indicate if and how the current user
 *   can have access o document status and investor status page actions items , given their IPM number or ID. */
export const useManageExistingInvestorsFunctions = () => {
  // Get the permissions for the current user.
  const hasPermission = useHasPermission()

  const result = useMemo(() => {

    const userCanAccessSubmitButton = () => {
      return hasPermission({
        level: PermissionRequestLevels.Any,
        permissionName: permissions.SUBMIT_ACTION_VERIFY.name,
      })
    }

    const userCanAccessProvisionButton = (
      ipmNumber: number | undefined
    ): boolean => {
      // return true if clientId is 0 or undefined as there will be no record for the entity in DB and we simply ignore them
      if (!ipmNumber) {
        return true
      }
      const hasAdminPermission = hasPermission({
        level: PermissionRequestLevels.Any,
        permissionName: permissions.ADMIN_PROVISION_INVESTOR.name,
      })
      const hasEntityPermission = hasPermission({
        level: PermissionRequestLevels.Entity,
        permissionName: permissions.USER_PROVISION_INVESTOR.name,
        ipmNumber: ipmNumber!,
      })
      return hasAdminPermission || hasEntityPermission
    }

    const userCanAccessCEMInfoButton = () => {
      return hasPermission({
        level: PermissionRequestLevels.Any,
        permissionName: permissions.CEM_EDIT_PROFILE.name
      })
    }

    // this function is not being used for now, this will be used when we have Edit entity modal working
    const userCanAccessEditEntityButton = (ipm: number) => {
      return hasPermission({
        level: PermissionRequestLevels.Client,
        permissionName: permissions.ENTITY_EDIT_INVESTOR.name,
        entityOrClientId: ipm,
      })
    }

    return {
      userCanAccessSubmitButton,
      userCanAccessProvisionButton,
      userCanAccessCEMInfoButton,
      userCanAccessEditEntityButton
    }
  }, [hasPermission])

  return result
}
