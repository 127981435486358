import { Container, Button, Drawer } from '@mui/material'
import { useState, useMemo, useEffect } from 'react'
import DataGrid, {
  SearchPanel,
  Column,
  GroupPanel,
  Toolbar,
  Item,
  Paging,
  LoadPanel,
} from 'devextreme-react/data-grid'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { userEntityGroupState } from '../../state/atom'
import './landingPage.scss'
import AddIcon from '@mui/icons-material/Add'
import { AddClient } from './addClient'
import produce from 'immer'
import { EntityGroup } from '../../api-client/investor-portal-client'
import { AdminNameCard } from './admin-name-card'
import { NameCardProps } from '../investor-view/investor-utils'
import { EditClient, listDataSourceType } from './landing-utility'
import { DISMOUNT_ABORT_REASON } from '../utility/abort-constants'
import { usePermissions } from '../../access-manager/use-permission'
import { PermissionRequestLevels } from '../../access-manager/permission-models'
import * as permissions from '../../model/permissions'

let defaultData: listDataSourceType

interface CardData {
  data: NameCardProps
}

/** Admin Landing Page Tiles View */
export const AdminLandingPageTilesView = () => {
  const { groups, isLoading } = useRecoilValue(userEntityGroupState)
  const setUserEntityGroups = useSetRecoilState(userEntityGroupState)
  const [sortOrder, setSortOrder] = useState('asc')
  const abortController = useMemo(() => new AbortController(), [])

  const [showEditClient, setShowEditClient] = useState<EditClient>({
    groupId: 0,
    mode: 'edit',
    visible: false,
  })

  function onEditClient(entityGroupId: number) {
    setShowEditClient({ groupId: entityGroupId, mode: 'edit', visible: true })
  }

  /** Abort the API calls if any promise is pending */
  useEffect(() => {
    return () => {
      abortController.abort(DISMOUNT_ABORT_REASON)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //Passing row items to show in tile(card)
  const investorNameCard = (cardData: CardData) => {
    return (
      <AdminNameCard
        id={cardData.data.id}
        name={cardData.data.name}
        logo={cardData.data.logoUri!}
        url={cardData.data.logoUri!}
        isFavorite={cardData.data.isFavorite}
        abortSignal={abortController.signal}
        onEditClient={onEditClient}
      ></AdminNameCard>
    )
  }

  /*filtering all the fevorite and non favorite and sorting then explicitly concatinating*/
  const sortedEntityGroups = useMemo(() => {
    const favorite = groups.filter((e) => e.isFavorite)
    const nonFavorite = groups.filter((e) => !e.isFavorite)
    if (sortOrder === 'desc') {
      favorite.sort((a: EntityGroup, b: EntityGroup) =>
        b.name!.localeCompare(a.name!)
      )
      nonFavorite.sort((a: EntityGroup, b: EntityGroup) =>
        b.name!.localeCompare(a.name!)
      )
    } else {
      favorite.sort((a: EntityGroup, b: EntityGroup) =>
        a.name!.localeCompare(b.name!)
      )
      nonFavorite.sort((a: EntityGroup, b: EntityGroup) =>
        a.name!.localeCompare(b.name!)
      )
    }
    //concatinating and returning first fvorite and then non-favorite to keep favorite groups on top
    return favorite.concat(nonFavorite)
  }, [groups, sortOrder])

  /* Explicit sorting by IsFavorite and Name to keep favorite groups on top*/
  const onPositionSortingChanged = () => {
    sortOrder === 'desc' ? setSortOrder('asc') : setSortOrder('desc')
    setUserEntityGroups((userEntityGroups) => {
      return produce(userEntityGroups, (draft) => {
        draft.groups = sortedEntityGroups
      })
    })
  }

  return (
    <div className='landing-page-container'>
      <Container maxWidth={false} className='cui-c_section cui-h_mt'>
        <DataGrid
          id='admin-grid-landing-tile-view'
          keyExpr='id'
          dataSource={sortedEntityGroups}
          showBorders={false}
          height='calc(100vh - 222px)'
          className={'cui-c_table cui-c_table-dx cui-c_table-dx_tile'}
          showColumnHeaders={false}
          showRowLines={false}
          showColumnLines={false}
          dataRowRender={investorNameCard}
          noDataText={
            isLoading && groups.length === 0
              ? 'Loading...'
              : !isLoading && groups.length === 0
              ? 'No Data Found.'
              : ''
          }
        >
          <SearchPanel visible={true} width='200px' />
          <Paging defaultPageSize={sortedEntityGroups.length} />
          <LoadPanel enabled={true} />
          <Column
            dataField='name'
            allowSearch={true}
            allowSorting={true}
          ></Column>
          <GroupPanel visible={true} />
          <Toolbar>
            <Item location='before'>
              <Button
                onClick={onPositionSortingChanged}
                variant='outlined'
                size='small'
              >
                Client
                <span
                  className='material-icons-outlined'
                  style={{ fontSize: '12px', marginLeft: '4px' }}
                >
                  {sortOrder === 'desc' ? 'south' : 'north'}
                </span>
              </Button>
            </Item>
            <Item name='searchPanel' />
          </Toolbar>
        </DataGrid>
      </Container>
      <Drawer
        open={showEditClient.visible}
        anchor={'right'}
        onClose={() => setShowEditClient({ visible: false })}
      >
        <AddClient
          groupId={showEditClient.groupId}
          mode={showEditClient.mode}
          onCancel={() => setShowEditClient({ visible: false })}
        ></AddClient>
      </Drawer>
    </div>
  )
}
