import { ICellData, MenuProps } from '../../model/file-model'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Button,
  CheckBox,
  ContextMenu,
  Popover,
  TextBox,
} from 'devextreme-react'
import { Link, useParams } from 'react-router-dom'
import { CircularProgress, Grid } from '@mui/material'
import {
  DataGrid,
  Column,
  Scrolling,
  LoadPanel,
  SearchPanel,
  Editing,
  Paging,
  HeaderFilter,
} from 'devextreme-react/data-grid'
import { useStateManagerApi } from '../../hooks/use-state-manager-api'
import { UnlockSelectionDialog } from './unlock-selections-dailog'
import {
  EmailStatusTypes,
  InvestorNotificationTypes,
  InvestorSearchResult,
  InvestorSelectionOperationCaps,
  InvestorsSearchRequestBaseOfObjectId,
  SelectableObjectCacheSelectionState,
  SMParentTypes,
  SortOrderTypes,
} from '../../api-client/investor-portal-client'
import {
  LoadingStateEnum,
  smEntityOptionsAtom,
  smGeneralElectionInfoAtom,
  smGeneralOptionsAtom,
} from '../../state/atom'
import { useRecoilValue } from 'recoil'
import CustomStore from 'devextreme/data/custom_store'
import { CopyClipboard } from '../../common-components/copyClipboard'
import './state-manager-investor-page.scss'
import { EditorOptions } from 'devextreme/ui/editor/editor'
import { ObjectId } from '../../api-client/object-id-override'
import { LoadOptions } from 'devextreme/data'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import { EmailStatusTemplate } from './email-status-template'
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant'
import { useSendEmailNotificationHandler } from '../../signalr/signal-r-handlers/email-notification-handler'
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined'
import { StateManagerProvisionUsers } from '../user/state-manager-provision-users'
import PreviewIcon from '@mui/icons-material/Preview'
import { InvestorViewElections } from './investor-view-elections'
import { QuickEditEntry } from '../investor-files/quickEditEntry'
import { QuickEditEntryModel } from '../../client-models/clientmodels'
import { ClearFiltersButton } from '../../common-components/clear-filter/clear-filters-button'
import { EmailConfirmationModal } from './send-initial-reminder-email/email-confirmation-modal'
import { StateManagerReports } from './reports/state-manager-reports'
import { BarChartDisplay } from '../reuasble-components/barchart-display'
import { DISMOUNT_ABORT_REASON } from '../utility/abort-constants'
import { DateFormat } from '../../model/date-formats'
import { formatDateFiltersWithNeverForEmailSent } from './state-manager-utils'

/** Set context menu text and css class */
export function renderMenuItem(e: MenuProps) {
  let icon = <span className='material-icons-outlined'>{e.icon}</span>
  switch (e.icon) {
    case 'reminder':
      icon = <NotificationImportantIcon />
      break
    case 'unlock':
      icon = <LockOpenIcon />
      break
    case 'provisionUser':
      icon = <PersonAddAltOutlinedIcon />
      break
    case 'viewElections':
      icon = <PreviewIcon />
      break
  }
  return (
    <>
      {icon}
      {e.text}
    </>
  )
}

type DataGridEditorOptions = EditorOptions<CheckBox>
type CheckboxEventHandler = DataGridEditorOptions['onValueChanged']
interface HeaderStores {
  lastReminderStatus: CustomStore
  initialEmailStatus: CustomStore
  allocatingEntities: CustomStore
  status: CustomStore
}

//MongoDB default session value
const invalidSessionId = '000000000000000000000000'

interface StateManagerInvestorPageProps {
  updateCounterCount: (newCount: number) => void
}

export const StateManagerInvestorPage = ({
  updateCounterCount,
}: StateManagerInvestorPageProps) => {
  const dataGridRef = useRef<DataGrid>(null)
  const stateManagerApi = useStateManagerApi()
  const [isLoadingEnabled, setIsLoadingEnabled] = useState(true)
  const smGeneralOptions = useRecoilValue(smGeneralOptionsAtom)
  const [popOverTarget, setPopOverTarget] = useState<string>()
  const [popOverVisible, setPopOverVisible] = useState<boolean>(false)
  const [popOverText, setPopOverText] = useState<string | string[]>()
  const [searchText, setSearchText] = useState<string>()
  const [isScrollingAllowed, setIsScrollingAllowed] = useState(true)
  const currentApiRequestAbortControllerRef = useRef<
    AbortController | undefined
  >()
  const sessionIdRef = useRef<ObjectId | undefined>(undefined)
  const [showUnlockSelectonModal, setShowUnlockSelectionModal] = useState(false)
  const [totalCount, setTotalCount] = useState<number>(0)
  const entityOptions = useRecoilValue(smEntityOptionsAtom)

  const [showEmailModal, setShowEmailModal] = useState(false)
  const [emailType, setEmailType] = useState<InvestorNotificationTypes>()
  const isCuttoffDateFound = !!smGeneralOptions?.generalOptions?.cutoffDate
  const [actionInSingleRow, setActionInSingleRow] = useState(false)
  const [selectedRowInvestorId, setSelectedRowInvestorId] = useState<number>()
  const [sendInvidualMail, setSendIndividualMail] = useState(false)
  const [showQEEModal, setShowQEEModal] = useState(false)
  const [quickEditData, setQEEData] = useState<QuickEditEntryModel>()
  const [showProvisionUsersModal, setShowProvisionUsersModal] =
    useState<boolean>(false)
  const [showDownloadResultsModal, setShowDownloadResultsModal] =
    useState<boolean>(false)
  const [allocatingEntityIds, setAllocatingEntityIds] = useState<number[]>()

  const { groupId, taxYear } = useParams() as {
    groupId: string
    taxYear: string
  }
  const groupIdValue = parseInt(groupId)
  const taxYearValue = parseInt(taxYear)
  const [showViewElectionModal, setShowViewElectionModal] =
    useState<boolean>(false)
  const [investorName, setInvestorName] = useState<string>()
  const { electionsInfo } = useRecoilValue(smGeneralElectionInfoAtom)

  const [allEntitySelectionState, setAllEntitySelectionState] =
    useState<SelectableObjectCacheSelectionState>(
      SelectableObjectCacheSelectionState.None
    )
  const [entitySelectionState, setEntitySelectionState] =
    useState<SelectableObjectCacheSelectionState>(
      SelectableObjectCacheSelectionState.None
    )
  // trigger state to reload datastore if anything is changed in the table
  const [updateDataStoreSignal, setUpdateDataStoreSignal] = useState<number>(1)

  const [enableActionItems, setEnableActionItems] =
    useState<InvestorSelectionOperationCaps>()
  const [masterActionMenuVisible, setMasterActionMenuVisible] = useState(false)
  const [menuLoading, setMenuLoading] = useState(false)

  const [submittedPercentage, setSubmittedPercentage] = useState<number>()
  const [chartDisplayText, setChartDisplayText] = useState<string>()
  const [chartVisible, setChartVisible] = useState<boolean>(false)
  let isCutOffDatePassed = false
  const searchRef = useRef<TextBox>(null)

  // check if cutoff date is passed
  isCutOffDatePassed = smGeneralOptions?.generalOptions?.isLocked!

  const getGridData = () => {
    return (dataGridRef.current?.instance?.getDataSource().items() ??
      []) as InvestorSearchResult[]
  }

  //check the sort order chosen by user and return
  const getSortDirection = (sortOptions: any): SortOrderTypes => {
    return !sortOptions || sortOptions[0].desc === false
      ? SortOrderTypes.Asc
      : SortOrderTypes.Desc
  }

  //check the sort order chosen by user and return
  const getSortField = (sortOptions: any): string | undefined => {
    if (sortOptions) {
      return (
        sortOptions[0].selector.toString().charAt(0).toUpperCase() +
        sortOptions[0].selector.slice(1)
      )
    } else {
      return undefined
    }
  }

  // Cleanup the investor selection cache if there's any change in Tax year
  useEffect(() => {
    if (sessionIdRef.current && sessionIdRef.current !== invalidSessionId) {
      //remove barchart when there is no session or change
      setChartVisible(false)
      stateManagerApi.cleanupServerSideCache(sessionIdRef.current)
      // reset selection cache Id
      sessionIdRef.current = undefined
    }
  }, [taxYear, sessionIdRef])

  useEffect(() => {
    // Cleanup the investor selection cache.
    return () => {
      // Abort any API calls currently in flight.
      if (currentApiRequestAbortControllerRef.current) {
        currentApiRequestAbortControllerRef.current.abort(DISMOUNT_ABORT_REASON)
      }
      if (sessionIdRef.current && sessionIdRef.current !== invalidSessionId) {
        stateManagerApi.cleanupServerSideCache(sessionIdRef.current)
      }
    }
  }, [])

  //update Investor count in Counter of Page using callback function
  useEffect(() => {
    updateCounterCount(totalCount)
  }, [totalCount, updateCounterCount])

  /**Get Barchart Status Values */
  const fetchElectionStatus = useCallback(
    async (sessionId: string, abortSignal?: AbortSignal) => {
      setChartVisible(false)

      await stateManagerApi
        .getElectionStatus(sessionId, abortSignal)
        .then((response) => {
          // Check if response exists and has the necessary properties
          if (response) {
            const investorCount = response?.investorCount ?? 0
            const totalInvestorCount = response?.totalInvestorCount ?? 0

            // Calculate the submitted and unsent percentages
            const submittedPercent: number =
              (investorCount / totalInvestorCount) * 100

            // Set the text to be displayed in chart
            setChartDisplayText(
              `${investorCount}/${totalInvestorCount} (${submittedPercent.toFixed(
                0
              )}%)`
            )

            // Update investors submittedPercentage state
            setSubmittedPercentage(submittedPercent)

            if (totalInvestorCount === 0) {
              setChartVisible(false)
            } else {
              setChartVisible(true)
            }
          }
        })
    },
    [chartVisible]
  )

  /** Get the data grid filter option */
  const setFilterString = () => {
    let filterString = ''
    if (dataGridRef.current && sessionIdRef.current) {
      const filter = dataGridRef.current.instance.getCombinedFilter()
      filterString = filter ? JSON.stringify(filter) : ''
    }
    return filterString
  }

  //Load the Status Bar Chart only when the session ID changes or when investor gets unlocked
  useEffect(() => {
    const abortController = new AbortController()

    if (sessionIdRef.current !== invalidSessionId) {
      fetchElectionStatus(sessionIdRef.current!, abortController.signal)
    }

    return () => {
      abortController.abort(DISMOUNT_ABORT_REASON)
    }
  }, [sessionIdRef.current, updateDataStoreSignal])

  /** custom store */
  const dataStore = useMemo(() => {
    // We define our search request here so we can reuse it for filtering
    //  when checking if all investors are checked.
    let searchRequest: InvestorsSearchRequestBaseOfObjectId | undefined =
      undefined

    // Abort controller for getting the state of "All Investors Checked".
    //  We want to be able to cancel this on subsequent calls, when calls are still in flight.
    let allInvestorsCheckedAbortController: AbortController | undefined =
      undefined

    /** Loads the investor selection data from the server. */
    const loadInvestorHandler = async (
      loadOptions: LoadOptions<InvestorSearchResult>
    ) => {
      let searchTextString = loadOptions.filter
        ? loadOptions.filter[0].filterValue
        : ''
      if (!searchRef) {
        setSearchText(searchTextString)
      }

      const newAbortcontroller = new AbortController()
      currentApiRequestAbortControllerRef.current = newAbortcontroller

      if (isCuttoffDateFound) {
        setIsLoadingEnabled(true)
        searchRequest = {
          sessionId: sessionIdRef.current,
          parentIds: [smGeneralOptions.generalOptions?.id!],
          parentType: SMParentTypes.Global,
          sortDirection: getSortDirection(loadOptions.sort),
          sortField: getSortField(loadOptions.sort),
          filterValue: JSON.stringify(loadOptions.filter),
          pageSize: loadOptions['take'],
          skipCount: loadOptions['skip'],
          clientTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }

        const responseData = await stateManagerApi.getInvestorsStatus(
          searchRequest,
          newAbortcontroller.signal
        )

        sessionIdRef.current = responseData.sessionId

        setAllEntitySelectionState(responseData.allSelectedState!)

        setIsLoadingEnabled(false)
        setTotalCount(responseData.totalCount!)

        return {
          data: responseData.data!,
          totalCount: responseData.totalCount!,
        }
      }

      setTotalCount(0)
      setIsLoadingEnabled(false)
      return {
        data: [],
        totalCount: 0,
      }
    }

    /** Updates the selection state of a specified investor locally, as well as on the server. */
    const updateInvestorSelection = async (
      key: number,
      newValue: InvestorSearchResult
    ) => {
      // Update the selection state on the server.
      await stateManagerApi.updateInvestorStatusSelection(
        sessionIdRef.current!,
        key,
        newValue.isSelected!
      )

      // If we were already checking the state of "All Investors Checked", then
      //  cancel that request, since we're going to start a new one.
      allInvestorsCheckedAbortController?.abort(DISMOUNT_ABORT_REASON)

      // Create a new abort controller for this request.
      allInvestorsCheckedAbortController = new AbortController()

      // Get the new "All Selected" state, based on the last request filter.
      //  There's no way searchRequest can be undefined if we're able to make selections.
      searchRequest!.sessionId = sessionIdRef.current!
      searchRequest!.filterValue = JSON.stringify(
        dataGridRef.current!.instance.getCombinedFilter()
      )

      try {
        const isAllSelected =
          await stateManagerApi.getInvestorStatusAllSelectedState(
            searchRequest!,
            allInvestorsCheckedAbortController.signal
          )

        setAllEntitySelectionState(isAllSelected)
      } catch (err) {
        // We want to throw errors if it's not from abort.
        if ((err as any).status) {
          throw err
        }
      }
    }

    return new CustomStore({
      key: 'investorId',
      load: loadInvestorHandler,
      update: (_, __) => {
        return Promise.resolve()
      },
      onUpdated: (key, newValue) => {
        updateInvestorSelection(key, newValue)
      },
      onModified: () => {},
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smGeneralOptions.generalOptions?.id, updateDataStoreSignal])

  /** The datastore used to drive the header filter. */
  const headerFilterDataStores = useMemo(() => {
    /** Function to create the function to load the header options from the server for a given property. */
    const createLoadMethod = (propName: string) => {
      return async function (options: LoadOptions<string>) {
        if (
          typeof options.skip !== 'number' ||
          typeof options.take !== 'number'
        ) {
          throw new Error(
            `Filter options request must include a skip and take property.`
          )
        }
        const filterOptions = setFilterString()
        const response = await stateManagerApi.getInvestorStatusFilterOptions(
          sessionIdRef.current!,
          Intl.DateTimeFormat().resolvedOptions().timeZone,
          propName,
          filterOptions,
          options.skip!,
          options.take!
          )

        return {
          data: response.data!.map((x) => {
          const formattedDate = formatDateFiltersWithNeverForEmailSent(x, DateFormat.shortDateFormat);
          return {
            text: formattedDate,
            value: formattedDate,
          };
        }),
          totalCount: response.totalCount!,
        }
      }
    }

    // Assemble the stores for each property.
    const storeProps: (keyof HeaderStores)[] = [
      'lastReminderStatus' as const,
      'initialEmailStatus' as const,
      'allocatingEntities' as const,
      'status' as const,
    ]

    const results: HeaderStores = {} as HeaderStores
    storeProps.forEach((x) => {
      results[x] = new CustomStore({
        load: createLoadMethod(x),
      })
    })

    return results
  }, [])

  /** Handler to Update Column Status*/
  useSendEmailNotificationHandler(
    smGeneralOptions?.generalOptions?.id!,
    useCallback(
      (investorId, statusType, emailType) => {
        // Fix the date to only be a date (not date/time)
        const emailProperty =
          emailType === 'Initial' ? 'initialEmailStatus' : 'lastReminderStatus'
        dataStore.push([
          {
            type: 'update',
            key: investorId,
            data: {
              [emailProperty]: {
                status: statusType,
                statusDate: new Date(),
              },
            },
          },
        ])
      },
      [dataStore]
    )
  )

  /**
   *  fetches master action menu items from the server.
   * Sets loading state, triggers API call, and updates state variables accordingly.
   */
  const updateMasterActionEnabledStates = async () => {
    try {
      // Check if the master action menu is not currently visible
      if (
        (!masterActionMenuVisible || menuLoading) &&
        sessionIdRef.current !== invalidSessionId
      ) {
        setMenuLoading(true) // Set loading state to true before making the API call

        // Make API call to fetch investor selection operation capabilities
        const response =
          await stateManagerApi.getInvestorSelectionOperationCaps(
            sessionIdRef.current!
          )

        // Update state variables with the API response
        setEnableActionItems(response)

        // Set menu visibility after fetching data
        setMasterActionMenuVisible(true)
      }
    } finally {
      // Set loading state to false after the API call is complete
      setMenuLoading(false)
    }
  }

  /**
   * Handles document click events to check if the click is outside the menu.
   * If outside the menu, sets masterActionMenuVisible to false, hiding the menu.
   */
  const handleDocumentClick = (e: MouseEvent) => {
    // Check if the click is outside the menu (using dataGridRef as a reference point)
    if (dataGridRef.current) {
      setMasterActionMenuVisible(false)
    }
  }

  /**
   * useEffect hook to add and remove the document click event listener.
   * Runs only once (on mount) to set up the initial event listener.
   */
  useEffect(() => {
    document.addEventListener('click', handleDocumentClick)

    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, []) // Empty dependency array ensures this effect runs once

  // Buttons for the Master context menu, with the appropriate actions.
  const masterActionMenu: MenuProps[] = useMemo(() => {
    return [
      {
        text: 'Send Initial Email',
        icon: 'send',
        action: () => {
          setEmailType(InvestorNotificationTypes.Initial)
          setSendIndividualMail(false)
          setShowEmailModal(true)
        },
        disabled:
          isCutOffDatePassed || enableActionItems?.disableSendInitialEmail!,
      },
      {
        text: 'Send Reminder',
        icon: 'reminder',
        action: () => {
          setEmailType(InvestorNotificationTypes.Reminder)
          setSendIndividualMail(false)
          setShowEmailModal(true)
        },
        disabled:
          isCutOffDatePassed || enableActionItems?.disableSendReminderEmail,
      },
      {
        text: 'Unlock Selection',
        icon: 'unlock',
        action: () => {
          setShowUnlockSelectionModal(true)
        },
        disabled:
          isCutOffDatePassed || enableActionItems?.disableUnlockSelection,
      },
      {
        text: 'Provision Users',
        icon: 'provisionUser',
        disabled: enableActionItems?.disableProvisionUsers,
        action: () => {
          setShowProvisionUsersModal(true)
        },
      },
      {
        text: 'Download Results',
        icon: 'file_download',
        disabled: enableActionItems?.disableDownloadResults,
        action: () => {
          setShowDownloadResultsModal(true)
          /**check for investor slection done for some then only
           * go and select the currently selected investor */
          if (
            allEntitySelectionState === SelectableObjectCacheSelectionState.Some
          ) {
            setEntitySelectionState(SelectableObjectCacheSelectionState.Some)
          }
        },
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allEntitySelectionState, isCutOffDatePassed, enableActionItems])

  //close the model and set setSelectedFiles state to empty
  const closeModal = () => {
    setShowUnlockSelectionModal(false)
    setSelectedRowInvestorId(undefined)
    setActionInSingleRow(false)
    setShowViewElectionModal(false)
  }

  // close provisioning user modal
  const provisionCloseModal = () => {
    setShowProvisionUsersModal(false)
    setSelectedRowInvestorId(undefined)
    setAllocatingEntityIds(undefined)
  }

  // close download result modal
  const downloadResultCloseModal = () => {
    setShowDownloadResultsModal(false)
    setSelectedRowInvestorId(undefined)
    setAllocatingEntityIds(undefined)
  }

  // trigger reload of datastore when unlock is completed
  const unlockComplete = () => {
    setUpdateDataStoreSignal(updateDataStoreSignal + 1)
  }

  /** Bind context menu with the grid column */
  const setActionColumn = (cellData: ICellData<InvestorSearchResult>) => {
    const selectedRowData = cellData.data!

    // Create the ID for the button, so we can attach our context menu to it.
    const buttonId = `btnMenuAction-${selectedRowData.investorId}`

    // disable Unlock Selection button if , investor has incomplete submissions
    const disableUnlockSelection: boolean = selectedRowData.submittedDate
      ? false
      : true

    // Disable Provision User if investor is not having email address
    const disableProvisionUser: boolean =
      selectedRowData.emails && selectedRowData.emails.length > 0 ? false : true

    const hasErrorStatus =
      selectedRowData.initialEmailStatus?.status ===
        EmailStatusTypes.ErrorBuilding ||
      selectedRowData.initialEmailStatus?.status ===
        EmailStatusTypes.ErrorSending

    const enableInitialEmail =
      !selectedRowData.submittedDate &&
      (selectedRowData.initialEmailStatus === undefined || hasErrorStatus)

    const enableReminderEmail =
      !selectedRowData.submittedDate &&
      selectedRowData.initialEmailStatus?.status === EmailStatusTypes.Complete

    // Buttons for the Action column context menu, with the appropriate actions.
    const contextMenu: MenuProps[] = [
      {
        text: 'Send Initial Email',
        icon: 'send',
        action: () => {
          setEmailType(InvestorNotificationTypes.Initial)
          setShowEmailModal(true)
          setSendIndividualMail(true)
          setSelectedRowInvestorId(selectedRowData?.investorId)
        },
        disabled: !enableInitialEmail || isCutOffDatePassed,
      },
      {
        text: 'Send Reminder ',
        icon: 'reminder',
        action: () => {
          setEmailType(InvestorNotificationTypes.Reminder)
          setSendIndividualMail(true)
          setShowEmailModal(true)
          setSelectedRowInvestorId(selectedRowData?.investorId)
        },
        disabled: !enableReminderEmail || isCutOffDatePassed,
      },
      {
        text: 'Unlock Selection',
        icon: 'unlock',
        disabled: disableUnlockSelection || isCutOffDatePassed,
        action: () => {
          setShowUnlockSelectionModal(true)
          setActionInSingleRow(true)
          // set state to investorId
          setSelectedRowInvestorId(cellData.data?.investorId)
        },
      },
      {
        text: 'View Elections',
        icon: 'viewElections',
        disabled: !cellData.data?.submittedDate,
        action: () => {
          setShowViewElectionModal(true)
          setInvestorName(cellData.data?.investorName)
          setSelectedRowInvestorId(cellData.data?.id)
          setSelectedRowInvestorId(cellData.data?.investorId)
        },
      },
      {
        text: 'Quick Edit Entity',
        icon: 'flash_on',
        action: () => {
          const quickEditData: QuickEditEntryModel = {
            fileId: selectedRowData.id!,
            entityName: selectedRowData.investorName!,
            emails: selectedRowData?.emails!,
            partnerEntityId: selectedRowData.investorId!,
            einNumber: '',
          }
          setShowQEEModal(true)
          setQEEData(quickEditData)
        },
      },
      {
        text: 'Provision User',
        icon: 'provisionUser',
        disabled: disableProvisionUser,
        action: () => {
          if (!disableProvisionUser) {
            setShowProvisionUsersModal(true)
            // set investorId in state
            setSelectedRowInvestorId(selectedRowData.investorId)
            setAllocatingEntityIds(
              selectedRowData.allocatingEntities?.map((a) => a.id!)
            )
          }
        },
      },
      {
        text: 'Download Results',
        icon: 'file_download',
        disabled: !cellData.data?.submittedDate,
        action: () => {
          //collect the allocatingIds for the current row investor
          const allocatingentityIds = selectedRowData.allocatingEntities?.map(
            (a) => a.id!
          )
          setAllocatingEntityIds(allocatingentityIds)
          setSelectedRowInvestorId(selectedRowData.investorId)
          setShowDownloadResultsModal(true)
          setEntitySelectionState(SelectableObjectCacheSelectionState.None)
        },
      },
    ]

    // Return the cell content with a button and a menu attached to it.
    return (
      <>
        <Button
          stylingMode='outlined'
          id={buttonId}
          data-testid='btnMasterAction'
        >
          <span className='dx-icon-overflow'></span>
        </Button>
        <ContextMenu
          dataSource={contextMenu}
          showEvent='click'
          target={`#${buttonId}`}
          itemRender={renderMenuItem}
          onItemClick={(e) => {
            const item = e.itemData as MenuProps | undefined
            if (item?.action) {
              item.action()
            }
          }}
        />
      </>
    )
  }

  /**Render tooltip text and align center */
  const renderTooltip = () => {
    if (typeof popOverText === 'string') {
      return <div className='content-center'>{popOverText}</div>
    } else {
      return (
        <div className='content-center popup-tooltip'>
          {popOverText!.map((name) => {
            return <div>{name} |</div>
          })}
        </div>
      )
    }
  }

  /** Set PopOver configuration to display */
  const setPopOverDisplay = (
    showPopOver: boolean,
    text: string | string[],
    target: string
  ) => {
    setPopOverVisible(showPopOver)
    setPopOverText(text)
    setPopOverTarget(target)
  }

  /** Set the Investor Email(s) for the column, if multiple emails found then display
   * Multiple Emails Found as hyperlink and display the email(s) on mouse over as tooltip
   */
  const setInvestorEmail = (cellData: ICellData<InvestorSearchResult>) => {
    if (cellData.rowType === 'data' && cellData.data?.emails) {
      let toolTip = ''
      const controlId = 'email-' + cellData.data?.investorId
      if (cellData.data?.emails?.length === 1) {
        toolTip = cellData.data?.emails[0]
        if (!toolTip) {
          return ''
        }
        return (
          <div>
            <CopyClipboard
              text={toolTip}
              tooltipText='Email(s) Copied!'
              targetControl={'#' + controlId}
              width={125}
            />
            <span
              id={controlId}
              onMouseEnter={() =>
                setPopOverDisplay(true, toolTip, '#' + controlId)
              }
              onMouseLeave={() => setPopOverDisplay(false, '', '')}
            >
              {toolTip}
            </span>
          </div>
        )
      } else if (cellData.data?.emails?.length > 1) {
        const controlId = 'email-' + cellData.data?.investorId
        toolTip = cellData.data?.emails[0]
        let emails = toolTip
        for (let index = 1; index < cellData.data?.emails?.length; index++) {
          const email = cellData.data.emails[index]
          toolTip += '\n' + email
          emails += ';' + email
        }
        return (
          <div>
            <CopyClipboard
              text={emails}
              tooltipText='Email(s) Copied!'
              targetControl={'#' + controlId}
              width={125}
            />
            <Link
              to=''
              id={controlId}
              onMouseEnter={() =>
                setPopOverDisplay(true, toolTip, '#' + controlId)
              }
              onMouseLeave={() => setPopOverDisplay(false, '', '')}
            >
              Multiple Emails Found
            </Link>
          </div>
        )
      }
    }
    return ''
  }

  /** Set the Allocatitng Entitities for the column, if multiple allocating entity found then display
   * Multiple Entities Found as hyperlink and display the Multiple Entity(ies) on mouse over as tooltip
   */
  const setAllocatingEntity = (cellData: ICellData<InvestorSearchResult>) => {
    if (cellData.rowType === 'data' && cellData.data?.allocatingEntities) {
      let toolTip = ''
      const controlId = 'allocatingentity-' + cellData.data?.investorId
      if (cellData.data?.allocatingEntities?.length === 1) {
        toolTip = cellData.data?.allocatingEntities[0].name!
        return (
          <div>
            <span
              id={controlId}
              onMouseEnter={() =>
                setPopOverDisplay(true, toolTip, '#' + controlId)
              }
              onMouseLeave={() => setPopOverDisplay(false, '', '')}
            >
              {toolTip}
            </span>
          </div>
        )
      } else if (cellData.data?.allocatingEntities?.length > 1) {
        const controlId = 'allocatingentity-' + cellData.data?.investorId
        var toolTipArray: string[] = []
        toolTipArray.push(cellData.data?.allocatingEntities[0].name!)
        for (
          let index = 1;
          index < cellData.data?.allocatingEntities?.length;
          index++
        ) {
          const entity = cellData.data.allocatingEntities[index]
          toolTipArray.push(entity.name!)
        }
        return (
          <div>
            <Link
              to=''
              id={controlId}
              onMouseEnter={() =>
                setPopOverDisplay(true, toolTipArray, '#' + controlId)
              }
              onMouseLeave={() => setPopOverDisplay(false, '', '')}
            >
              <u> Multiple Entities Found</u>
            </Link>
          </div>
        )
      }
    }
    return ''
  }

  /**Initial Email sent column */
  const setInitialEmailSent = (cellData: ICellData<InvestorSearchResult>) => {
    if (cellData.rowType === 'data') {
      return (
        <EmailStatusTemplate
          status={cellData.data?.initialEmailStatus}
        ></EmailStatusTemplate>
      )
    }
  }

  /**Last Reminder Sent column */
  const setLastReminderSent = (cellData: ICellData<InvestorSearchResult>) => {
    if (cellData.rowType === 'data') {
      return (
        <EmailStatusTemplate
          status={cellData.data?.lastReminderStatus}
        ></EmailStatusTemplate>
      )
    }
  }

  /** Set status based on the submitted date */
  const setStatus = (cellData: ICellData<InvestorSearchResult>) => {
    if (cellData.rowType === 'data') {
      if (cellData.data?.submittedDate) {
        return (
          <div>
            <Button
              className='status-buttons-complete'
              width={130}
              text='Complete'
              type='success'
              stylingMode='outlined'
              style={{ pointerEvents: 'none' }} //for un-clickable button
            />
          </div>
        )
      } else {
        return (
          <div>
            <Button
              className='status-buttons-incomplete'
              width={130}
              text='Incomplete'
              type='success'
              stylingMode='outlined'
              style={{ pointerEvents: 'none' }} //for un-clickable button
            />
          </div>
        )
      }
    }
  }

  /** Update all investors based on the selection ie., SelectAll or SelectNone */
  const toggleAllSelectedState: CheckboxEventHandler = (changeInfo) => {
    // We only want to respond to a user event.  If the change happens, because
    //  another checkbox was checked (but not the "Select All" checkbox), then
    //  we want to exit.
    if (!changeInfo.event) {
      return
    }

    changeInfo.event?.preventDefault()

    // Determine the new state for the selection.
    const isSelected = changeInfo.value

    // Get the grid data.
    const gridData = getGridData()

    if (sessionIdRef.current) {
      // Create the filter string, if we have one.
      const filter = dataGridRef.current!.instance.getCombinedFilter()
      let filterString = filter ? JSON.stringify(filter) : ''

      // Block the scrolling on the page until the database is updated.
      setIsScrollingAllowed(false)
      stateManagerApi
        .updateAllInvestorStatusSelection(
          sessionIdRef.current!,
          filterString,
          isSelected
        )
        .then((x) => {
          setIsScrollingAllowed(true)
        })
    }

    // Make the update instructions for the data grid data.
    const changes = gridData
      .filter((x) => x.isSelected !== isSelected)
      .map((x) => ({
        type: 'update' as const,
        data: { isSelected: isSelected },
        key: x.investorId!,
      }))

    // Make the actual update to the data.
    dataStore.push(changes)

    // Update the selection state.
    const newSelectionState = isSelected
      ? SelectableObjectCacheSelectionState.All
      : SelectableObjectCacheSelectionState.None
    setAllEntitySelectionState(newSelectionState)
  }

  const isLoading =
    smGeneralOptions.loadingState !== LoadingStateEnum.Loaded ||
    !entityOptions.hasLoaded ||
    isLoadingEnabled

  /** Close the QEE modal & reset the QEE data */
  const quickEditClose = () => {
    setShowQEEModal(false)
    setQEEData(undefined)
  }

  /**ToolBar Content */
  const toolbarContent = (
    <Grid container alignItems='flex-end' className='toolbar-investortable'>
      <Grid item xs={7} md={5} className='before-search'>
        <BarChartDisplay
          visible={chartVisible}
          markedPercent={submittedPercentage!}
          displayValue={chartDisplayText}
        />
      </Grid>

      {/* Search Box and Clear buttons */}
      <Grid item xs={5} md={5} className='search-container'>
        <Grid container alignItems='flex-end'>
          <Grid item xs={1} md={4} className='clear-button'>
            <ClearFiltersButton gridRef={dataGridRef} />
          </Grid>
          <Grid item xs={5} md={6.5} className='search-box-container'>
            <TextBox
              className='search-box'
              ref={searchRef}
              onValueChanged={(e) => setSearchText(e.value)}
              valueChangeEvent='keyup'
              placeholder='Search...'
              value={searchText}
              width={300}
              showClearButton={true}
            >
              <Button
                icon='search'
                type='normal'
                className='icon-button'
                onClick={() => {
                  searchRef.current!.instance.focus()
                }}
              />
            </TextBox>
          </Grid>
          {/* Context Menu */}
          <Grid item xs={5} md={1.5} className='after-search'>
            <div
              id='investorMasterActionBtn'
              data-testid='investor-master-Action-Btn'
              onMouseEnter={updateMasterActionEnabledStates}
              className='button-like-div'
            >
              {menuLoading ? (
                <CircularProgress size={24} color='info' />
              ) : (
                <span className='dx-icon-overflow'></span>
              )}
            </div>
            <ContextMenu
              width={150}
              visible={masterActionMenuVisible}
              dataSource={masterActionMenu}
              target='#investorMasterActionBtn'
              itemRender={renderMenuItem}
              onItemClick={(e) => {
                const item = e.itemData as MenuProps | undefined
                if (item?.action) {
                  item.action()
                  setMasterActionMenuVisible(false)
                }
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )

  return (
    <div className='sm-investor-selector-container ip-table'>
      <div>{toolbarContent}</div>
      <DataGrid
        ref={dataGridRef}
        showBorders={true}
        noDataText={
          isLoading
            ? 'Loading...'
            : totalCount === 0 && !isCuttoffDateFound
            ? 'Please setup the elections for this tax year with the Global Settings button in Allocating Entity view.'
            : totalCount === 0
            ? 'No Data Found'
            : ''
        }
        dataSource={dataStore}
        columnAutoWidth={true}
        height='calc(90vh - 152px)'
        width='100%'
        repaintChangesOnly={true}
        remoteOperations={true}
        showColumnLines={false}
        showRowLines={true}
        wordWrapEnabled={false}
        disabled={!isScrollingAllowed}
      >
        <Paging defaultPageSize={13} />
        <SearchPanel
          visible={false}
          width='400px'
          text={searchText}
          placeholder='Search...'
        />
        <Scrolling mode='virtual' rowRenderingMode='virtual' />
        <LoadPanel enabled={true} />
        <Editing
          mode='cell'
          allowUpdating={true}
          allowAdding={false}
          refreshMode='repaint'
        />
        <HeaderFilter visible={true} />
        <Column
          dataField='isSelected'
          dataType='boolean'
          headerCellRender={() => {
            return (
              <CheckBox
                data-testid='chk-select-all'
                disabled={totalCount === 0 || isLoading}
                value={
                  allEntitySelectionState ===
                  SelectableObjectCacheSelectionState.All
                }
                onValueChanged={toggleAllSelectedState as any}
              />
            )
          }}
          allowEditing={true}
          allowSearch={false}
          allowSorting={false}
          allowFiltering={false}
          width={'5%'}
        ></Column>
        <Column
          dataField='investorName'
          caption='Investor Name'
          allowSearch={true}
          allowSorting={true}
          defaultSortOrder='asc'
          allowEditing={false}
          allowFiltering={false}
          width={'15%'}
          dataType='string'
        ></Column>
        <Column
          dataField='emails'
          caption='Investor Email'
          allowSearch={true}
          allowSorting={true}
          allowEditing={false}
          allowFiltering={false}
          alignment='left'
          width={'15%'}
          dataType='string'
          cellRender={setInvestorEmail}
        />
        <Column
          dataField='allocatingEntities'
          caption='Allocating Entities'
          allowSearch={true}
          allowSorting={true}
          allowEditing={false}
          allowHeaderFiltering={true}
          width={'15%'}
          cellRender={setAllocatingEntity}
          dataType='string'
        >
          <HeaderFilter
            dataSource={headerFilterDataStores.allocatingEntities}
          ></HeaderFilter>
        </Column>
        <Column
          dataField='initialEmailStatus'
          caption='Initial Email Sent'
          allowSearch={false}
          allowSorting={true}
          allowEditing={false}
          allowHeaderFiltering={true}
          width={'15%'}
          cellRender={setInitialEmailSent}
        >
          <HeaderFilter
            dataSource={headerFilterDataStores.initialEmailStatus}
          ></HeaderFilter>
        </Column>
        <Column
          dataField='lastReminderStatus'
          caption='Last Reminder Sent'
          allowSearch={false}
          allowSorting={true}
          allowEditing={false}
          allowHeaderFiltering={true}
          width={'15%'}
          cellRender={setLastReminderSent}
        >
          <HeaderFilter
            dataSource={headerFilterDataStores.lastReminderStatus}
          ></HeaderFilter>
        </Column>
        <Column
          dataField='status'
          caption='Status'
          allowSearch={false}
          allowSorting={true}
          allowEditing={false}
          allowHeaderFiltering={true}
          width={'15%'}
          cellRender={setStatus}
          alignment='center'
        >
          <HeaderFilter
            dataSource={headerFilterDataStores.status}
          ></HeaderFilter>
        </Column>
        <Column
          caption='Action'
          cellRender={setActionColumn}
          allowSearch={false}
          allowEditing={false}
          allowSorting={false}
          allowFiltering={false}
          width={'5%'}
          alignment='center'
        />
      </DataGrid>
      <Popover
        target={popOverTarget}
        visible={popOverVisible}
        width={280}
        contentRender={renderTooltip}
      ></Popover>
      {showUnlockSelectonModal && (
        <UnlockSelectionDialog
          onCancel={closeModal}
          isVisible={showUnlockSelectonModal}
          onConfirm={closeModal}
          sessionId={sessionIdRef.current}
          investorId={selectedRowInvestorId}
          isSingle={actionInSingleRow}
          onComplete={unlockComplete}
        ></UnlockSelectionDialog>
      )}
      {showEmailModal && (
        <EmailConfirmationModal
          onPreviewClose={() => setShowEmailModal(false)}
          emailType={emailType!}
          sessionId={sessionIdRef.current}
          investorId={selectedRowInvestorId!}
          isAllSelected={
            allEntitySelectionState !== SelectableObjectCacheSelectionState.Some
          } // Since select NONE === select ALL, this logic is correct.
          sendIndividualMail={sendInvidualMail}
        ></EmailConfirmationModal>
      )}
      {showViewElectionModal && (
        <InvestorViewElections
          isVisible={showViewElectionModal}
          investorElectionsInfoId={electionsInfo?.id!}
          onCancel={closeModal}
          investorName={investorName}
          investorId={selectedRowInvestorId!}
          groupId={groupIdValue}
          taxYear={taxYearValue}
        />
      )}
      {showProvisionUsersModal && (
        <StateManagerProvisionUsers
          onClose={provisionCloseModal}
          isVisible={showProvisionUsersModal}
          selectionCacheId={sessionIdRef.current!}
          entityGroupId={groupIdValue}
          investorId={selectedRowInvestorId}
          allocatingEntityIds={allocatingEntityIds}
        ></StateManagerProvisionUsers>
      )}
      {showQEEModal && (
        <QuickEditEntry
          detail={quickEditData!}
          onCancel={quickEditClose}
          onSave={quickEditClose}
          selectionCacheId={sessionIdRef.current!}
          onComplete={unlockComplete}
        ></QuickEditEntry>
      )}
      {showDownloadResultsModal && (
        <StateManagerReports
          onCancel={downloadResultCloseModal}
          isVisible={showDownloadResultsModal}
          selectionCacheId={sessionIdRef.current!}
          investorId={selectedRowInvestorId}
          groupId={groupIdValue}
          taxYear={taxYearValue}
          allocatingEntityIds={allocatingEntityIds}
          cacheSelectionState={entitySelectionState}
        />
      )}
    </div>
  )
}