import React, { useState } from 'react'
import { ButtonFunction, Modal } from '../modal/modalBase'
import { EntityType, getEntityTypeItems } from './entity-utils'
import RadioGroup from 'devextreme-react/radio-group'
import './entity-type-selector.scss'
import { useEntityManagerPermissions } from './use-entity-manager-permissions'
import { useParams } from 'react-router-dom'

interface EntityTypeSelectorProps {
  onEntityTypeSelected: (entityType: EntityType) => void // Callback when an entity type is selected.
  onCancel: () => void
}

/** EntityTypeSelector to add new entity. */
export const EntityTypeSelector = ({
  onEntityTypeSelected,
  onCancel,
}: EntityTypeSelectorProps) => {
  const [selectedEntityType, setSelectedEntityType] = useState<EntityType>()

  // Get group id
  const { groupId } = useParams() as {
    groupId: string
  }
  const groupIdValue = parseInt(groupId)

  const permissions = useEntityManagerPermissions(groupIdValue)

  // Function to update the selected entity type and invoke the callback.
  const updateEntityType = () => {
    if (selectedEntityType) {
      onEntityTypeSelected(selectedEntityType)
    }
  }

  // Create the buttons for the Modal.
  const buttonFunctions: ButtonFunction[] = [
    {
      label: 'Cancel',
      onClick: () => {
        onCancel()
      },
      isDefaultAction: true,
      buttonProps: {
        className: 'cancel-btn',
        stylingMode: 'contained',
        type: 'normal',
      },
    },
    {
      label: 'Next',
      onClick: () => {
        updateEntityType()
      },
      buttonProps: {
        className: 'next-btn',
        stylingMode: 'contained',
        type: 'default',
      },
      isDisabled: !selectedEntityType,
    },
  ]

  const entityTypeItems = getEntityTypeItems(
    !permissions.canAddInvestorEntity,
    !permissions.canAddAllocatingEntity
  )

  return (
    <Modal
      visible={true}
      title={'Add Entity'}
      maxHeight={180}
      maxWidth={500}
      showCloseButtonInTitle={true}
      buttonFunctions={buttonFunctions}
      preventModalClose={true}
      className='add-entity-modal'
    >
      <div className='add-entity-container'>
        <div className='label-font'>Allocating Entity or Investor</div>
        <RadioGroup
          items={entityTypeItems}
          value={selectedEntityType}
          valueExpr='value'
          displayExpr='label'
          onValueChanged={(e) => setSelectedEntityType(e.value)}
          layout='horizontal'
          focusStateEnabled={false}
          className='add-entity-radio'
        />
      </div>
    </Modal>
  )
}
