import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { NavDataProps } from '../components/layout/leftnav/LeftNav'
import {
  getClientsPageRoute,
  getEntityManagerRoute,
  getLandingPageRoute,
  getManageExistingInvestorsRoute,
  getStateManagerRoute,
} from '../components/utility/route-creation'
import {
  REACT_APP_FEATURES_MANAGEEXISTING,
  REACT_APP_FEATURES_STATEMANAGER,
} from '../envVariables'
import { REACT_APP_FEATURES_ENTITYMANAGER } from '../envVariables'
import { ensureNumberOrUndefined } from '../utilities/utilities'
import { entityGroupPartnershipStateAtom } from '../state/atom'
import { useRecoilValue } from 'recoil'
import {
  permissionRequestAny,
  permissionRequestClientById,
} from '../access-manager/permission-request-creation'
import { useHasPermission } from '../access-manager/use-permission'
import * as Permissions from '../model/permissions'

export function useNavDataAdmin() {
  /** Notify: is statemanager active for the current app?
   * We are storing it into a variable for further reference
   */
  const enableStateManager = REACT_APP_FEATURES_STATEMANAGER ?? false
  const enableEntityManager = REACT_APP_FEATURES_ENTITYMANAGER ?? false
  const enableManageExistingInvestorsFeature =
    REACT_APP_FEATURES_MANAGEEXISTING ?? false
  const entityClientGroup = useRecoilValue(entityGroupPartnershipStateAtom)
  //notifying groupId to inject relative paths for the navData
  const { groupId } = useParams() as { groupId: string }
  const groupIdNumber = ensureNumberOrUndefined(groupId)

  const hasPermission = useHasPermission()

  //Enabling StateManager icon in leftnav with State Elections flag from recoil state
  const clientState = groupIdNumber
    ? entityClientGroup[groupIdNumber]
    : undefined

  const elections = !!clientState?.featureFlag?.stateElections

  // Memoizing nav data for Admin view
  const navData: NavDataProps | undefined = useMemo(() => {
    // If the user isn't an investor, then they can't see the menu.
    if (!hasPermission(permissionRequestAny(Permissions.ADMIN))) {
      return undefined
    }

    // Evaluate all permissions needed to show/hide the various menu items, and store them in a map.
    const permissionMap = new Map<Permissions.PermissionInfo, boolean>()

    ;[
      Permissions.PAGE_VISIBILITY_STATEMAN,
      Permissions.PAGE_VISIBILITY_ENTITYMAN,
    ].forEach((p) => {
      // If we have a client group ID, then we can test if the user has the permission.  Otherwise
      //  we can't perform this test, and we default to false.
      if (groupIdNumber) {
        permissionMap.set(
          p,
          hasPermission(permissionRequestClientById(p, groupIdNumber))
        )
      } else {
        permissionMap.set(p, false)
      }
    })
    ;[
      Permissions.PAGE_VISIBILITY_ADMINLANDING,
      Permissions.PAGE_VISIBILITY_MANAGEXISTING,
      Permissions.PAGE_VISIBILITY_APPSETTINGS,
      Permissions.PAGE_VISIBILITY_CLIENTSADMIN,
    ].forEach((p) => {
      permissionMap.set(p, hasPermission(permissionRequestAny(p)))
    })

    return {
      default: {
        title: 'Home',
        path: getLandingPageRoute(),
        visible: permissionMap.get(Permissions.PAGE_VISIBILITY_ADMINLANDING),
        icon: 'home',
        iconsType: 'filled',
      },
      menu: [
        {
          title: 'Manage Existing Investors',
          path: getManageExistingInvestorsRoute(),
          visible:
            permissionMap.get(Permissions.PAGE_VISIBILITY_MANAGEXISTING) &&
            enableManageExistingInvestorsFeature,
          icon: 'contact_mail',
          tooltip: '',
        },
        {
          title: 'State Manager',
          path: getStateManagerRoute(groupIdNumber),
          icon: 'place',
          tooltip: 'State Manager',
          visible:
            elections &&
            enableStateManager &&
            permissionMap.get(Permissions.PAGE_VISIBILITY_STATEMAN),
        },
        {
          title: 'Entity Manager',
          path: getEntityManagerRoute(groupIdNumber),
          icon: 'work',
          tooltip: 'Entity Manager',
          visible:
            !!groupIdNumber &&
            enableEntityManager &&
            permissionMap.get(Permissions.PAGE_VISIBILITY_ENTITYMAN),
        },
        {
          title: 'Settings',
          icon: 'settings',
          tooltip: 'Settings',
          visible: permissionMap.get(Permissions.PAGE_VISIBILITY_APPSETTINGS),
          menu: [
            {
              title: 'Clients',
              path: getClientsPageRoute(),
              tooltip: 'Clients',
              visible: permissionMap.get(
                Permissions.PAGE_VISIBILITY_CLIENTSADMIN
              ),
            },
          ],
        },
      ],
    }
  }, [
    enableManageExistingInvestorsFeature,
    groupIdNumber,
    elections,
    enableStateManager,
    enableEntityManager,
    hasPermission,
  ])

  return navData
}
