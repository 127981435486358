import { useContext, useEffect, useState, useCallback } from 'react'
import { useCommonDialogs } from './components/modal/commonDialog/common-dialog-operations'
import {
  REACT_APP_IDMAUTHENTICATIONAUTHORITY,
  REACT_APP_IDMAUTHENTICATIONCLIENTID,
} from './envVariables'
import { OidcIdentityContext } from '@rsmus/react-auth'

type TokenValue = {
  expires_at: string
}

/** Shows Popup when the session expires */
export const TokenChecker = () => {
  const commonDialog = useCommonDialogs()
  const { logout } = useContext(OidcIdentityContext)
  const [dialogShown, setDialogShown] = useState(false)

  // Construct the key to access the token from sessionStorage
  const ACCESS_TOKEN = `oidc.user:${REACT_APP_IDMAUTHENTICATIONAUTHORITY}:${REACT_APP_IDMAUTHENTICATIONCLIENTID}`

  /** Check if the token has expired */
  const checkTokenExpiration = useCallback(() => {
    if (dialogShown) return // If the dialog is already shown, do not check again

    const tokendata = sessionStorage.getItem(ACCESS_TOKEN)
    if (tokendata) {
      const dataObject: TokenValue = JSON.parse(tokendata)
      const tokenExpirationTime = parseInt(dataObject.expires_at)
      const currentTime = Math.floor(Date.now() / 1000)

      /** If the token expires and does not get refreshed, show the dialog */
      if (currentTime >= tokenExpirationTime && !dialogShown) {
        setDialogShown(true)
        commonDialog.showDialog({
          dialogType: 'general',
          title: 'Session Expired',
          content: 'Your session has expired, please log in again.',
          omitDefaultButton: true,
          preventModalClose: true,
          buttonFunctions: [
            {
              label: 'OK',
              isCloseAction: true,
              isDefaultAction: true,
              onClick: () => {
                // Close the dialog and redirect to login page (setting isIdleTimeout to true redirects to login)
                logout(true)
              },
              buttonProps: {
                stylingMode: 'contained',
                type: 'normal',
                width: 100,
                'data-testid': 'cancel-button',
              },
            },
          ],
        })
      }
    }
  }, [ACCESS_TOKEN, dialogShown, logout])

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null

    const startInterval = () => {
      if (!dialogShown) {
        checkTokenExpiration() // Run an initial check immediately
        interval = setInterval(checkTokenExpiration, 30000)
      }
    }

    // Handle browser visibility change
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible' && !dialogShown) {
        startInterval()
      } else if (interval) {
        clearInterval(interval)
        interval = null
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    // Call immediately to start the initial timeout and interval if tab is visible
    // (Don't wait for a visibility change to start checking)
    handleVisibilityChange()

    // Cleanup on unmount
    return () => {
      if (interval) {
        clearInterval(interval)
        interval = null
      }
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [checkTokenExpiration, dialogShown])

  return null
}
