import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'
import Tile from './tile'
import StarIcon from '@mui/icons-material/Star'
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined'
import { useFileClientApi } from '../../hooks/use-file-api'
import { EntityPartnershipDetails } from '../../api-client/investor-portal-client'
import { useCallback, useEffect, useState } from 'react'
import { getClientGroupPageRoute } from '../utility/route-creation'
import './admin-name-card.scss'
import { useEntityClientApi } from '../../hooks/use-entity-api'
import { userEntityGroupState, userProfileFromIdmAtom } from '../../state/atom'
import { useRecoilValue, useRecoilState } from 'recoil'
import produce from 'immer'
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined'
import { MenuProps } from '../../client-models/clientmodels'
import { useNavigate } from '../../hooks/useNavigationGuard'
import { useClientEditPermissionFunctions } from './use-client-edit-permission-functions'

interface Props {
  id: number
  name: string
  logo: string
  url: string
  isFavorite: boolean
  list?: Array<string>
  abortSignal: AbortSignal
  onEditClient: (entityGroupId: number) => void
}

export const AdminNameCard = ({
  id,
  name,
  logo,
  url,
  isFavorite,
  abortSignal,
  onEditClient,
}: Props) => {
  const [userEntityGroups, setUserEntityGroups] =
    useRecoilState(userEntityGroupState)
  const fileApi = useFileClientApi()
  const entityClientApi = useEntityClientApi()
  const [entityCount, setEntityCount] = useState<EntityPartnershipDetails>()
  const { userProfileResult } = useRecoilValue(userProfileFromIdmAtom)
  const navigate = useNavigate()

  const { userCanEditClient } = useClientEditPermissionFunctions()

  //star icon click, if not favorite then make favorite else vice-versa
  const favoriteClickHandler = async () => {
    const favoriteclientInfo = {
      userId: userProfileResult.id,
      clientGroupId: id,
      isFavorite: isFavorite,
    }
    const newValue = await entityClientApi.addFavroiteClientGroup(
      favoriteclientInfo
    )
    setUserEntityGroups(
      produce(userEntityGroups, (draft) => {
        const updateEntityGroup = draft.groups.find((x) => x.id === id)
        if (updateEntityGroup) {
          updateEntityGroup.isFavorite = newValue
        }
      })
    )
  }

  useEffect(() => {
    fileApi.getEntityPartnershipCount(id, abortSignal).then((response) => {
      setEntityCount(response)
    })
  }, [id])

  const onClickHandler = useCallback(() => {
    navigate(getClientGroupPageRoute(id))
  }, [id])

  // Buttons for the context menu.
  const contextMenu: MenuProps[] = [
    {
      text: 'Edit Client',
      icon: 'edit',
      action: () => {
        onEditClient(id)
      },
    },
  ]
  return (
    <tr key={`client-group-tile-${id}`} className='client-group-tile'>
      <td>
        <Tile
          onEditClient={onEditClient}
          title={name}
          id={id}
          isContextMenuVisible={true}
          clientMenus={contextMenu}
        >
          <div
            className='cui-c_tile-image align-center client-group-image'
            onClick={onClickHandler}
          >
            {logo && <img src={logo} alt='investor group img' />}
            {!logo && <ImageOutlinedIcon className='tile-placeholder-image' />}
          </div>
          <div className='cui-c_tile-content'>
            <div className='cui-c_card-group'>
              <div className='cui-c_card'>
                <h3>
                  {entityCount?.entitiesCount?.toString()
                    ? entityCount?.entitiesCount
                    : '#'}
                </h3>
                <label>Allocating Entities</label>
              </div>

              <div className='cui-c_card'>
                <h3>
                  {entityCount?.investorsCount?.toString()
                    ? entityCount?.investorsCount
                    : '#'}
                </h3>
                <label>Investors</label>
              </div>

              <div className='cui-c_card'>
                <h3>
                  {entityCount?.contactsCount?.toString()
                    ? entityCount?.contactsCount
                    : '#'}
                </h3>
                <label>Contacts</label>
              </div>

              <div className='cui-c_card placeholder-align-center'>
                <h3>
                  {!isFavorite ? (
                    <StarBorderOutlinedIcon
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => {
                        favoriteClickHandler()
                      }}
                    />
                  ) : (
                    <StarIcon
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => {
                        favoriteClickHandler()
                      }}
                    />
                  )}
                </h3>
              </div>
            </div>
          </div>
        </Tile>
      </td>
    </tr>
  )
}
