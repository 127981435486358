import { getAccessToken } from '@rsmus/react-auth'
import {
  BulkUploadResult,
  EntityGroupView,
  EntitySearchRequest,
  EntityView2,
  FavoriteClientGroupsView,
  FavoriteInvestorClientGroupView,
  FilterOptionRequest,
} from '../api-client/entity-manager-client-v3'
import {
  entityClientApi,
  filesClientApi,
} from '../api-client/investor-portal-client-runtime'
import { REACT_APP_ENGAGEMENTMANAGERURL } from '../envVariables'
import { BulkUploadDetail } from '../components/entity-manager/entity-utils'
import { wrapApiWithCommonErrorHandlers } from './api-error-wrapper'

/** Entity API Wrapper */
export const useEntityClientApi = () => {
  /** Add new entity group with feature flags */
  const addClient = async (entityGroupView: EntityGroupView) => {
    return await entityClientApi.entityGroup_AddEntityGroup(entityGroupView)
  }
  /** Gets entity group with feature flags */
  const getClient = async (entityGroupId: number, abortSignal?: AbortSignal) => {
    return await entityClientApi.entityGroup_GetEntityGroup(entityGroupId, abortSignal)
  }
  /** Updates entity group with feature flags */
  const updateClient = async (entityGroupView: EntityGroupView) => {
    return await entityClientApi.entityGroup_UpdateEntityGroup(entityGroupView)
  }

  /**Insert or Delete the Favorite list list of clients*/
  const addFavroiteClientGroup = async (
    favoriteClientGroupsView: FavoriteClientGroupsView
  ) => {
    return await entityClientApi.favorites_InsertOrDeleteFavoriteClientGroups(
      favoriteClientGroupsView
    )
  }

  /**Insert or Delete the Investor Favorite list list of clients*/
  const addInvestorFavoriteClientGroup = async (
    favoriteInvestorClientGroupView: FavoriteInvestorClientGroupView
  ) => {
    return await entityClientApi.favorites_InsertOrDeleteInvestorClientGroup(
      favoriteInvestorClientGroupView
    )
  }

  /*** Get Entities based on the search creteria */
  const getEntities = async (
    searchRequest: EntitySearchRequest,
    signal?: AbortSignal
  ) => {
    return await entityClientApi.entityManagement_GetEntities(
      searchRequest,
      signal
    )
  }

  /*** Get selection state based on the cache id */
  const getSelectionState = async (
    cacheId: string,
    signal?: AbortSignal | undefined
  ) => {
    return await entityClientApi.entityManagement_GetSelectionState(
      cacheId,
      signal
    )
  }

  /*** Set Entitity selection state based on the cacheId & entity id */
  const setEntitySelectionState = async (
    cacheId: string | null,
    entityId: number,
    newValue: boolean,
    signal?: AbortSignal | undefined
  ) => {
    return await entityClientApi.entityManagement_SetEntitySelectionState(
      cacheId!,
      entityId,
      newValue,
      signal
    )
  }

  /*** Set All Entitity selection state based on the cacheId */
  const setAllEntitySelectionStatus = async (
    cacheId: string | null,
    newValue: boolean,
    filterValue: string,
    signal?: AbortSignal | undefined
  ) => {
    return await entityClientApi.entityManagement_SetAllEntitySelectionStatus(
      cacheId!,
      newValue,
      filterValue,
      signal
    )
  }

  /*** Get filter options based on the cacheId, filterField, skip and take */
  const getFilterOptions = async (
    cacheId: string | undefined,
    filterField: string | undefined,
    skip: number,
    take: number,
    filterValue: string,
    isRSMClient: boolean | undefined,
    signal?: AbortSignal | undefined
  ) => {
    const filterOptionRequest: FilterOptionRequest = {
      cacheId: cacheId,
      filterField: filterField,
      filterValue: filterValue,
      isRSMClient: isRSMClient,
      skip: skip,
      take: take,
    }
    return await entityClientApi.entityManagement_GetEntityRecordFilterOptions(
      filterOptionRequest,
      signal
    )
  }

  /*** Clean service side cache data based on the cacheId */
  const cleanupServerSideCache = async (cacheId: string | null) => {
    return await entityClientApi.entityManagement_EndSelectionSession(cacheId!)
  }

  /** Call API to get the entity based on the entity id */
  const getEntityById = async (id: number, signal?: AbortSignal) => {
    return await entityClientApi.entity_Get2(id, signal)
  }

  /** Call API to get allocating entities based on the entity group id */
  const getAllocatingEntities = async (
    entityGroupIds: number[],
    signal?: AbortSignal
  ) => {
    return await entityClientApi.entity_GetPartnershipsForGroup(
      entityGroupIds,
      signal
    )
  }

  /** Returns IDM/CEM information about a user of a specified email address. */
  const getUserManagementInfo = async (emailAddress: string) => {
    return await entityClientApi.userManagement_GetUserManagementInfo(
      emailAddress
    )
  }

  /** Resends the activation email to an IDM user, specified by their user ID. */
  const resendActivationEmail = async (userId: string) => {
    return await entityClientApi.userManagement_ResendActivationEmail(userId)
  }

  /** Resets a user's email, through IDM, specified by their user ID. */
  const resetUserPassword = async (userId: string) => {
    return await entityClientApi.userManagement_ResetUserPassword(userId)
  }

  /** Delete Entity By Id */
  const deleteEntityById = async (cacheId: string | null, id: number) => {
    return await filesClientApi.user_DeleteEntityById(cacheId, id)
  }

  /**  Check the status of the investor to see if they have any files or associations with State Manager*/
  const checkInvestorStatusForDelete = async (id: number) => {
    return await filesClientApi.user_CheckInvestorStatus(id)

  }


  /** Updates an existing entity in the database through the API. */
  const updateEntity = async (entityView: EntityView2) => {
    if (!entityView.id) {
      throw new Error(`Entity must have an id to be updated`)
    }

    return await entityClientApi.entity_UpdateEntity(entityView.id, entityView)
  }

  /** Save or Update entity which is being served from a cache (most cases)*/
  const insertOrUpdateEntity = async (
    entityView: EntityView2,
    cacheId: string
  ) => {
    if (entityView.id) {
      return await entityClientApi.entity_Put(
        entityView.id,
        cacheId,
        entityView
      )
    } else {
      return await entityClientApi.entity_Post(cacheId, entityView)
    }
  }

  /**  Get All the Client Groups List from Entity Manager */
  const getAllClientGroups = async (abortSignal?: AbortSignal) => {
    //Do not enable server side search (so always send null); using built-in search from Devextreme datagrid
    return await entityClientApi.entityGroup_GetAll(abortSignal)
  }

  /**Get Bulk Entity Download from Entity Manager */
  const downloadBlankTemplate = async () => {
    return await entityClientApi.fileUpload_BulkEntityTemplate()
  }

  /**  Upload the entities in bulk from sheet  */
  const uploadBulkEntities = async (
    uploadDetail: BulkUploadDetail,
    file: File
  ) => {
    // Get the access token
    const accessToken = await getAccessToken()
    const data = new FormData()
    data.append('file', file)

    const response = await fetch(
      REACT_APP_ENGAGEMENTMANAGERURL +
      `/v3/FileUpload/client/${uploadDetail.clientGroupId}/bulkentity?isRsmClient=${uploadDetail.isRsmClient}&overrideEmail=${uploadDetail.overrideEmail}`,
      {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          Authorization: 'bearer ' + accessToken!,
          'Content-Type': 'application/octet-stream',
        },
        body: data,
      }
    )

    if (response.ok) {
      return (await response.json()) as BulkUploadResult
    }

    throw new Error(await response.text())
  }

  /** Returns permission data for the current user from the server, based on their roles in CEM. */
  const getUserPermissionData = async (abortSignal?: AbortSignal) => {
    return await entityClientApi.accessManager_GetAccessManagerInfo(abortSignal)
  }

  return wrapApiWithCommonErrorHandlers({
    addClient,
    getClient,
    updateClient,
    addFavroiteClientGroup,
    addInvestorFavoriteClientGroup,
    getEntities,
    getSelectionState,
    setEntitySelectionState,
    setAllEntitySelectionStatus,
    getFilterOptions,
    cleanupServerSideCache,
    getEntityById,
    getAllocatingEntities,
    getUserManagementInfo,
    resendActivationEmail,
    resetUserPassword,
    deleteEntityById,
    insertOrUpdateEntity,
    checkInvestorStatusForDelete,
    getAllClientGroups,
    downloadBlankTemplate,
    uploadBulkEntities,
    getUserPermissionData,
    updateEntity,
  })
}
