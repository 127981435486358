import { useRecoilValue } from 'recoil'
import '../landingPage/landingPage.scss'
import { appSettingsAtom } from '../../state/atom'
import { AdminLandingPageTilesView } from './admin-landing-tiles-view'
import { AdminLandingPageListView } from './admin-landing-list-view'
import { useAuthorizedPermission } from '../../access-manager/use-authorized-permission'
import { permissionRequestAny } from '../../access-manager/permission-request-creation'
import * as permissions from '../../model/permissions'

// Admin landing page component
export const AdminLandingPage = () => {
  const appState = useRecoilValue(appSettingsAtom)

  useAuthorizedPermission([
    permissionRequestAny(permissions.PAGE_VISIBILITY_CLIENTSTATUS),
  ])
  
  /** To set the current view */
  const currentView = appState?.viewOptions['InvestorLandingPageView']
    ? appState?.viewOptions['InvestorLandingPageView']
    : 'tile'
  return (
    <>
      {currentView === 'tile' ? (
        <AdminLandingPageTilesView />
      ) : (
        <AdminLandingPageListView />
      )}
    </>
  )
}