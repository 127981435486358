import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import './parentFileTable.scss'
import {
  DataGrid,
  Column,
  Sorting,
  Selection,
  Scrolling,
  Paging,
  LoadPanel,
  HeaderFilter,
} from 'devextreme-react/data-grid'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  clientAndPartnershipStateAtom,
  entityGroupPartnershipStateAtom,
  parentFileListAtom,
  searchTextAtom,
} from '../../state/atom'
import {
  getFileType,
  getTaxYear,
  getFileDisplayStatus,
  validateFileSearchTerm,
  formatDate,
  getValueForFileTag,
  getHeaderFilterDates,
  getFormattedDateWithNeverOption,
  getFormattedDateWithOutTimeStampWithNeverOption,
  isFileFlagSet,
} from './fileUtilities'
import { useParams } from 'react-router-dom'
import { ContextMenu, Button, TextBox } from 'devextreme-react'
import { ICellData, MenuProps, dgheight } from '../../model/file-model'
import '../../sass/components/grid.scss'
import { IsAuthorized } from '../authentication/isAuthorized'
import {
  commonFileTags,
  DisplayFileStatus,
  EditParentFileModel,
  SearchTermValidationTypes,
} from '../../client-models/clientmodels'
import { File, FileStatus } from '../../api-client/investor-portal-client'
import { DateFormat } from '../../model/date-formats'
import { DeleteFileConfirmation } from './deleteFileConfirmation'
import { useFileClientApi } from '../../hooks/use-file-api'
import { CounterDisplay } from '../reuasble-components/fileCountDisplay'
import { Grid, Icon } from '@mui/material'
import { addToolTipOnCellPrepared } from '../utility/column-utility'
import { PublishFiles } from './publishFiles'
import { AddDocumentDialog } from './addDocumentDialog'
import { ManageEmailTemplateDialog } from '../modal/emailTemplateDialog/manageEmailTemplateDialog'
import {
  getChildFileListRoute,
  getClientGroupPageRoute,
} from '../utility/route-creation'
import { EditParentFile } from './editParentFileTable'
import { useCommonDialogs } from '../modal/commonDialog/common-dialog-operations'
import { HeaderFilterDataSourceOptions } from '../../../types/types'
import { RelinkParentFiles } from './relinkParentFiles'
import FolderZipOutlinedIcon from '@mui/icons-material/FolderZipOutlined'
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined'
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined'
import { SpinnerModal } from '../modal/spinnerModal'
import produce from 'immer'
import { taxYearState } from '../../common-components/banners/tax-year-control/tax-year-control-state'
import PeopleIcon from '@mui/icons-material/People'
import { BulkDownloadEntitySpreadsheet } from './bulkDownloadEntitySpreadsheets'
import { ProvisionUsers } from '../user/provisionUsers'
import { ProvisionUsersStatus } from '../user/provisionUsersStatus'
import InfoIcon from '@mui/icons-material/Info'
import { getClientId } from '../../utilities/utilities'
import { EventInfo } from 'devextreme/events'
import dxDataGrid from 'devextreme/ui/data_grid'
import { ClearFiltersButton } from '../../common-components/clear-filter/clear-filters-button'
import { useNavigate } from '../../hooks/useNavigationGuard'
import { downloadReport } from '../../signalr/watcher-utilities/download-report'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { PDFPreview } from './file-preview'
import { DISMOUNT_ABORT_REASON } from '../utility/abort-constants'
import { FileFlagProperties } from '../../api-client/file-flags-override'
import { ToolTarget } from '../../common-components/toolTarget'
import { ZipUploadConfirmationSpreadsheets } from './zipUploadConfirmationSpreadsheets'
import * as Permissions from '../../model/permissions'
import {
  permissionRequestAny,
  permissionRequestClientById,
  permissionRequestEntityById,
} from '../../access-manager/permission-request-creation'
import { useAuthorizedPermission } from '../../access-manager/use-authorized-permission'
import { PermissionInfo } from '../../model/permissions'
import { useHasPermission } from '../../access-manager/use-permission'

/** Set context menu text and css class */
export function renderMenuItem(e: MenuProps) {
  let icon = <span className='material-icons-outlined'>{e.icon}</span>
  switch (e.icon) {
    case 'FolderZipOutlinedIcon':
      icon = <FolderZipOutlinedIcon />
      break
    case 'PersonAddAltOutlinedIcon':
      icon = <PersonAddAltOutlinedIcon />
      break
    case 'PersonSearchOutlinedIcon':
      icon = <PersonSearchOutlinedIcon />
      break
    case 'peopleicon':
      icon = <PeopleIcon />
      break
  }
  return (
    <>
      {e.tooltip && (
        <div className='parent-menu-items'>
          <ToolTarget toolTipContent={e.tooltipText}>
            <span className='menu-items-pointer'>
              {icon}
              {e.items ? <span className='dx-icon-spinright' /> : null}
              {e.text}
            </span>
          </ToolTarget>
        </div>
      )}
      {!e.tooltip && (
        <>
          {icon}
          {e.items ? <span className='dx-icon-spinright' /> : null}
          {e.text}
        </>
      )}
    </>
  )
}

//calculate the last activity date for 'createdDate' column
const calculateCreatedDate = (cellData: File) => {
  return getFormattedDateWithNeverOption(
    cellData.createdDate,
    DateFormat.isoDateTimeFormat
  )
}

/** Updates the list of dates displayed in the date header filter
 * Why outside: To avoid re-render we kept outside of ParentFileTable component.
 */
function createdDateHeaderFilter(options: HeaderFilterDataSourceOptions) {
  options.dataSource.paginate = false
  options.dataSource.postProcess = getHeaderFilterDates
}

/** Calculate Matching Criteria colum Filter expression for 'DateAdded' column filter */
function calculateCreatedDateFilterExpression(
  this: Column,
  value: any,
  selectedFilterOperations: string,
  target: string
) {
  if (target === 'headerFilter') {
    return [[getFormattedDate, '=', value]]
  }

  return this.defaultCalculateFilterExpression(
    value,
    selectedFilterOperations,
    target
  )
}

function getFormattedDate(rowData: File) {
  return getFormattedDateWithOutTimeStampWithNeverOption(
    rowData.createdDate,
    DateFormat.shortDateFormat
  )
}

/** Display Parent files */
export const ParentFileTable = () => {
  const ref = useRef<DataGrid>(null)
  const { files, isLoading } = useRecoilValue(parentFileListAtom)
  const [filterString, setFilterString] = useRecoilState(searchTextAtom)
  const [apiTimer, setApiTimer] = useState<any>()
  const [showFileUpload, setShowFileUpload] = useState(false)
  const [showEmailTemplate, setShowEmailTemplate] = useState(false)
  const navigate = useNavigate()
  const { groupId, partnershipId } = useParams() as {
    groupId: string
    partnershipId: string
  }
  const groupIdValue = parseInt(groupId)
  const partnershipIdValue = parseInt(partnershipId)
  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const [deleteVisible, setDeleteVisible] = useState(false)
  const [enablePublish, setEnablePublish] = useState(false)
  const [publishVisible, setPublishVisible] = useState(false)
  const [editVisible, setEditVisible] = useState(false)
  const [editFileData, setEditFileData] = useState<EditParentFileModel>()
  const [showReLinkModal, setShowReLinkModal] = useState(false)
  const [showProvisionUsersModal, setShowProvisionUsersModal] = useState(false)
  const [showProvisionUsersStatusModal, setShowProvisionUsersStatusModal] =
    useState(false)
  const [showBulkDownloadModal, setShowBulkDownloadModal] = useState(false)
  const [
    showZipVerificationDownloadModal,
    setShowZipVerificationDownloadModal,
  ] = useState(false)
  const commonDialogApi = useCommonDialogs()
  const clientApi = useFileClientApi()
  const [reportCompletePrmoise, setReportCompletePrmoise] =
    useState<Promise<any>>()
  const [showSpinner, setShowSpinner] = useState<boolean>(false)
  const [taxYear, setTaxYear] = useRecoilState(taxYearState)
  const clientGroupInfo = useRecoilValue(clientAndPartnershipStateAtom)
  const [parentId, setParentId] = useState<number | undefined>(0)
  const [documentCount, setDocumentCount] = useState<number>(0)
  const clients = useRecoilValue(entityGroupPartnershipStateAtom)
  const commonDialog = useCommonDialogs()
  const [previewerVisible, setPreviewerVisible] = useState<boolean>(false)
  const [unmappedVerificationFile, setUnmappedVerificationFile] =
    useState<File>()
  const [previewFile, setPreviewFile] = useState<{
    fileName: string
    fileId: number
  }>()

  // Redirect the user if they dont't have permission to this entity.
  useAuthorizedPermission([
    permissionRequestEntityById(
      Permissions.PAGE_VISIBILITY_COMPOSITEDOCS,
      partnershipIdValue
    ),
  ])

  // Get the function to check permissions.
  const hasPermissions = useHasPermission()

  // Get all of the permissions needed for this page.  They should all basically
  //  be the same construction, with different permission names.
  const permissionMap = new Map<PermissionInfo, boolean>()

  // Start with just the entity-level permissions.
  ;[
    Permissions.COMPOSITEDOCS_ADD_NEW,
    Permissions.COMPOSITEDOCS_PUBLISH,
    Permissions.COMPOSITEDOCS_EDIT_ENTITYEMAILTEMPLATE,
    Permissions.COMPOSITEDOCS_RELINK,
    Permissions.COMPOSITEDOCS_DOWNLOAD_MAPPING,
    Permissions.COMPOSITEDOCS_EDIT_PACKAGE,
    Permissions.COMPOSITEDOCS_PUBLISH,
    Permissions.COMPOSITEDOCS_DOWNLOAD_PACKAGE,
    Permissions.COMPOSITEDOCS_DELETE_PACKAGE,
    Permissions.ZIPUPLOAD_EXPORT,
    Permissions.PROVISION_ACTION_INVESTOR,
  ].forEach((p) => {
    permissionMap.set(
      p,
      hasPermissions(permissionRequestEntityById(p, partnershipIdValue))
    )
  })

  // Get the items that are client-level.
  ;[
    Permissions.ENTITY_REPORT_POPULATED,
  ].forEach((p) => {
    permissionMap.set(
      p,
      hasPermissions(permissionRequestClientById(p, groupIdValue))
    )
  })

  useEffect(() => {
    /** Clear Filter in DataGrid if there's any change in partnershipId */
    ref.current?.instance.clearFilter()
  }, [partnershipId])

  /** are files available? */
  const areFilesAvailable = () => files.length > 0

  /**Close the Bulk Download Modal */
  const bulkDownloadModalClose = () => {
    setShowBulkDownloadModal(false)
  }

  /**Close the zip Verification Modal*/
  const zipVerificationModalClose = () => {
    setShowZipVerificationDownloadModal(false)
  }

  /** Bind context menu with the grid column */
  const setActionColumn = (cellData: ICellData<File>) => {
    const selectedRowData = cellData.data!

    // Create the ID for the button, so we can attach our context menu to it.
    const buttonId = `btnMenuAction-${selectedRowData.id}`

    // Find out if the file is an unmapped zip file.
    var isUnmappedZipFile = isFileFlagSet(
      selectedRowData.flags!,
      FileFlagProperties.IsUnmappedZipFile
    )

    // If this is an unmapped zip, find out if it's been verified.
    var isUnmappedZipVerified = isFileFlagSet(
      selectedRowData.flags!,
      FileFlagProperties.IsZipVerified
    )

    // Disabling Publish button based on file status and publish flag
    const publishDisabled: boolean =
      selectedRowData.published! ||
      selectedRowData.status === FileStatus.UploadError ||
      (selectedRowData.clientInfo?.childrenHasUploadError! &&
        selectedRowData.clientInfo?.childCount! <= 0 &&
        selectedRowData.clientInfo?.errorCount! >
          selectedRowData.clientInfo?.childCount!) ||
      selectedRowData.status === FileStatus.Inactive ||
      selectedRowData.clientInfo?.childFilesInactive! ||
      (isUnmappedZipFile && !isUnmappedZipVerified)

    // Disabling DownloadBulkEntitySpreadsheets button based on file status
    const bulkDownloadDisabled: boolean =
      selectedRowData.clientInfo?.childCount === 0

    // Disable Manage Email Template option
    const manageEmailDisabled: boolean =
      selectedRowData.clientInfo?.childCount! > 0 ? false : true

    // Disable the Zip Upload Export button if files count is 0 and unmapped zip file OR all child files are not linked
    const isZipUploadExportDisable =
      (isUnmappedZipFile && selectedRowData.clientInfo?.childCount! < 1) ||
      (isUnmappedZipFile && selectedRowData.clientInfo?.allChildFilesNotLinked)

    // Buttons for the context menu, with the appropriate actions.
    const contextMenu: MenuProps[] = [
      {
        text: 'Edit',
        icon: 'create',
        disabled: !permissionMap.get(Permissions.COMPOSITEDOCS_EDIT_PACKAGE),
        action: () => {
          let fileData = cellData.data!
          const editFile: EditParentFileModel = {
            fileId: fileData.id!,
            taxYear: getValueForFileTag(commonFileTags.taxYear, fileData.tags)!,
            fileType: getValueForFileTag(
              commonFileTags.DocumentSubType,
              fileData.tags
            )!,
            emailTemplate: fileData.emailTemplate!,
            addedBy: fileData.createdByFullName!,
            dateAdded: new Date(fileData.createdDate!).toLocaleString(),
            documentFileName: fileData.externalFileName!,
          }
          setEditFileData(editFile)
          setEditVisible(true)
        },
      },
      {
        text: 'Download Bulk Entity Spreadsheet',
        icon: 'peopleicon',
        disabled:
          !permissionMap.get(Permissions.ENTITY_REPORT_POPULATED) ||
          bulkDownloadDisabled,
        action: () => {
          if (!bulkDownloadDisabled) {
            setShowBulkDownloadModal(true)
            setParentId(cellData.data!.id)
          }
        },
      },
      {
        text: 'Zip Upload Export',
        icon: 'peopleicon',
        visible: isUnmappedZipFile,
        disabled: isZipUploadExportDisable || !permissionMap.get(Permissions.ZIPUPLOAD_EXPORT),
        action: () => {
          if (isUnmappedZipFile) {
            setShowZipVerificationDownloadModal(true)
            setUnmappedVerificationFile(selectedRowData)
          }
        },
      },
      {
        text: 'Publish',
        icon: 'publish',
        disabled:
          !permissionMap.get(Permissions.COMPOSITEDOCS_PUBLISH) ||
          publishDisabled,
        // we need tooltip only if zipfile is not yet published and is unmappedZipVerified
        tooltip: isUnmappedZipFile && publishDisabled && !isUnmappedZipVerified,
        tooltipText: 'Zip Upload Export must be downloaded first',
        action: () => {
          if (!publishDisabled) {
            setSelectedFiles([cellData.data! as File])
            setPublishVisible(true)
          }
        },
      },
      {
        text: 'Manage Email Template',
        icon: 'create',
        disabled: manageEmailDisabled,
        action: () => {
          if (!manageEmailDisabled) {
            setParentId(cellData.data!.id)
            setShowEmailTemplate(true)
          }
        },
      },
      {
        text: 'Download',
        icon: 'file_download',
        disabled: !permissionMap.get(
          Permissions.COMPOSITEDOCS_DOWNLOAD_PACKAGE
        ),
        action: () => {
          handleDownloadClick(cellData.data!)
        },
      },
      {
        text: 'Delete Package',
        icon: 'delete',
        disabled: !permissionMap.get(Permissions.COMPOSITEDOCS_DELETE_PACKAGE),
        action: () => {
          setSelectedFiles([cellData.data! as File])
          setDeleteVisible(true)
        },
      },
    ]

    // Return the cell content with a button and a menu attached to it.
    return (
      <>
        <Button
          id={buttonId}
          icon='overflow'
          data-testid='context-button'
          stylingMode='text'
        />
        <ContextMenu
          dataSource={contextMenu}
          showEvent='click'
          target={`#${buttonId}`}
          itemRender={renderMenuItem}
          onItemClick={(e) => {
            const item = e.itemData as MenuProps | undefined
            if (item?.action) {
              item.action()
            }
          }}
        />
      </>
    )
  }

  /** Set PDF Preview Column in Datagrid */
  const setPreviewActionColumn = (cellData: ICellData<File>) => {
    if (cellData.data) {
      const file = cellData.data!
      // Create the ID for the button, so we can attach our context menu to it.
      const buttonId = `previewbtn-${file.externalFileId}`

      return (
        <Button
          id={buttonId}
          className='pdfpreview-button'
          disabled={!file?.externalFileName!.toLowerCase().endsWith('.pdf')}
          data-testid='preview-button'
          stylingMode='text'
          onClick={() => {
            setPreviewerVisible(true)
            setPreviewFile({
              fileId: file.id!,
              fileName: file.externalFileName!,
            })
          }}
        >
          <Icon
            className='preview-btn'
            component={VisibilityOutlinedIcon}
            width={5}
            height={5}
          ></Icon>
        </Button>
      )
    }
  }

  /** Download the selected file in Checkbox */
  const handleDownloadClick = async (selectedFile: File) => {
    try {
      await clientApi.downloadFile(selectedFile)
    } catch (err) {
      commonDialog.showDialog({
        dialogType: 'error',
        title: 'Download Error',
        content: `Error downloading file: ${err}`,
      })
    }
  }

  /** Get the selected files in Checkbox */
  const onSelectionChanged = (data: any) => {
    if (data.selectedRowsData.length > 0) {
      setEnablePublish(true)
      setSelectedFiles(data.selectedRowsData)
    } else {
      setEnablePublish(false)
    }
  }

  /** Get the list of records displayed in the datagrid */
  const onContentReady = (e: EventInfo<dxDataGrid>) => {
    setDocumentCount(e.component.totalCount())
  }

  useEffect(() => {
    const abortController = new AbortController()

    let searchTerm = filterString
    if (!searchTerm) {
      clientApi.getParentFiles(
        groupIdValue,
        partnershipIdValue,
        searchTerm,
        abortController.signal
      )
    } else {
      // If a previous call is in effect, then cancel it.
      if (apiTimer) {
        clearTimeout(apiTimer)
      }

      const timerId = setTimeout(() => {
        setApiTimer(undefined)
        // If there are less than three characters in the filter,
        //  and it's not just a number, then there's nothing to do.
        if (
          validateFileSearchTerm(searchTerm) !== SearchTermValidationTypes.Valid
        ) {
          return
        }

        clientApi.getFilteredParentFiles(
          groupIdValue,
          partnershipIdValue,
          searchTerm,
          abortController.signal
        )
      }, 500)

      setApiTimer(timerId)
      return () => {
        abortController.abort(DISMOUNT_ABORT_REASON)
      }
    }
    /** If there's any change in the ClientId, load the parent file data from the API */
  }, [partnershipIdValue, groupIdValue, filterString])

  const filteredFiles = useMemo(() => {
    if (taxYear.taxyear) {
      return files.filter((f) => getTaxYear(f) === taxYear.taxyear)
    } else {
      return files
    }
  }, [taxYear?.taxyear, files])

  // Reset the tax year dropdown value to 'All' (default)
  useEffect(() => {
    return () =>
      setTaxYear(
        produce((draft) => {
          draft.taxyear = undefined
        })
      )
  }, [])

  /** Add link to child files in the File Name column */
  const addChildFileLink = useCallback(
    (cellData: ICellData<File>) => {
      return (
        <Button
          data-role='link-button'
          onClick={() =>
            navigate(
              getChildFileListRoute(
                groupIdValue,
                partnershipIdValue,
                cellData.data!.id!
              )
            )
          }
          style={{ textTransform: 'none' }}
          stylingMode='text'
        >
          {cellData.value}
        </Button>
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [groupId, partnershipId]
  )

  /** Set date added column value by formatting the date using momment.js library
   * Display the created date field as tooltip
   */
  const setDateAdded = (cellData: ICellData<File>) => {
    if (cellData.rowType === 'data' && cellData.data?.createdDate) {
      const createdDate = new Date(cellData.data?.createdDate)
      let displayDate = formatDate(createdDate)
      let titleDate = getFormattedDateWithNeverOption(
        createdDate,
        DateFormat.longDateTimeFormat
      )
      return <span title={titleDate}>{displayDate}</span>
    }
    return ''
  }

  /** Set the background color based on status value
   * Display the status field as tooltip
   */
  const setStatusStyle = (cellData: ICellData<File>) => {
    if (cellData.rowType === 'data' && cellData?.value) {
      let status = cellData?.value
      let cellClass = ''
      if (
        status === DisplayFileStatus.Published ||
        status === DisplayFileStatus.PublishedWithError
      ) {
        cellClass = 'statusColumnGreen'
      } else if (
        status === DisplayFileStatus.ReadyToPublish ||
        status === DisplayFileStatus.ReadyToPublishWithError
      ) {
        cellClass = 'statusColumnBlue'
      } else {
        cellClass = 'statusColumnGrey'
      }

      //if statusMessage has some value then we are showing infoIcon with tooltip
      if (!cellData.data?.statusMessage!) {
        return (
          <span title={status} className={cellClass}>
            {status}
          </span>
        )
      } else {
        return (
          <>
            <ToolTarget
              toolTipContent={cellData.data.statusMessage}
              isInline={true}
            >
              <InfoIcon className='parent-info-icon-style' />
            </ToolTarget>
            <span className={cellClass}>{status}</span>
          </>
        )
      }
    }
    return ''
  }

  /** Close the publish moadal and reset the selected files */
  const publishModalClose = () => {
    setPublishVisible(false)
  }

  /** Close the publish modal,reset the selected files and grid selection */
  const publishModalConfirm = () => {
    setPublishVisible(false)
    setEnablePublish(false)
    setSelectedFiles([])
    ref.current?.instance.clearSelection()
  }

  /** Close the edit moadal and reset the selected files */
  const editModalClose = () => {
    setEditVisible(false)
    setSelectedFiles([])
  }

  /** Close the relink investors moadal and reset the selected files */
  const relinkModalClose = () => {
    setShowReLinkModal(false)
    setSelectedFiles([])
  }
  const handleSearchChange = useCallback((value: string) => {
    setFilterString(value)
  }, [])

  // Shows error modal based on file status.Displays error modal when
  // selected files contains file(s) with status 'inactive'/ 'not ready to publish'
  const doPublish = () => {
    if (
      selectedFiles &&
      selectedFiles.length !== 0 &&
      selectedFiles.some((f) => {
        const fileStatus = getFileDisplayStatus(f)
        return (
          fileStatus === DisplayFileStatus.NotReadyToPublish ||
          fileStatus === DisplayFileStatus.Inactive
        )
      })
    ) {
      commonDialogApi.showDialog({
        content: 'One or more file(s) are not ready to publish.',
        dialogType: 'error',
      })
    } else if (
      selectedFiles &&
      selectedFiles.length !== 0 &&
      selectedFiles.every((f) => {
        const fileStatus = getFileDisplayStatus(f)
        return (
          fileStatus === DisplayFileStatus.PublishedWithError ||
          fileStatus === DisplayFileStatus.Published
        )
      })
    ) {
      commonDialogApi.showDialog({
        content: 'Selected file(s) are already published.',
        dialogType: 'error',
      })
    } else {
      setPublishVisible(true)
    }
  }

  /**  Shows error modal based on file status.Displays error modal when
   * Zip Upload Template button clicked action - progress and failed */
  const zipUploadTemplateDownload = async () => {
    setShowSpinner(true)
    //generating random number for operationId
    const operationId = Math.floor(Math.random() * 100000)
    // API call to get the sas-URI
    const downloadZipTemplatePromise = clientApi.getZipUploadTemplate(
      groupIdValue,
      getClientId(clients, groupIdValue, partnershipIdValue),
      operationId
    )
    // call the download report helper method which will take cate of download report as well as any operation error
    const downloadReportPromise = downloadReport(
      downloadZipTemplatePromise,
      operationId
    )
    setReportCompletePrmoise(downloadReportPromise)
    // API action completed - hide the modelSpinner for success else show the error message in model
    downloadReportPromise.then(() => {
      //hideing the spinner model
      setShowSpinner(false)
    })
  }

  const masterContextMenu: MenuProps[] = [
    {
      text: 'Add Document',
      icon: 'add',
      disabled: !permissionMap.get(Permissions.COMPOSITEDOCS_ADD_NEW),
      action: () => {
        setShowFileUpload(true)
      },
    },
    {
      text: 'Publish',
      icon: 'publish',
      disabled:
        !permissionMap.get(Permissions.COMPOSITEDOCS_PUBLISH) || !enablePublish,
      action: () => {
        doPublish()
      },
    },
    {
      text: 'Manage Email Template',
      icon: 'create',
      action: () => {
        setParentId(undefined)
        setShowEmailTemplate(true)
      },
    },
    {
      text: 'Re-link Investors',
      icon: 'link',
      disabled: !permissionMap.get(Permissions.COMPOSITEDOCS_RELINK),
      action: () => {
        setSelectedFiles(filteredFiles)
        setShowReLinkModal(true)
      },
    },
    {
      text: 'Zip Upload Template',
      icon: 'FolderZipOutlinedIcon',
      disabled: !permissionMap.get(Permissions.COMPOSITEDOCS_DOWNLOAD_MAPPING),
      action: () => {
        zipUploadTemplateDownload()
      },
    },
    {
      text: 'Provision Users',
      icon: 'PersonAddAltOutlinedIcon',
      disabled:
        !permissionMap.get(Permissions.PROVISION_ACTION_INVESTOR) ||
        !areFilesAvailable(),
      action: () => {
        setShowProvisionUsersModal(true)
      },
    },
    {
      text: 'Provision Status',
      icon: 'PersonSearchOutlinedIcon',
      disabled:
        !permissionMap.get(Permissions.PROVISION_ACTION_INVESTOR) ||
        !areFilesAvailable(),
      action: () => {
        setShowProvisionUsersStatusModal(true)
      },
    },
  ]

  useEffect(() => {
    // This hook handles a glitch on the datagrid which prevents the context menu from updating
    //  properly when a file is uploaded and subsequently finishes linking.  The actual issue appears
    //  on zip files without mapping files for the "ZIP Upload Export" button.  The childInfo property on the
    //  parent file does not reflect the updated application state's value when being passed to the
    //  renderMenuItem method.  As such, the button is disabled.
    //  Another alternative is to set the repaintChangesOnly property on the grid to true.  This property
    //  is traditionally set to false on datagrids, and setting it to true may lead to other undesirable issues.
    ref.current?.instance.refresh()
  }, [files])

  return (
    <IsAuthorized
      checkType='any'
      permissions={[
        permissionRequestAny(Permissions.ADMIN),
        permissionRequestAny(Permissions.INTERNAL),
      ]}
    >
      <div className='ip-table'>
        <Grid
          className='parent-file-toolbar'
          container
          justifyContent={'flex-start'}
        >
          <Grid item xs={0.5} md={0.25}>
            <Button
              onClick={() => navigate(getClientGroupPageRoute(groupId))}
              stylingMode='outlined'
              data-testid='btnBack'
            >
              <span className='dx-icon-arrowleft'></span>
            </Button>
          </Grid>
          <Grid item xs={1} md={0.25} className='document-count-style'>
            <CounterDisplay
              count={filteredFiles.length}
              isVisible={!isLoading}
              title='Documents'
            />
          </Grid>
          <Grid
            item
            xs={10.5}
            md={11.5}
            style={{ display: 'flex' }}
            justifyContent={'flex-end'}
          >
            {/* CLEAR FILTERS */}
            <div>
              <ClearFiltersButton gridRef={ref} />
            </div>
            <div>
              <TextBox
                width={400}
                placeholder='Search investor name, ein/ssn'
                value={filterString}
                valueChangeEvent='input'
                mode='search'
                onValueChange={handleSearchChange}
                showClearButton={true}
              />
            </div>
            <div className='parent-file-actionbtn'>
              <Button
                stylingMode='outlined'
                id='masterActionBtn'
                data-testid='btnMasterAction'
              >
                <span className='dx-icon-overflow'></span>
              </Button>
              <ContextMenu
                dataSource={masterContextMenu}
                itemRender={renderMenuItem}
                showEvent='mouseenter'
                target='#masterActionBtn'
                onItemClick={(e) => {
                  const item = e.itemData as MenuProps | undefined
                  if (item?.action) {
                    item.action(selectedFiles)
                  }
                }}
              />
            </div>
          </Grid>
        </Grid>
        <br />
        <DataGrid
          ref={ref}
          showBorders={true}
          noDataText={
            isLoading && filteredFiles.length === 0 && documentCount === 0
              ? 'Loading...'
              : !isLoading &&
                (filteredFiles.length === 0 || documentCount === 0)
              ? 'No Data Found.'
              : ''
          }
          onCellPrepared={addToolTipOnCellPrepared}
          dataSource={filteredFiles}
          keyExpr='id'
          columnAutoWidth={true}
          height={dgheight}
          repaintChangesOnly={true}
          showColumnLines={false}
          showRowLines={true}
          onSelectionChanged={onSelectionChanged}
          wordWrapEnabled={false}
          onContentReady={onContentReady}
          allowColumnResizing={true}
        >
          <Scrolling mode='virtual' rowRenderingMode='virtual' />
          <Paging defaultPageSize={8} />
          <Selection mode='multiple' showCheckBoxesMode='always' />
          <Sorting mode='single' />
          <LoadPanel enabled={true} />
          <HeaderFilter visible={true} allowSearch={true} />
          <Column
            cellRender={setPreviewActionColumn}
            allowSearch={false}
            allowSorting={false}
            allowFiltering={false}
            width={50}
          />
          <Column
            dataField='externalFileName'
            caption='File Name'
            cellRender={addChildFileLink}
            allowSearch={false}
            allowSorting={true}
          ></Column>
          <Column
            dataField='DocumentSubType'
            caption='File Type'
            allowSearch={false}
            allowSorting={true}
            calculateCellValue={getFileType}
          />
          <Column
            caption='# Investor Files'
            alignment='center'
            allowSearch={false}
            allowSorting={true}
            allowFiltering={true}
            calculateCellValue={(file: File) => {
              return file.clientInfo?.childCount
            }}
          />
          <Column
            dataField='taxYear'
            caption='Tax Year'
            alignment='center'
            allowSearch={false}
            allowSorting={true}
            calculateCellValue={getTaxYear}
          />
          <Column
            dataField='status'
            caption='Status'
            alignment='center'
            minWidth={200}
            allowSearch={false}
            data-includeTooltip={false}
            allowSorting={true}
            calculateCellValue={getFileDisplayStatus}
            cellRender={setStatusStyle}
          />
          <Column
            dataField='emailTemplate'
            caption='Email Template'
            allowSearch={false}
            allowSorting={true}
          />
          <Column
            calculateCellValue={calculateCreatedDate}
            caption='Date Added'
            allowSearch={false}
            allowSorting={true}
            cellRender={setDateAdded}
            data-includeTooltip={false}
            allowFiltering={true}
            defaultSortOrder='desc'
            calculateFilterExpression={calculateCreatedDateFilterExpression}
          >
            <HeaderFilter dataSource={createdDateHeaderFilter} />
          </Column>
          <Column dataField='createdByFullName' caption='Added By' />
          <Column
            caption='Action'
            cellRender={setActionColumn}
            allowSearch={false}
            allowSorting={false}
            allowFiltering={false}
          />
        </DataGrid>
      </div>
      <SpinnerModal
        visible={showSpinner}
        errorTitleMessage='Error'
        errorMessage='Zip upload template download failed.'
        inProgressTitleMessage='Zip upload template'
        inProgressMessage='Zip upload template download in-progress...'
        successTitleMessage='Zip upload template success'
        successMessage='Zip upload template downloaded successfully.'
        onClose={() => {
          setShowSpinner(false)
        }}
        apiAction={reportCompletePrmoise}
      />
      <DeleteFileConfirmation
        files={selectedFiles}
        isVisible={deleteVisible}
        onCancel={() => setDeleteVisible(false)}
        onConfirm={() => {
          setDeleteVisible(false)
          setSelectedFiles([])
        }}
      ></DeleteFileConfirmation>
      {clientGroupInfo.partnership && (
        <AddDocumentDialog
          isVisible={showFileUpload}
          onSave={() => setShowFileUpload(false)}
          onCancel={() => setShowFileUpload(false)}
          clientId={getClientId(clients, groupIdValue, partnershipIdValue)}
          partnershipId={clientGroupInfo.partnership.id!}
          entityGroupId={groupIdValue}
        />
      )}
      {editVisible && (
        <EditParentFile
          selectedFile={editFileData!}
          isVisible={editVisible}
          onCancel={editModalClose}
          onSave={editModalClose}
        ></EditParentFile>
      )}
      {showBulkDownloadModal && (
        <BulkDownloadEntitySpreadsheet
          isVisible={showBulkDownloadModal}
          onCancel={bulkDownloadModalClose}
          onConfirm={bulkDownloadModalClose}
          parentId={parentId!}
        ></BulkDownloadEntitySpreadsheet>
      )}
      <PublishFiles
        selectedFiles={selectedFiles}
        isVisible={publishVisible}
        onCancel={publishModalClose}
        onConfirm={publishModalConfirm}
      ></PublishFiles>
      <RelinkParentFiles
        files={selectedFiles}
        isVisible={showReLinkModal}
        entityGroupId={groupIdValue}
        onCancel={relinkModalClose}
        onConfirm={relinkModalClose}
      ></RelinkParentFiles>
      <ProvisionUsers
        isVisible={showProvisionUsersModal}
        entityGroupId={groupIdValue}
        clientId={getClientId(clients, groupIdValue, partnershipIdValue)}
        onClose={() => setShowProvisionUsersModal(false)}
      />
      <ProvisionUsersStatus
        isVisible={showProvisionUsersStatusModal}
        entityGroupId={groupIdValue}
        clientId={getClientId(clients, groupIdValue, partnershipIdValue)}
        onClose={() => setShowProvisionUsersStatusModal(false)}
      />
      {showEmailTemplate && (
        <ManageEmailTemplateDialog
          onCancel={() => setShowEmailTemplate(false)}
          partnershipId={clientGroupInfo.partnership?.id!}
          entityGroupId={groupIdValue}
          parentFileId={parentId!}
        ></ManageEmailTemplateDialog>
      )}
      {previewerVisible && (
        <PDFPreview
          fileName={previewFile?.fileName!}
          fileId={previewFile?.fileId!}
          onCancel={() => setPreviewerVisible(false)}
        />
      )}
      {showZipVerificationDownloadModal && (
        <ZipUploadConfirmationSpreadsheets
          onCancel={zipVerificationModalClose}
          onConfirm={zipVerificationModalClose}
          selectedVerificationFile={unmappedVerificationFile!}
        ></ZipUploadConfirmationSpreadsheets>
      )}
    </IsAuthorized>
  )
}
