import { ChildFilesTable } from './components/investor-files/childFilesTable'
import { ParentFileTable } from './components/investor-files/parentFileTable'
import Client from './ui-kit/components/investor/Client'
import { AdminLandingPage } from './components/landingPage/admin-landing-page'
import { ClientGroupDetails } from './components/client-groups/clientGroupDetails'
import { HelpPage } from './components/help/helpPage'
import { InvestorFiles } from './components/investor-view/investorFiles'
import { InvestorLandingPage } from './components/investor-view/landing-page/investor-landing-page'
import { StateManagerElection } from './components/state-manager/state-manager-election'
import { StateManagerGlobalPage } from './components/state-manager/statemanager-global'
import { TermsOfUse } from './components/layout/termsOfUse'
import { InvestorElectionMaster } from './components/investor-view/investorElectionsMaster'
import { EntityListPage } from './components/entity-manager/entity-list-page'
import { ClientsGroupList } from './components/entity-manager/clients-list/client-group-list'
import { ManageExistingInvestors } from './components/user/manageExistingInvestors'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from './components/error-boundary/ErrorFallback'
import { ErrorPage } from './components/error-boundary/error-page'

export const routes = [
  {
    path: 'admin',
    element: (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <AdminLandingPage />
      </ErrorBoundary>
    ),
  },
  {
    path: 'client',
    element: (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Client />
      </ErrorBoundary>
    ),
  },
  {
    path: 'admin/existinginvestors',
    element: (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ManageExistingInvestors />
      </ErrorBoundary>
    ),
  },
  {
    path: 'admin/groups/:groupId',
    element: (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ClientGroupDetails></ClientGroupDetails>
      </ErrorBoundary>
    ),
  },
  {
    path: 'admin/groups/:groupId/partnerships/:partnershipId',
    element: (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ParentFileTable />
      </ErrorBoundary>
    ),
  },
  {
    path: 'admin/groups/:groupId/statemanager/:taxYear',
    element: (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <StateManagerElection />
      </ErrorBoundary>
    ),
  },
  {
    path: 'admin/groups/:groupId/statemanager',
    element: (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <StateManagerGlobalPage />
      </ErrorBoundary>
    ),
  },
  {
    path: 'admin/groups/:groupId/partnerships/:partnershipId/children/:parentFileId',
    element: (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ChildFilesTable />
      </ErrorBoundary>
    ),
  },
  {
    path: 'admin/groups/:groupId/entitymanager/entities',
    element: (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <EntityListPage />
      </ErrorBoundary>
    ),
  },
  {
    path: 'help',
    element: (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <HelpPage />
      </ErrorBoundary>
    ),
  },
  {
    path: 'investor/investorfiles',
    element: (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <InvestorFiles />
      </ErrorBoundary>
    ),
  },
  {
    path: 'investor/investorlandingpage',
    element: (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <InvestorLandingPage />
      </ErrorBoundary>
    ),
  },
  {
    path: 'investor/investorfiles/:groupId/:partnerId',
    element: (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <InvestorFiles />
      </ErrorBoundary>
    ),
  },
  {
    path: 'investor/groups/:groupId/stateelections/:partnerId',
    element: (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <InvestorElectionMaster />
      </ErrorBoundary>
    ),
  },
  {
    path: 'investor/groups/:groupId/stateelections/:partnerId/:taxYear',
    element: (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <InvestorElectionMaster />
      </ErrorBoundary>
    ),
  },
  {
    path: 'terms-of-use',
    element: (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <TermsOfUse />
      </ErrorBoundary>
    ),
  },
  {
    path: 'admin/application/settings/clients',
    element: (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ClientsGroupList />
      </ErrorBoundary>
    ),
  },
  {
    path: 'error',
    element: (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ErrorPage />
      </ErrorBoundary>
    ),
  },
]
