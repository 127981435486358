import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { permissionRequestAny } from '../access-manager/permission-request-creation'
import { useHasPermission } from '../access-manager/use-permission'
import { NavDataProps } from '../components/layout/leftnav/LeftNav'
import { getEntityName } from '../components/utility/entity-utility'
import { getInvestorLandingRoute, getInvestorPageRoute, getInvestorFilesRoute, getStateManagerRouteInvestor } from '../components/utility/route-creation'
import { REACT_APP_FEATURES_STATEMANAGER } from '../envVariables'
import { investorRelationsAtom } from '../state/atom'
import { ensureNumberOrUndefined } from '../utilities/utilities'
import * as Permissions from '../model/permissions'

export function useNavDataInvestor() {

  const { groupId, partnerId } = useParams() as {
    groupId: string
    partnerId: string
  }

  //picking investorRelations from global state
  const investorRelations = useRecoilValue(investorRelationsAtom)
  const hasPermission = useHasPermission()

  /** convert string to number or undefined */
  const groupIdNumber = ensureNumberOrUndefined(groupId)
  const partnerIdNumber = ensureNumberOrUndefined(partnerId)

  const navEnableStateManager =
    !!investorRelations?.investorEntities?.stateManagerTaxYears?.find(
      (x) =>
        x.investorId === partnerIdNumber && x.clientGroupId === groupIdNumber
    )?.taxYears

  // pick the client groups. if available!
  const client = investorRelations?.investorEntities.clientGroups?.find((each) => each.id === groupIdNumber)
  // pick the client group entities. if available!
  const entity = investorRelations?.investorEntities.clientGroupEntities?.find((each) => each.id === partnerIdNumber)
  //if entity availble, then fetch it's name for lateral usage.
  const entityName = entity ? getEntityName(entity) : ""
  // notify: is statemanager active for the current page?
  const enableStateManager = REACT_APP_FEATURES_STATEMANAGER === true
  // notify: is current page having details of clientGroups and entities?
  const showIndividualInvestorFiles = !!(client && entity)


  // Memoizing nav data for Investor view
  const navData: NavDataProps | undefined = useMemo(() => {
    // If the user isn't an investor, then they can't see the menu.
    if (!hasPermission(permissionRequestAny(Permissions.INVESTOR))) {
      return undefined
    }

    return {
      default: {
        title: 'Investor',
        path: enableStateManager ? getInvestorLandingRoute() : getInvestorFilesRoute(),
        icon: 'home',
        iconsType: 'outlined',
      },
      menu: [
        {
          title: 'Documents',
          icon: 'folder',
          tooltip: '',
          visible: true,
          menu: [
            {
              title: 'All Documents',
              path: getInvestorFilesRoute(),
              tooltip: 'All Documents',
              visible: true,
            },
            {
              // if entity available
              title: `${client?.name} - ${entityName}  Documents`,
              // If showIndividualInvestorFiles == false, then just provide an empty string, because we're not
              //  going to show this menu item anyway.
              path: showIndividualInvestorFiles ? getInvestorPageRoute(groupIdNumber!, partnerIdNumber!) : '',
              tooltip: `${client?.name} - ${entityName}  Documents`,
              visible: showIndividualInvestorFiles
            },
          ],
        },
        {
          title: 'State Manager',
          // If enableStateManager == false, then just provide an empty string, because we're not
          //  going to show this menu item anyway.
          path: navEnableStateManager ? getStateManagerRouteInvestor(groupIdNumber!, partnerIdNumber!) : '',
          icon: 'place',
          tooltip: 'State Manager',
          visible: (navEnableStateManager && showIndividualInvestorFiles)
        },
      ]
    }
  }, [client, entityName, enableStateManager, showIndividualInvestorFiles, groupIdNumber, partnerIdNumber, navEnableStateManager])

  return navData
}
