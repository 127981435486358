import { useMemo } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  clientAndPartnershipStateAtom,
  entityGroupPartnershipStateAtom,
} from '../../../state/atom'
import {
  getStateManagerRoute,
  getStateManagerTaxRoute,
} from '../../utility/route-creation'
import { useNavWidgetPageItems } from '../nav-hooks'
import { CLIENT_GROUP_MENU_LEVEL } from '../widget-levels'
import { taxYearState } from '../../../common-components/banners/tax-year-control/tax-year-control-state'
import { useParams } from 'react-router-dom'
import { useHasPermission } from '../../../access-manager/use-permission'
import { permissionRequestClientById } from '../../../access-manager/permission-request-creation'
import * as Permissions from '../../../model/permissions'

export const useStateManagerNavWidget = () => {
  const { groupId } = useParams() as {
    groupId: string
  }
  const groupIdNumber = parseInt(groupId)
  const clientAndPartnership = useRecoilValue(clientAndPartnershipStateAtom)
  const [taxYear] = useRecoilState(taxYearState)

  // Check if the user has permission to view the State Manager page
  const hasStateManagerVisibilityPermission = useHasPermission()(
    permissionRequestClientById(
      Permissions.PAGE_VISIBILITY_STATEMAN,
      groupIdNumber
    )
  )

  //Enabling StateManager Page with State Elections flag from recoil state
  const entityState = useRecoilValue(entityGroupPartnershipStateAtom)
  const clientState = entityState[groupIdNumber]
  const elections = clientState
    ? !!clientState.featureFlag?.stateElections
    : false

  const navPathState = useMemo(() => {
    let route: string | undefined

    /**Navigate to Global Page if No tax year selected and to Year Page when tax year selected  */
    if (clientAndPartnership.clientGroupId) {
      if (!taxYear.taxyear) {
        route = getStateManagerRoute(clientAndPartnership.clientGroupId)
      } else {
        route = getStateManagerTaxRoute(
          clientAndPartnership.clientGroupId,
          taxYear.taxyear
        )
      }
    } else {
      route = undefined
    }
    return route
  }, [clientAndPartnership.clientGroupId, taxYear.taxyear])

  const navItems = useMemo(
    () => [
      {
        label: 'State Manager',
        level: CLIENT_GROUP_MENU_LEVEL,
        visibilityPaths:
          hasStateManagerVisibilityPermission &&
          elections &&
          'admin/groups/:groupId/*',
        selectionPath: navPathState + '/*',
        navigationPath: navPathState,
        sortOrder: 0,
      },
    ],
    [elections, navPathState, hasStateManagerVisibilityPermission]
  )

  useNavWidgetPageItems(navItems)
}
