import { ButtonFunction, Modal } from '../modal/modalBase'
import { Viewer, Worker } from '@react-pdf-viewer/core'
import { SpecialZoomLevel } from '@react-pdf-viewer/core'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import { filesClientApi } from '../../api-client/investor-portal-client-runtime'
import {
  ToolbarProps,
  defaultLayoutPlugin,
} from '@react-pdf-viewer/default-layout'
import { ReactElement, useEffect, useState } from 'react'
import 'pdfjs-dist/build/pdf.worker.min.js'
import { DISMOUNT_ABORT_REASON } from '../utility/abort-constants'

interface PDFPreviewProps {
  fileName: string
  fileId: number
  onCancel: () => void
}

/** Preview the PDF inside a modal */
export const PDFPreview = ({ fileName, fileId, onCancel }: PDFPreviewProps) => {
  const [pdfURL, setPdfURL] = useState<string>('')

  /** Here is the Reference document on how to set up using Pre-built PDF
   * https://github.com/mozilla/pdf.js/wiki/Setup-pdf.js-in-a-website */
  const workerUrl = 'pdfjs-dist/build/pdf.worker.min.js'

  useEffect(() => {
    const abortController = new AbortController()

    /** If there's any change in the Parent FileId, load the parent file data blob from the API */
    filesClientApi
      .files_GetDownloadUri(fileId!, abortController.signal)
      .then((response) => {
        setPdfURL(response)
      })

    return () => {
      abortController.abort(DISMOUNT_ABORT_REASON)
    }
  }, [fileId])

  /**  Function to render the toolbar of the PDF viewer*/
  const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
    <Toolbar />
  )

  /**  Create an instance of the default layout plugin for the PDF viewer*/
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
  })

  //Create the buttons for the dialog box.
  const buttonFunctions: ButtonFunction[] = [
    {
      label: 'Close',
      onClick: () => {
        onCancel()
      },
      visible: true,
      isDefaultAction: true,
      buttonProps: {
        stylingMode: 'contained',
        type: 'normal',
        width: 100,
        'data-testid': 'cancel-button',
      },
    },
  ]

  return (
    <Modal
      visible={true}
      title={fileName}
      buttonFunctions={buttonFunctions}
      maxWidth={850}
      height='90%'
      showCloseButtonInTitle={true}
      disableScrollbar={true}
      className='preview-container'
    >
      {pdfURL && (
        <Worker workerUrl={workerUrl}>
          <Viewer
            fileUrl={pdfURL} // Pass the URL of the PDF file to the Viewer component
            defaultScale={1.5} // Set default zoom level
            plugins={[defaultLayoutPluginInstance]} // Use default layout plugin
            enableSmoothScroll={true}
          />
        </Worker>
      )}
    </Modal>
  )
}
