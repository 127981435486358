import {
  File,
  FileStatus,
  FileTag,  
} from '../../api-client/investor-portal-client'
import { DateFormat } from '../../model/date-formats'
import moment from 'moment'
import {
  commonFileTags,
  CommonFilterHeader,
  DisplayFileStatus,
  SearchTermValidationTypes,
} from '../../client-models/clientmodels'
import { FileFlagProperties, FileFlags } from '../../api-client/file-flags-override'

export function getValueForFileTag(
  tagName: string,
  tags: FileTag[] | undefined
) {
  if (!Array.isArray(tags)) return ''
  const tag = tags.find((tag) => tag.name === tagName)
  return tag?.value
}

/** Get Investor name value from file tags */
export const getInvestorName = (file: File) => {
  return getValueForFileTag(commonFileTags.PartnerName, file.tags)
}

/** Get Entity name value from file tags */
export const getEntityName = (file: File) => {
  return getValueForFileTag(commonFileTags.EntityName, file.tags)
}

/** Get Matching Criteria from file tags */
export const getMacthingCriteria = (file: File) => {
  return getValueForFileTag(commonFileTags.MatchingCriteria, file.tags)
}

/** Get Investor name value from file tags */
export const getInvestorId = (file: File) => {
  return getValueForFileTag(commonFileTags.PartnerId, file.tags)
}

/** Get Document Subtype value from file tags */
export const getFileType = (file: File) => {
  return getValueForFileTag(commonFileTags.DocumentSubType, file.tags)
}

/** Get tax year value from file tags */
export const getTaxYear = (file: File) => {
  return getValueForFileTag(commonFileTags.TaxYear, file.tags)
}

/**Get Name of parntneship */
export const getPartnershipName = (file: File) => {
  return getValueForFileTag(commonFileTags.PartnershipName, file.tags)
}

/** Returns the value of the DocumentCode tag for specified file. */
export const getDocumentCode = (file: File) => {
  return getValueForFileTag(commonFileTags.DocumentCode, file.tags)
}

/** Returns a boolean value indicating whether or not a specified file has "Generic"
 *   for a value for its DocumentCode tag. */
export const isGenericFile = (file: File) => {
  return getDocumentCode(file)?.toLocaleLowerCase() === 'generic'
}

/** Set the status value based on column value and cellInfo */
export const getFileDisplayStatus = (file: File) => {
  // Get the fileFlags status to compare
  const isUnmappedFlags: boolean = isFileFlagSet(file.flags!, FileFlagProperties.IsUnmappedZipFile)
  const isUnmappedZipVerified = isFileFlagSet(file.flags!, FileFlagProperties.IsZipVerified)

  const isPublished = file?.published
  const clientInfo = file?.clientInfo
  const parentHasUploadError = file?.status === FileStatus.UploadError
  const childrenHasUploadError = clientInfo?.childrenHasUploadError
  const emailError = clientInfo?.emailError
  const uploadError =
    parentHasUploadError === true || childrenHasUploadError === true
  // Checking for unmapped zip file  
  const isUnmappedZipFile =
    isUnmappedFlags &&
    file.externalFileName?.toLowerCase().endsWith('.zip')!
  const childFilesInactive = file?.clientInfo?.childFilesInactive
  let status: DisplayFileStatus

  if (isPublished) {
    if (childFilesInactive) {
      status = DisplayFileStatus.Inactive
    }
    else if (uploadError || emailError) {
      status = DisplayFileStatus.PublishedWithError
    }
    else {
      status = DisplayFileStatus.Published
    }
  } else {
    if (isUnmappedZipFile && !isUnmappedZipVerified) {
      status = DisplayFileStatus.NotReadyToPublish
    }
    else if (uploadError) {
      if (
        childrenHasUploadError &&
        clientInfo?.childCount! > 0 &&
        clientInfo?.errorCount! < clientInfo?.childCount!
      ) {
        status = DisplayFileStatus.ReadyToPublishWithError
      } else {
        status = DisplayFileStatus.NotReadyToPublish
      }
    } else if (childFilesInactive) {
      status = DisplayFileStatus.Inactive
    } else {
      status = DisplayFileStatus.ReadyToPublish
    }
  }
  return status
}

/** Format the date using moment.js and return the date in specified format */
export const formatDate = (dateValue: Date) => {
  if (dateValue) {
    var roundingDefault = moment.relativeTimeRounding();    
    var start = moment(dateValue)
    var end = moment(Date.now())
    setMomentRelativeTimeRounding()
    var formattedDate = end.from(start, true) + ' ago'
    reSetMomentRelativeTimeRounding(roundingDefault)
    return formattedDate
  }
  return ''
}

/** Set moment Relative time rounding to proper seconds(60), minutes (60), hours(24), days(30), months(12) values to avoid confustion for end user
 *  where as by default these are seconds(45), minutes (45), hours(22), days(26), months(11) */
export const setMomentRelativeTimeRounding = ()  => {
      moment.relativeTimeRounding(Math.floor);
      moment.relativeTimeThreshold('s', 60);
      moment.relativeTimeThreshold('m', 60);
      moment.relativeTimeThreshold('h', 24);
      moment.relativeTimeThreshold('d', 30);
      moment.relativeTimeThreshold('M', 12);
}

/** Re Set moment Relative time rounding to default values. As this is a global property, resetting back to default to not effect other methods of moment */
export const reSetMomentRelativeTimeRounding = (roundingFunction: (num: number) => number) => {
  moment.relativeTimeRounding(roundingFunction);
    moment.relativeTimeThreshold('s', 45);
    moment.relativeTimeThreshold('m', 45);
    moment.relativeTimeThreshold('h', 22);
    moment.relativeTimeThreshold('d', 26);
    moment.relativeTimeThreshold('M', 11);
}

/**Remove whitespaces and dashed in the text */
export function removeWhitespacesDashes(text: string) {
  if (text && (/[0-9]{2}-/.test(text) || /[0-9]{3}-/.test(text))) {
    text = text.replaceAll('-', '')
    text = text.replace(/\s/g, '')
    text = text.trim()
  }
  return text
}

/** Returns a SearchTermValidationTypes value indicating whether or not the search term (or filter string)
 *   is valid to be requested from the server.  */
export function validateFileSearchTerm(
  searchTerm?: string
): SearchTermValidationTypes {
  if (!searchTerm || /^\s+$/.test(searchTerm)) {
    return SearchTermValidationTypes.NoContent
  }

  // A value less than 3 characters, but a number, can be used to search
  //  on the Investor ID from the server.
  searchTerm = removeWhitespacesDashes(searchTerm)
  if (/^\d{1,2}$/.test(searchTerm)) {
    return SearchTermValidationTypes.Valid
  }

  // The value must be at least 3 characters long.
  return searchTerm.length > 2
    ? SearchTermValidationTypes.Valid
    : SearchTermValidationTypes.TooShort
}

/**
 * Match the partner name and entity name field value and return TRUE/FALSE
 * @param file
 * @returns true/false/''
 */
export const verifyMatchResult = (file: File, partnerName: string): boolean => {
  let resultValue = false
  let tagValue = getValueForFileTag(commonFileTags.PartnerName, [
    ...(file.tags ? file.tags : []),
  ])
  if (tagValue && partnerName) {
    tagValue = tagValue.replace(/ /g, '').toLocaleLowerCase()
    partnerName = partnerName.replace(/ /g, '').toLocaleLowerCase()
    if (tagValue === partnerName) {
      resultValue = true
    }
  }
  return resultValue
}

/***Remove element from an array if exists */
export const removeArrayElement = (elementList: number[], value: number) => {
  if (elementList && elementList.length > 0) {
    var index = elementList.indexOf(value)
    if (index > -1) {
      elementList.splice(index, 1)
    }
    return elementList
  }
  return elementList
}

/** Return the tax years from 2017 till current year + 1
 *  Also include the taxyear if not exists between 2017 to current year + 1
 */
export const getTaxYearValues = (taxYear?: string, isReverse?: boolean) => {
  const taxYears: string[] = []
  const currentYear = new Date().getFullYear() + 1 // End year
  let year: number = 2017 // Start year
  do {
    taxYears.push(year.toString())
    year = year + 1
  } while (year <= currentYear)
  //Check and add the already selected tax year if not exists
  if (taxYear && !taxYears.includes(taxYear)) {
    taxYears.splice(0, 0, taxYear)
  }
  if (isReverse) {
    return taxYears.reverse()
  }
  return taxYears
}

/* Returns a boolean value indicating whether or not a specified CommonFilterHeader array includes any items with its text property is set to a specified value */
export const checkFilterTextExists = (
  listItems: Array<CommonFilterHeader>,
  text: string
) => {
  //check and return the true if test not exist
  return !listItems.some((dg) => dg.text === text)
}

/** Returns header filter for dates.*/
export function getHeaderFilterDates(dateFilters: CommonFilterHeader[]) {
   // Format the date filters
  const formattedDateFilters = formatDateFilters(dateFilters);
  // Remove duplicates, and return the results.
  const dateGroups = new Array<CommonFilterHeader>()
  formattedDateFilters.forEach((d) => {
    if (!dateGroups.some((dg) => dg.text === d.text)) {
      dateGroups.push(d)
    }
  })
  return dateGroups.sort((a: CommonFilterHeader, b: CommonFilterHeader) =>
  b.text.localeCompare(a.text)
  )
}

// Function to format date filters to MM/DD/YYYY. 
// These dates list might not match with db dates as we are converting the date to local date.
export function formatDateFilters(dateFilters: CommonFilterHeader[]): CommonFilterHeader[] {
  return dateFilters.map((d) => {
    const date = new Date(d.text);
    
    if (isNaN(date.getTime())) {
      // Handle invalid date text, e.g., "never"
      return { ...d, text: d.text };
    }    
    const formattedDate = moment(date.toLocaleString().split('T')[0]).format(DateFormat.shortDateFormat)
    return { ...d, text: formattedDate, value: formattedDate };
  });
}

//get the partner tag value from partner tag
export function getPartnerIdFromFileTag(
  tagName: string,
  tags: FileTag[] | undefined
) {
  if (!Array.isArray(tags)) return ''
  const tag = tags.find((tag) => tag.name === tagName)
  return tag ? tag.value : ''
}

//formatting the date and returning 'never' when 'undefined' or provided format
export function getFormattedDateWithNeverOption(lastActivityDate: Date | undefined, format: DateFormat) {
  if (lastActivityDate) {
    const activityDate = new Date(lastActivityDate)
    return activityDate.getFullYear() <= 1
      ? 'never'
      : moment(activityDate.toLocaleString())
        .local()
        .format(format)
  } else {
    return 'never'
  }

}
//formatting the date and returning 'never' when 'undefined' or provided format
export function getFormattedDateWithOutTimeStampWithNeverOption(lastActivityDate: Date | undefined, format: DateFormat) {

  if (lastActivityDate) {
    const activityDate = new Date(lastActivityDate)   
    return activityDate.getFullYear() <= 1
      ? 'never'       
      : moment(activityDate.toLocaleString().split('T')[0]).format(format)
  } else {
    return 'never'
  }
}

/** Returns a boolean value indicating whether or not a specified file flag is set to true, on a specified file flag value. */
export function isFileFlagSet(fileFlagValue: FileFlags, flagProperty: FileFlagProperties): boolean {
  return (fileFlagValue & flagProperty) !== 0
}

/** Given a specified current file flag value, and file flag property to update on that value, returns the new value of the file flag to set on the file's flags property. */
export function setFileFlag(currentFileFlagValue: FileFlags, fileFlagProperty: FileFlagProperties, newValue: boolean): FileFlags {
  let newFlagStatus = 0
  if (newValue) {
    newFlagStatus = fileFlagProperty
  }

  const returnValue = currentFileFlagValue & (~fileFlagProperty) | newFlagStatus
  return returnValue;
}

/** Filter the selected Files and return them */
export const getPublishFiles = (selectedFiles: File[]) => {
  return selectedFiles.filter(
    (f) =>
      !f.published &&
      f.status !== FileStatus.UploadError &&
      (!f.clientInfo?.childrenHasUploadError! ||
        (f.clientInfo?.childCount! > 0 &&
          f.clientInfo?.errorCount! < f.clientInfo?.childCount!)) &&
      f.status !== FileStatus.Inactive &&
      !f.clientInfo?.childFilesInactive!
  )
}