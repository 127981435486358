import React, { useEffect, useMemo } from 'react'
import { permissionRequestClientById } from '../../access-manager/permission-request-creation'
import * as Permissions from '../../model/permissions'
import { useHasPermission } from '../../access-manager/use-permission'
import { PermissionInfo } from '../../model/permissions'

/** Hook used to return a consolidated set of permissions used in the entity manager portion
 *   of the application. */
export const useEntityManagerPermissions = (clientGroupId: number) => {
  // Get the function to check permissions.
  const hasPermissions = useHasPermission()

  return useMemo(() => {
    // Get all of the permissions needed for this page.  They should all basically
    //  be the same construction, with different permission names.
    const permissionMap = new Map<PermissionInfo, boolean>()

    ;[
      Permissions.ENTITY_ADD_ALLOCATING,
      Permissions.ENTITY_ADD_INVESTOR,
      Permissions.ENTITY_REPORT_BLANK,
      Permissions.ENTITY_REPORT_POPULATED,
      Permissions.ENTITY_REPORT_ACTIVITY,
      Permissions.ENTITY_UPLOAD_ALLOCATING,
      Permissions.ENTITY_UPLOAD_INVESTOR,
      Permissions.ENTITY_EDIT_ALLOCATING,
      Permissions.ENTITY_DELETE_ALLOCATING,
      Permissions.ENTITY_EDIT_INVESTOR,
      Permissions.ENTITY_DELETE_INVESTOR,
    ].forEach((p) => {
      permissionMap.set(
        p,
        hasPermissions(permissionRequestClientById(p, clientGroupId))
      )
    })

    const canAddAllocatingEntity = permissionMap.get(
      Permissions.ENTITY_ADD_ALLOCATING
    )!
    const canAddInvestorEntity = permissionMap.get(
      Permissions.ENTITY_ADD_INVESTOR
    )!

    const canEditAllocatingEntity = permissionMap.get(
      Permissions.ENTITY_EDIT_ALLOCATING
    )!
    const canEditInvestorEntity = permissionMap.get(
      Permissions.ENTITY_EDIT_INVESTOR
    )!

    const canDeleteAllocatingEntity = permissionMap.get(
      Permissions.ENTITY_DELETE_ALLOCATING
    )!
    const canDeleteInvestorEntity = permissionMap.get(
      Permissions.ENTITY_DELETE_INVESTOR
    )!

    const canDownloadBlankBulkSheet = permissionMap.get(
      Permissions.ENTITY_REPORT_BLANK
    )!
    const canDownloadPopulatedBulkSheet = permissionMap.get(
      Permissions.ENTITY_REPORT_POPULATED
    )!
    const canDownloadActivityReport = permissionMap.get(
      Permissions.ENTITY_REPORT_ACTIVITY
    )!

    const canUploadBulkAllocatingSheet = permissionMap.get(
      Permissions.ENTITY_UPLOAD_ALLOCATING
    )!
    const canUploadBulkInvestorSheet = permissionMap.get(
      Permissions.ENTITY_UPLOAD_INVESTOR
    )!

    // Boolean value indicating whether or not the user can upload any bulk entity spreadsheet.
    const canUploadAnyBulkSheet =
      canUploadBulkAllocatingSheet || canUploadBulkInvestorSheet

    // Boolean value indicating whether or not the user can add any entities at all.
    const canAddNewEntity = canAddAllocatingEntity || canAddInvestorEntity

    // Boolean value indicating whether or not the user can edit any entities at all.
    const canEditEntity = canEditAllocatingEntity || canEditInvestorEntity

    return {
      canAddNewEntity,
      canAddAllocatingEntity,
      canAddInvestorEntity,

      canEditEntity,
      canEditAllocatingEntity,
      canEditInvestorEntity,

      canDeleteAllocatingEntity,
      canDeleteInvestorEntity,

      canDownloadBlankBulkSheet,
      canDownloadPopulatedBulkSheet,
      canDownloadActivityReport,

      canUploadBulkAllocatingSheet,
      canUploadBulkInvestorSheet,
      canUploadAnyBulkSheet,
    }
  }, [clientGroupId, hasPermissions])
}
