import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  Form,
  Item,
  ButtonItem,
  RequiredRule,
  GroupItem,
  SimpleItem,
  PatternRule,
  Label,
} from 'devextreme-react/form'
import CloseIcon from '@mui/icons-material/Close'
import {
  EntityGroupResult,
  EntityGroupView,
  FeatureFlagsView,
} from '../../api-client/entity-manager-client-v3'
import { useEntityClientApi } from '../../hooks/use-entity-api'
import { SpinnerModal } from '../modal/spinnerModal'
import './addClient.scss'
import { FileUpload } from '../fileUpload/fileUpload'
import { Subject } from 'rxjs'
import { uploadLogo } from '../utility/upload-entity-group-logo'
import { Checkbox, CircularProgress } from '@mui/material'
import {
  userEntityGroupState,
  entityGroupPartnershipStateAtom,
} from '../../state/atom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import produce from 'immer'
import { EntityGroup } from '../../api-client/investor-portal-client'
import FormControlLabel from '@mui/material/FormControlLabel'
import { ToolTarget } from '../../common-components/toolTarget'
import { REACT_APP_FEATURES_STATEMANAGER } from '../../envVariables'
import { singleEmailPattern } from '../../utilities/email-validation'
import { useFileUploadState } from '../fileUpload/use-file-upload-state'
import { DISMOUNT_ABORT_REASON } from '../utility/abort-constants'
import { useClientEditPermissionFunctions } from './use-client-edit-permission-functions'

/** The modes that the AddClient component can be in.  Either edit or add. */
export type AddClientModes = 'edit' | 'add'

export interface AddClientProps {
  groupId?: number
  mode?: AddClientModes
  onCancel: () => void
  onClientAdded?: (client: EntityGroup) => void
  onClientUpdated?: (client: EntityGroup) => void
}
type EntityGroupViewClientView = Omit<EntityGroupView, 'clientId'> & {
  clientId?: string
}
export const AddClient = ({
  groupId,
  mode,
  onCancel,
  onClientAdded,
  onClientUpdated,
}: AddClientProps) => {
  const { getClient } = useEntityClientApi()
  const [clientFormInfo, setClientFormInfo] = useState({
    name: '',
    clientId: undefined,
    shortName: '',
    email: '',
    featureFlagsView: {
      enableEmails: false,
      viewAllDocuments: false,
      editAccess: false,
      stateElections: false,
      documentReceipt: false,
    },
  } as Partial<EntityGroupView>)
  const [clientFormInfoFormatted, setClientFormInfoFormatted] = useState({
    clientId: '',
  } as Partial<EntityGroupViewClientView>)
  // Add entity group function from the API hook
  const { addClient, updateClient } = useEntityClientApi()
  const formRef = useRef<Form>(null)
  const uploadPromiseRef = useRef<Promise<any>>()
  const groupIdRef = useRef<number | undefined>(0)
  const [saveDisabled, setSaveDisabled] = useState(true)
  const [showSpinner, setShowSpinner] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [clientPromise, setClientPromise] = useState<Promise<any>>()
  const [formValidate, setFormValidate] = useState<boolean>(false)
  const [selectedFile, setSelectedFile] = useState<File | undefined>()
  const uploadTriggerSubject = useMemo(() => new Subject<void>(), [])
  const uploadTrigger = useMemo(
    () => uploadTriggerSubject.asObservable(),
    [uploadTriggerSubject]
  )

  // Get the base methods for checking the user's abilities on this page.
  const {
    userCanEditClientDetails: userCanEditClientDetailsFunc,
    userCanEditClientIdentity: userCanEditClientIdentityFunc,
  } = useClientEditPermissionFunctions()

  // Check if the user can client identity for the page.  If we're in "add"
  //  mode, then regardless of their details, they have to be able to edit
  //  the identity properties, or you can't create a client.
  const userCanEditClientIdentity =
    mode === 'add' || userCanEditClientIdentityFunc()

  // Check if the user can edit the entity details.  Same rules apply as above.
  const userCanEditClientDetails =
    mode === 'add' ||
    userCanEditClientDetailsFunc({ entityOrClientId: groupId! })

  // Boolean value which assists to indicate that all editing is disabled.
  const isFormDisabled = !userCanEditClientDetails && !userCanEditClientIdentity

  const fileUploadStateKey = useFileUploadState()

  const setUserEntityGroups = useSetRecoilState(userEntityGroupState)

  const [entityGroupPartnerships, setEntityGroupPartnerships] = useRecoilState(
    entityGroupPartnershipStateAtom
  )

  // Below two lines will be removed after MVP 3.0 release
  const tooltipMessage: string = 'Functionality not currently available'
  const enableStateManager = REACT_APP_FEATURES_STATEMANAGER ?? false

  /** Checkbox Change event */
  interface ICheckBoxEvent {
    value?: any
  }

  /** Textbox Change event */
  interface ITextBoxEvent {
    value?: any
  }

  /** If we have onValueChanged event added to the devexterem form fields
   * validation message disappears, to fix that added a flag to fire the form validation
   * explicitly when there's any invalid form field  data
   */
  useEffect(() => {
    // Fire form validation if form is invalid
    if (formValidate) {
      formRef.current?.instance.validate()
    }
  }, [formValidate])

  useEffect(() => {
    const abortController = new AbortController()

    if (mode === 'edit' && groupId) {
      setIsLoading(true)
      getClient(groupId, abortController.signal).then((response) => {
        if (response.featureFlagsView === undefined) {
          response.featureFlagsView = {
            enableEmails: false,
            viewAllDocuments: false,
            editAccess: false,
            stateElections: false,
            documentReceipt: false,
          }
        }
        setClientFormInfo(response)
        const formattedClientId = getFormattedClientId(
          response?.clientId?.toString()
        )
        setClientFormInfoFormatted({ clientId: formattedClientId })
        setIsLoading(false)
      })
    }

    return () => {
      abortController.abort(DISMOUNT_ABORT_REASON)
    }
  }, [mode, groupId])

  /**update the client logo URI in groups state */
  const updatedClientLogoState = (clientLogoUri: string | undefined) => {
    /**Update the groups in the state. */
    setUserEntityGroups((userEntityGroups) => {
      return produce(userEntityGroups, (draft) => {
        const updateEntityGroup = draft.groups.find(
          (x) => x.id === groupIdRef.current
        )
        if (updateEntityGroup) {
          updateEntityGroup.logoUri = clientLogoUri
        }
      })
    })
  }

  /**update/create the client groups state */
  const updatedClientGroupsState = (entityGroup: EntityGroup) => {
    /**Update the groups in the state. */
    setUserEntityGroups((userEntityGroups) => {
      return produce(userEntityGroups, (draft) => {
        // update the current state of clientGroup
        const localClientGroup = draft.groups.find(
          (x) => x.id === entityGroup.id
        )
        if (localClientGroup) {
          localClientGroup.name = entityGroup.name
        }
      })
    })
  }

  // Update feature flag details in recoil state
  const updateFeatureFlag = (
    groupIdNumber: number,
    featureFlag: FeatureFlagsView
  ) => {
    // Get client based on the groupid
    const clientState = entityGroupPartnerships[groupIdNumber]
    if (clientState) {
      // Update the feature flag with the new values
      setEntityGroupPartnerships(
        produce((draft) => {
          draft[groupIdNumber].featureFlag = featureFlag
        })
      )
    }
  }

  /** update the data type of client info  */
  function clientInfoTypeConversion(
    src: EntityGroupViewClientView
  ): EntityGroupView {
    return { ...src, clientId: parseInt(src.clientId ?? '0') }
  }

  /** Validate form and Call api method to save client data */
  const handleSubmit = async (e?: React.FormEvent) => {
    e?.preventDefault()

    if (!formRef.current?.instance?.validate().isValid) {
      return
    }

    setShowSpinner(true)

    let apiAction: Promise<any>
    const clientInfo = clientInfoTypeConversion({
      ...clientFormInfo,
      clientId: clientFormInfoFormatted.clientId,
    })

    if (groupId) {
      apiAction = updateClient(clientInfo)
    } else {
      apiAction = addClient(clientInfo)
    }

    apiAction = apiAction.then(async (result: EntityGroupResult) => {
      groupIdRef.current = result.entityGroup?.id
      // groupId is undefined when we are creating a new Client
      // update the existing clientGroup
      if (groupId) {
        updatedClientGroupsState(result.entityGroup!)
      }

      // Update Feature Flag
      updateFeatureFlag(
        result.entityGroup?.id!,
        clientFormInfo.featureFlagsView!
      )

      if (selectedFile) {
        uploadTriggerSubject.next()
      }

      // Notify the caller of the added/edited client.
      if (mode === 'edit') {
        onClientUpdated?.(result.entityGroup!)
      } else if (mode === 'add') {
        onClientAdded?.(result.entityGroup!)
      } else {
        throw new Error(`Unexpected mode: ${mode}`)
      }

      return uploadPromiseRef.current
    })

    setClientPromise(apiAction)
  }

  /** Hide spinner and Close add client dialog only for success */
  const onClose = (flag: boolean) => {
    setShowSpinner(false)
    //Check for success or Error and close the dialog
    if (flag) {
      onCancel()
    }
  }

  function formRefresh() {
    const formValid = !formRef.current?.instance.validate().isValid
    //Set flag to call form validation explicitly (workaround to fire form validation)
    setFormValidate(formValid)
    setSaveDisabled(formValid)
  }

  /** Text box editor options */
  const textBoxEditorOptions = {
    onValueChanged: () => {
      formRefresh()
    },
  }

  /** Prefix zero's into client id and make it of 7 digit when clinet id is not of 7 digit */
  const getFormattedClientId = (clientId?: string) => {
    if (!clientId) {
      return ''
    }
    if (clientId?.length === 7) {
      return clientId
    } else {
      const zerosNeeded = 7 - clientId.length
      return (
        Array(zerosNeeded)
          .fill('0')
          .reduce((acc, curr) => acc + curr, '') + clientId
      )
    }
  }

  return (
    <div className='add-client-container'>
      <div className='add-client-width'>
        <div className='dx-popup-title'>
          <div className='modal-header'>
            <div className='modal-header-title'>
              <div className='modal-title-font'>
                <span className='material-icons-outlined modal-title-alignment'></span>
                {mode === 'edit'
                  ? `Edit Client > ${clientFormInfo.name}`
                  : `Add New Client`}
              </div>
            </div>
            <div className='modal-close-button-container'>
              <div
                onClick={() => onCancel()}
                className='modal-close-button'
                role='button'
              >
                <CloseIcon />
              </div>
            </div>
          </div>
        </div>
        {!isLoading ? (
          <>
            <div className='ModalConfirmation' data-testid='edit-parent-files'>
              <div className='form-container form-scroll-horizontal add-client-form'>
                <Form
                  colCount={4}
                  formData={clientFormInfo}
                  readOnly={false}
                  showColonAfterLabel={false}
                  showValidationSummary={false}
                  ref={formRef}
                >
                  <GroupItem colSpan={4}>
                    <Item
                      dataField='name'
                      data-testid='client-name'
                      editorType='dxTextBox'
                      label={{ text: 'Client Name' }}
                      disabled={!userCanEditClientIdentity}
                      editorOptions={textBoxEditorOptions}
                    >
                      <RequiredRule message='Client Name is required' />
                    </Item>
                    <Item
                      dataField='clientId'
                      data-testid='client-id'
                      editorType='dxTextBox'
                      label={{ text: 'Client IPM Number' }}
                      disabled={!userCanEditClientIdentity}
                      editorOptions={{
                        value: clientFormInfoFormatted.clientId,
                        onValueChanged: (e: ITextBoxEvent) => {
                          /** clientFormInfoFormatted.clientId has been introduced so that while displaying the  client id will be stirng and at time of sending to payload clientId will be send as number */
                          clientFormInfoFormatted.clientId = e.value.trim()
                          formRefresh()
                        },
                      }}
                    >
                      <RequiredRule message='Client IPM Number is required' />
                      <PatternRule
                        ignoreEmptyValue={true}
                        message='Enter Valid 7-digit Client Code'
                        pattern='^\s*\d{7}\s*$'
                      />
                    </Item>
                    <Item
                      dataField='email'
                      data-testid='client-email-contact'
                      editorType='dxTextBox'
                      label={{ text: 'Client Email Contact' }}
                      disabled={!userCanEditClientDetails}
                      editorOptions={textBoxEditorOptions}
                    >
                      <RequiredRule message='Client Email Contact is required' />
                      <PatternRule
                        ignoreEmptyValue={true}
                        message='Email is invalid'
                        pattern={singleEmailPattern}
                      />
                    </Item>
                    <Item
                      dataField='shortName'
                      data-testid='client-short-name'
                      editorType='dxTextBox'
                      label={{ text: 'Client Short Name' }}
                      disabled={!userCanEditClientDetails}
                      editorOptions={textBoxEditorOptions}
                    />
                  </GroupItem>
                  <GroupItem colSpan={4} visible={enableStateManager}>
                    <SimpleItem
                      editorType='dxCheckBox'
                      editorOptions={{
                        disabled: !userCanEditClientDetails,
                        text: 'Enable State Manager',
                        value:
                          clientFormInfo.featureFlagsView === undefined
                            ? 0
                            : clientFormInfo.featureFlagsView?.stateElections!,
                        onValueChanged: (e: ICheckBoxEvent) => {
                          if (clientFormInfo.featureFlagsView !== undefined) {
                            clientFormInfo.featureFlagsView.stateElections =
                              e.value
                            formRefresh()
                          }
                        },
                      }}
                    />
                  </GroupItem>
                  {/* Below 'GroupItem' have MUI check box control in order to achive the MVP3.0 feature Disable and Tootip
                   *  Once we enable the actual checkbox this 'GroupItem' component will be no longer needed
                   */}
                  <GroupItem colSpan={4}>
                    <ToolTarget toolTipContent={tooltipMessage}>
                      <FormControlLabel
                        disabled={true}
                        control={<Checkbox size='small' />}
                        label='Enable Emails'
                      />
                    </ToolTarget>
                    <ToolTarget toolTipContent={tooltipMessage}>
                      <FormControlLabel
                        disabled={true}
                        control={<Checkbox size='small' />}
                        label='Enable Client to View All Documents'
                      />
                    </ToolTarget>
                    <ToolTarget toolTipContent={tooltipMessage}>
                      <FormControlLabel
                        disabled={true}
                        control={<Checkbox size='small' />}
                        label='Enable Client to Have Edit Access'
                      />
                    </ToolTarget>
                    {!enableStateManager && (
                      <ToolTarget toolTipContent={tooltipMessage}>
                        <FormControlLabel
                          disabled={true}
                          control={<Checkbox size='small' />}
                          label='Enable State Manager'
                        />
                      </ToolTarget>
                    )}
                    <ToolTarget toolTipContent={tooltipMessage}>
                      <FormControlLabel
                        disabled={true}
                        control={<Checkbox size='small' />}
                        label='Enable Document Receipt'
                      />
                    </ToolTarget>
                  </GroupItem>
                  {/* setting the below visible=false, unable to enable the tooltip for the 'SimpleItem'
                   * once we delete the above 'GroupItem' then we have to set visible=true for below 'GroupItem'
                   */}
                  <GroupItem colSpan={4} visible={false}>
                    <SimpleItem
                      editorType='dxCheckBox'
                      data-testid='enable-emails'
                      editorOptions={{
                        text: 'Enable Emails',
                        value:
                          clientFormInfo.featureFlagsView === undefined
                            ? 0
                            : clientFormInfo.featureFlagsView.enableEmails!,
                        onValueChanged: (e: ICheckBoxEvent) => {
                          if (clientFormInfo.featureFlagsView !== undefined) {
                            clientFormInfo.featureFlagsView.enableEmails =
                              e.value
                            formRefresh()
                          }
                        },
                      }}
                    />
                    <SimpleItem
                      editorType='dxCheckBox'
                      editorOptions={{
                        text: 'Enable Client to View All Documents',
                        value:
                          clientFormInfo.featureFlagsView === undefined
                            ? 0
                            : clientFormInfo.featureFlagsView
                                ?.viewAllDocuments!,
                        onValueChanged: (e: ICheckBoxEvent) => {
                          if (clientFormInfo.featureFlagsView !== undefined) {
                            clientFormInfo.featureFlagsView.viewAllDocuments =
                              e.value
                            formRefresh()
                          }
                        },
                      }}
                    />
                    <SimpleItem
                      editorType='dxCheckBox'
                      editorOptions={{
                        text: 'Enable Client to Have Edit Access',
                        value:
                          clientFormInfo.featureFlagsView === undefined
                            ? 0
                            : clientFormInfo.featureFlagsView?.editAccess!,
                        onValueChanged: (e: ICheckBoxEvent) => {
                          if (clientFormInfo.featureFlagsView !== undefined) {
                            clientFormInfo.featureFlagsView.editAccess = e.value
                            formRefresh()
                          }
                        },
                      }}
                    />
                    <SimpleItem
                      editorType='dxCheckBox'
                      editorOptions={{
                        text: 'Enable State Manager',
                        value:
                          clientFormInfo.featureFlagsView === undefined
                            ? 0
                            : clientFormInfo.featureFlagsView?.stateElections!,
                        onValueChanged: (e: ICheckBoxEvent) => {
                          if (clientFormInfo.featureFlagsView !== undefined) {
                            clientFormInfo.featureFlagsView.stateElections =
                              e.value
                            formRefresh()
                          }
                        },
                      }}
                    />
                    <SimpleItem
                      editorType='dxCheckBox'
                      editorOptions={{
                        text: 'Enable Document Receipt',
                        value:
                          clientFormInfo.featureFlagsView === undefined
                            ? 0
                            : clientFormInfo.featureFlagsView?.documentReceipt!,
                        onValueChanged: (e: ICheckBoxEvent) => {
                          if (clientFormInfo.featureFlagsView !== undefined) {
                            clientFormInfo.featureFlagsView.documentReceipt =
                              e.value
                            formRefresh()
                          }
                        },
                      }}
                    />
                  </GroupItem>
                  <GroupItem colSpan={4}>
                    <div>Client Logo</div>
                    <FileUpload
                      stateKey={fileUploadStateKey}
                      disabled={!userCanEditClientDetails}
                      uploadFileFn={(progress: (x: number) => void) =>
                        (uploadPromiseRef.current = uploadLogo(
                          selectedFile!,
                          groupIdRef.current!,
                          progress
                        ).then((result) => {
                          updatedClientLogoState(result?.readonlySasUri)
                        }))
                      }
                      uploadFileTrigger={uploadTrigger}
                      setFileSelection={(file: File | undefined) => {
                        setSelectedFile(file)
                        formRefresh()
                      }}
                      fileAcceptance={{
                        acceptTypes: '.jpeg, .jpg, .png, .webp, .gif',
                        acceptanceMessage: '.jpeg, .jpg, .png, .webp or .gif',
                        maxFileSize: 300 * 1024, //Max logo size 300KB in bytes
                      }}
                    ></FileUpload>
                  </GroupItem>
                  <GroupItem colSpan={3}>
                    <ButtonItem
                      colSpan={1}
                      horizontalAlignment='right'
                      buttonOptions={{
                        text: 'Cancel',
                        type: 'normal',
                        useSubmitBehavior: false,
                        onClick: () => {
                          onCancel()
                        },
                      }}
                    />
                  </GroupItem>
                  <GroupItem colSpan={1}>
                    <ButtonItem
                      colSpan={1}
                      horizontalAlignment='right'
                      buttonOptions={{
                        text: 'Save',
                        type: 'default',
                        useSubmitBehavior: true,
                        disabled: saveDisabled || isFormDisabled,
                        onClick: function () {
                          handleSubmit()
                        },
                      }}
                    />
                  </GroupItem>
                </Form>
              </div>
            </div>
            <SpinnerModal
              visible={showSpinner}
              errorTitleMessage='Error'
              errorMessage=''
              inProgressTitleMessage={
                mode === 'edit' && groupId ? 'Edit Client' : 'Add Client'
              }
              inProgressMessage={
                mode === 'edit' && groupId
                  ? 'Updating Client......'
                  : 'Adding Client......'
              }
              successTitleMessage={
                mode === 'edit' && groupId ? 'Edit Client' : 'Add New Client'
              }
              successMessage={
                mode === 'edit' && groupId
                  ? 'Client updated successfully.'
                  : 'Client added successfully.'
              }
              onClose={(e?: boolean) => onClose(e!)}
              apiAction={clientPromise}
            />
          </>
        ) : (
          <div className='circular-spinner-outer-container'>
            <div className='circular-spinner-container'>
              <CircularProgress />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
