import { useMemo } from 'react'
import { useHasPermission } from '../../access-manager/use-permission'
import * as permissions from '../../model/permissions'
import {
  PermissionRequestLevels,
  PermissionId,
} from '../../access-manager/permission-models'

/** Hook that will provide functions used to indicate if and how the current user
 *   can edit clients, given their IPM number or ID. */
export const useClientEditPermissionFunctions = () => {
  // Get the permissions for the current user.
  const hasPermission = useHasPermission()

  const result = useMemo(() => {
    const userCanEditClientIdentity = () => {
      return hasPermission({
        level: PermissionRequestLevels.Any,
        permissionName: permissions.ADMIN_EDIT_CLIENTIDENTITY.name,
      })
    }

    const userCanEditClientDetails = (permissionId: PermissionId) => {
      // The user must have either admin level or client level permission for this.
      //  Check them both, and return.
      const hasAdminLevelPermission = hasPermission({
        level: PermissionRequestLevels.Any,
        permissionName: permissions.ADMIN_EDIT_CLIENTDETAILS.name,
      })

      const hasClientLevelPermission = hasPermission({
        ...permissionId,
        level: PermissionRequestLevels.Client,
        permissionName: permissions.CLIENT_EDIT_CLIENTDETAILS.name,
      })

      // The user should have one of these requests
      return hasAdminLevelPermission || hasClientLevelPermission
    }

    const userCanEditClient = (permissionId: PermissionId) => {
      // The user should have one of the requests from either of the previous
      //  two function calls.
      return (
        userCanEditClientDetails(permissionId) || userCanEditClientIdentity()
      )
    }

    return {
      /** Given a specified client IPM/ID, returns a boolean value indicating whether or not
       *   the user can enter the edit dialog to edit arbitrary details for the client.  */
      userCanEditClient,
      /** Given a specified client IPM/ID, returns a boolean value indicating whether or not
       *   the user can edit non-identity details information for the client.  */
      userCanEditClientDetails,
      /** Given a specified client IPM/ID, returns a boolean value indicating whether or not
       *   the user can edit identity information for the client.  */
      userCanEditClientIdentity,
    }
  }, [hasPermission])

  return result
}
