import { PermissionSet } from '../api-client/entity-manager-client-v3'

export enum PermissionRequestLevels {
    Entity = 'legal-entity',
    Client = 'client',
    Any = 'any',
}

// The following types are private for use in the construction of more complex types below.
interface PermissionRequestBase {
    level: PermissionRequestLevels
    permissionName: string
}
interface PermissionRequestAny {
    level: PermissionRequestLevels.Any
    permissionName: string
    entityOrClientId?: never
    ipmNumber?: never
}

interface PermissionSpecById {
    ipmNumber: number
    entityOrClientId?: never
}
interface PermissionSpecByIpm {
    ipmNumber?: never
    entityOrClientId: number
}

/** The ID component of a permission request.  This can be either an IPM number or entity ID, but not both. */
export type PermissionId = PermissionSpecById | PermissionSpecByIpm

/** Represents a specific permission granted, for a specific allocating entity or client. */
export interface IndividualPermission extends PermissionSet {
    permissionName: string
}

/** Data needed to request whether or no the current user has a specified permission. */
export type PermissionRequest = (PermissionId & PermissionRequestBase) | PermissionRequestAny

/** A set of PermissionRequest objects to check the access for. */
export type PermissionRequestSet = PermissionRequest[]

