import { Button, DataGrid } from 'devextreme-react'
import {
  SearchPanel,
  Scrolling,
  Sorting,
  Column,
  Toolbar,
  Item,
} from 'devextreme-react/data-grid'
import React, { useCallback, useEffect, useState } from 'react'
import { ICellData, dgheight } from '../../../model/file-model'
import { useEntityClientApi } from '../../../hooks/use-entity-api'
import { EntityGroup } from '../../../api-client/investor-portal-client'
import './client-group-list.scss'
import { getLandingPageRoute } from '../../utility/route-creation'
import { useNavigate } from '../../../hooks/useNavigationGuard'
import { Drawer, Grid } from '@mui/material'
import { useCommonDialogs } from '../../modal/commonDialog/common-dialog-operations'
import { DISMOUNT_ABORT_REASON } from '../../utility/abort-constants'
import { AddClient, AddClientModes } from '../../landingPage/addClient'
import produce from 'immer'
import { usePermissions } from '../../../access-manager/use-permission'
import { permissionRequestAny } from '../../../access-manager/permission-request-creation'
import {
  CLIENT_ADD_NEW,
  PAGE_VISIBILITY_CLIENTSADMIN,
} from '../../../model/permissions'
import { useAuthorizedPermission } from '../../../access-manager/use-authorized-permission'

export interface EditClientDialogProps {
  groupId?: number
  mode?: AddClientModes
  visible: boolean
}

/**Clients Group List Page */
export const ClientsGroupList = () => {
  const entityApi = useEntityClientApi()
  const navigate = useNavigate()
  const commonDialog = useCommonDialogs()
  const [clients, setClients] = useState<EntityGroup[]>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showEditClient, setShowEditClient] = useState<EditClientDialogProps>({
    groupId: 0,
    mode: 'edit',
    visible: false,
  })

  useAuthorizedPermission([permissionRequestAny(PAGE_VISIBILITY_CLIENTSADMIN)])

  const disableAddButton = !usePermissions([
    permissionRequestAny(CLIENT_ADD_NEW),
  ])

  /** Fetch client groups from the API */
  useEffect(() => {
    const abortController = new AbortController()

    const getClientGroups = async () => {
      setIsLoading(true)

      await entityApi
        .getAllClientGroups(abortController.signal)
        .then((response) => {
          setClients(response)
          setIsLoading(false)
        })
        .catch((err) => {
          commonDialog.showDialog({
            title: 'Loading Error',
            dialogType: 'error',
            content: `Error While Loading Clients: ${err}`,
          })
        })
    }

    getClientGroups()

    return () => {
      abortController.abort(DISMOUNT_ABORT_REASON)
    }
  }, [])

  /** Called when a client is added through the AddClient dialog, so we can update the table with the new client. */
  const onClientAdded = useCallback((clientGroup: EntityGroup) => {
    setClients(
      produce((draft) => {
        if (draft) {
          draft.push(clientGroup)
        } else {
          return [clientGroup]
        }
      })
    )
  }, [])

  /** Called when a client is edited through the AddClient dialog, so we can update the table with the changes. */
  const onClientUpdated = useCallback((clientGroup: EntityGroup) => {
    setClients(
      produce((draft) => {
        if (!draft) {
          return
        }
        const index = draft.findIndex((x) => x.id === clientGroup.id)
        if (index >= 0) {
          draft![index] = clientGroup
        }
      })
    )
  }, [])

  /**  */
  const clientGroupClickAction = (cellData: ICellData<EntityGroup>) => {
    return (
      <Button
        data-role='link-button'
        onClick={() => {
          setShowEditClient({
            groupId: cellData.data?.id,
            mode: 'edit',
            visible: true,
          })
        }}
        style={{ textTransform: 'none' }}
        stylingMode='text'
      >
        {cellData.data?.name}
      </Button>
    )
  }

  return (
    <div className='client-list-page'>
      <div className='client-button-container'>
        <Grid container item xs={5} minWidth={50} justifyContent={'flex-start'}>
          <Grid item xs={3} className='button-container'>
            <Button
              onClick={() => navigate(getLandingPageRoute())}
              stylingMode='outlined'
              data-testid='btnBack'
            >
              <span className='dx-icon-arrowleft'></span>
            </Button>
          </Grid>
          <Grid item xs={2} className='label-container'>
            <span>Clients</span>
          </Grid>
        </Grid>
      </div>
      <div className='client-groups-grid ip-table'>
        <DataGrid
          showBorders={true}
          dataSource={clients}
          keyExpr='id'
          noDataText={isLoading ? 'Loading...' : ''}
          columnAutoWidth={false}
          height={dgheight}
          allowColumnResizing={true}
          repaintChangesOnly={true}
        >
          <SearchPanel visible={true} width='200px' />
          <Scrolling mode='virtual' />
          <Sorting mode='single' />
          <Toolbar>
            <Item name='searchPanel' location='before'></Item>
            <Item location='after'>
              <Button
                text='Add New Client'
                data-testid='add-client-button'
                stylingMode='contained'
                disabled={isLoading || disableAddButton}
                type='default'
                onClick={() =>
                  setShowEditClient({
                    groupId: 0,
                    mode: 'add',
                    visible: true,
                  })
                }
              ></Button>
            </Item>
          </Toolbar>
          <Column
            dataField='name'
            caption='Client Name'
            cellRender={clientGroupClickAction}
            allowSorting={true}
            defaultSortOrder='asc'
            minWidth={'20%'}
          ></Column>
        </DataGrid>
      </div>
      <Drawer
        open={showEditClient.visible}
        anchor={'right'}
        onClose={() => setShowEditClient({ visible: false })}
      >
        <AddClient
          groupId={showEditClient.groupId}
          mode={showEditClient.mode}
          onCancel={() => setShowEditClient({ visible: false })}
          onClientAdded={onClientAdded}
          onClientUpdated={onClientUpdated}
        ></AddClient>
      </Drawer>
    </div>
  )
}
