import { Container, Button, Drawer } from '@mui/material'
import { useState, useMemo, useCallback, useEffect } from 'react'
import DataGrid, {
  SearchPanel,
  Column,
  GroupPanel,
  Toolbar,
  Item,
  Scrolling,
  Paging,
} from 'devextreme-react/data-grid'
import { useRecoilValue, useRecoilState } from 'recoil'
import { userEntityGroupState, userProfileFromIdmAtom } from '../../state/atom'
import './landingPage.scss'
import AddIcon from '@mui/icons-material/Add'
import { AddClient } from './addClient'
import { EntityGroup } from '../../api-client/investor-portal-client'
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined'
import StarIcon from '@mui/icons-material/Star'
import produce from 'immer'
import { useEntityClientApi } from '../../hooks/use-entity-api'
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined'
import { ContextMenu, Button as DEVEXTBTN } from 'devextreme-react'
import { MenuProps } from '../../client-models/clientmodels'
import { ICellData } from '../../model/file-model'
import { ToolTarget } from '../../common-components/toolTarget'
import { getClientGroupPageRoute } from '../utility/route-creation'
import { useNavigate } from '../../hooks/useNavigationGuard'
import { useFileClientApi } from '../../hooks/use-file-api'
import { PromiseLimiter } from '../../utilities/promise-limiter'
import { EditClient, listDataSourceType } from './landing-utility'
import { DISMOUNT_ABORT_REASON } from '../utility/abort-constants'
import { useClientEditPermissionFunctions } from './use-client-edit-permission-functions'
import { usePermissions } from '../../access-manager/use-permission'
import { PermissionRequestLevels } from '../../access-manager/permission-models'
import * as permissions from '../../model/permissions'

let defaultData: listDataSourceType

interface ClientGroup {
  id?: number
  name?: string | undefined
  isFavorite?: boolean
  entitiesCount: string
  investorsCount: string
  contactsCount: string
}

/** Set context menu text and css class */
const renderMenuItem = (e: MenuProps) => {
  let icon = <span className='material-icons-outlined'>{e.icon}</span>
  /**Below condition made to show the disable button with hover
   * Targeted to MVP3.0
   * once we have some action for the 'Manage Users' we will not have need of this 'if' condition anymore
   */
  if (e.text === 'Manage Users') {
    return (
      <>
        <ToolTarget toolTipContent='Functionality not currently available'>
          <div className='disabled-tile-menu-item'>
            <span className='material-icons-outlined'>{e.icon}</span>
            {e.items ? <span className='dx-icon-spinright' /> : null}
            {e.text}
          </div>
        </ToolTarget>
      </>
    )
  }
  return (
    <>
      {icon}
      {e.items ? <span className='dx-icon-spinright' /> : null}
      {e.text}
    </>
  )
}

/** Admin Landing Page List View */
export const AdminLandingPageListView = () => {
  const { groups, isLoading } = useRecoilValue(userEntityGroupState)
  const { userProfileResult } = useRecoilValue(userProfileFromIdmAtom)

  const [userEntityGroups, setUserEntityGroups] =
    useRecoilState(userEntityGroupState)
  const [sortOrder, setSortOrder] = useState('asc')
  const [clientGroups, setClientGroups] = useState<ClientGroup[]>([])
  const [investorDrawerActive, setInvestorDrawerActive] = useState({
    pos: false,
    data: defaultData,
    name: '',
  })

  const [showEditClient, setShowEditClient] = useState<EditClient>({
    groupId: 0,
    mode: 'edit',
    visible: false,
  })

  const canUserAddClients = usePermissions([
    {
      level: PermissionRequestLevels.Any,
      permissionName: permissions.CLIENT_ADD_NEW.name,
    },
  ])

  // Initialize
  const promiseLimiter = useMemo(() => {
    return new PromiseLimiter()
  }, [])
  const abortController = useMemo(() => {
    return new AbortController()
  }, [])
  const navigate = useNavigate()
  const entityClientApi = useEntityClientApi()
  const fileApi = useFileClientApi()
  const toggleInvestorDrawer =
    (open: boolean, list: listDataSourceType, investor: string) => () => {
      setInvestorDrawerActive({ pos: open, data: list, name: investor })
    }

  const { userCanEditClient } = useClientEditPermissionFunctions()

  /** Star icon click, if item is not in favorite list then make it as favorite else vice-versa */
  const favoriteClickHandler = async (entityGroup: EntityGroup) => {
    const favoriteclientInfo = {
      userId: userProfileResult.id,
      clientGroupId: entityGroup.id,
      isFavorite: entityGroup.isFavorite,
    }
    const newValue = await entityClientApi.addFavroiteClientGroup(
      favoriteclientInfo
    )
    // Set updated favorite flag in userEntityGroups recoil state
    setUserEntityGroups(
      produce(userEntityGroups, (draft) => {
        const updateEntityGroup = draft.groups.find(
          (x) => x.id === entityGroup.id
        )
        if (updateEntityGroup) {
          updateEntityGroup.isFavorite = newValue
        }
      })
    )

    // Set updated favorite flag in clientGroups state
    setClientGroups(
      produce((draft) => {
        const updateClientGroup = draft.find((x) => x.id === entityGroup.id)
        if (updateClientGroup) {
          updateClientGroup.isFavorite = newValue
        }
      })
    )
  }

  /** Set client groups data in state
   * Filtering all the fevorite and non favorite and sorting then explicitly and
   *  set client group data with default values for allocating entity, investor & contacts count
   */
  useEffect(() => {
    const favorite = groups.filter((e) => e.isFavorite)
    const nonFavorite = groups.filter((e) => !e.isFavorite)

    //concatenating and returning first favorite and then non-favorite to keep favorite groups on top
    const result: ClientGroup[] = favorite
      .concat(nonFavorite)
      .map((entityGroup: EntityGroup) => {
        const groupData = clientGroups?.find(
          (client) => client.id === entityGroup.id
        )
        return {
          id: entityGroup.id,
          name: entityGroup.name,
          isFavorite: entityGroup.isFavorite,
          contactsCount: !groupData ? '#' : groupData.contactsCount,
          entitiesCount: !groupData ? '#' : groupData.entitiesCount,
          investorsCount: !groupData ? '#' : groupData.investorsCount,
        }
      })

    // set client group data in state
    setClientGroups(result)

    // Call register action to make API and
    // set allocating entity, investor & contacts count
    getEntityStats(result)
  }, [groups, isLoading])

  /** Abort the API calls, if any promise is pending */
  useEffect(() => {
    return () => {
      abortController.abort(DISMOUNT_ABORT_REASON)
      promiseLimiter.abort()
    }
  }, [])

  /** Call API and set allocating entity, investor & contacts count  */
  const setEntityStats = (clientGroupId: number) => {
    return fileApi
      .getEntityPartnershipCount(clientGroupId, abortController.signal)
      .then((entityDetail) => {
        setClientGroups(
          produce((draft) => {
            const clientGroup = draft.find((c) => c.id === clientGroupId)
            if (clientGroup) {
              clientGroup.contactsCount =
                entityDetail!.contactsCount!.toString()
              clientGroup.entitiesCount =
                entityDetail!.entitiesCount!.toString()
              clientGroup.investorsCount =
                entityDetail!.investorsCount!.toString()
            }
          })
        )
      })
  }

  /** Call promiseLimiter registerAction to set the api promises that will
   * make api to get allocating entity, investor & contacts count */
  const getEntityStats = async (clients: ClientGroup[]) => {
    clients.forEach((clientGroup) => {
      if (clientGroup.entitiesCount === '#') {
        promiseLimiter.registerAction(() => setEntityStats(clientGroup.id!))
      }
    })
  }

  /** Filtering all the fevorite and non favorite and sorting then explicitly */
  const sortedClientGroups = useMemo(() => {
    const favorite = clientGroups.filter((e) => e.isFavorite)
    const nonFavorite = clientGroups.filter((e) => !e.isFavorite)
    if (sortOrder === 'desc') {
      favorite.sort((a: ClientGroup, b: ClientGroup) =>
        b.name!.localeCompare(a.name!)
      )
      nonFavorite.sort((a: ClientGroup, b: ClientGroup) =>
        b.name!.localeCompare(a.name!)
      )
    } else {
      favorite.sort((a: ClientGroup, b: ClientGroup) =>
        a.name!.localeCompare(b.name!)
      )
      nonFavorite.sort((a: ClientGroup, b: ClientGroup) =>
        a.name!.localeCompare(b.name!)
      )
    }
    //concatenating and returning first fvorite and then non-favorite to keep favorite groups on top
    const data = favorite.concat(nonFavorite)

    return data
  }, [clientGroups, sortOrder])

  /** Set favorite column icon based on the  isFavorite flag value */
  const setFavoriteColumn = (cellData: ICellData<EntityGroup>) => {
    const selectedRowData = cellData.data!
    return (
      <span>
        {!selectedRowData.isFavorite ? (
          <StarBorderOutlinedIcon
            className='favorite-icon'
            type='success'
            onClick={(e) => {
              favoriteClickHandler(selectedRowData)
            }}
          />
        ) : (
          <StarIcon
            className='favorite-icon'
            onClick={(e) => {
              favoriteClickHandler(selectedRowData)
            }}
          />
        )}
      </span>
    )
  }

  /** Bind  action column to show context menu with the grid column */
  const setActionColumn = (cellData: ICellData<ClientGroup>) => {
    const clientGroupId = cellData.data?.id!

    // Create the ID for the button, so we can attach our context menu to it.
    const buttonId = `btnMenuAction-${clientGroupId}`

    // Buttons for the context menu, with the appropriate actions.
    const contextMenu: MenuProps[] = [      
      {
        text: 'Edit Client',
        icon: 'edit',
        disabled: !userCanEditClient({ entityOrClientId: cellData.data!.id! }),
        action: () => {
          setShowEditClient({
            groupId: clientGroupId,
            mode: 'edit',
            visible: true,
          })
        },
      },
    ]

    // Return the cell content with a button and a menu attached to it
    return (
      <>
        <DEVEXTBTN
          id={buttonId}
          icon='more'
          data-testid='context-button'
          stylingMode='text'
        ></DEVEXTBTN>
        <ContextMenu
          dataSource={contextMenu}
          showEvent='click'
          target={`#${buttonId}`}
          itemRender={renderMenuItem}
          onItemClick={(e) => {
            const item = e.itemData as MenuProps | undefined
            if (item?.action) {
              item.action()
            }
          }}
        />
      </>
    )
  }

  /* Explicit sorting by IsFavorite and Name to keep favorite groups on top*/
  const onPositionSortingChanged = () => {
    sortOrder === 'desc' ? setSortOrder('asc') : setSortOrder('desc')
  }

  /** Render client link to navigate to documents page */
  const renderClientLink = useCallback(
    (cellData: ICellData<EntityGroup>) => {
      return (
        <DEVEXTBTN
          data-role='client-name-link-button'
          onClick={() => navigate(getClientGroupPageRoute(cellData.data?.id!))}
          className='link-button'
          stylingMode='text'
        >
          {cellData.value}
        </DEVEXTBTN>
      )
    },
    [sortedClientGroups]
  )

  return (
    <div className='landing-page-container'>
      <Container maxWidth={false} className='cui-c_section cui-h_mt'>
        <DataGrid
          id='admin-grid-landing-list-view'
          className='align-grid-left'
          keyExpr='id'
          dataSource={sortedClientGroups}
          showBorders={true}
          columnAutoWidth={false}
          repaintChangesOnly={true}
          showColumnLines={false}
          showRowLines={false}
          wordWrapEnabled={true}
          height='calc(100vh - 222px)'
          noDataText={
            isLoading
              ? 'Loading...'
              : !isLoading && groups.length === 0
              ? 'No Data Found.'
              : ''
          }
          data-testid='admin-grid-landing-list-view'
          width='90%'
        >
          <SearchPanel visible={true} width='200px' />
          <Paging defaultPageSize={12} />
          <Scrolling
            mode='virtual'
            rowRenderingMode='virtual'
            showScrollbar={true}
          />
          <Column
            allowSorting={false}
            caption='Favorite'
            allowFiltering={false}
            allowSearch={false}
            allowEditing={false}
            width='8%'
            alignment='center'
            cellRender={setFavoriteColumn}
          ></Column>
          <Column
            caption='Client Name'
            dataField='name'
            width='42%'
            allowSearch={true}
            allowSorting={false}
            allowFiltering={false}
            allowEditing={false}
            cellRender={renderClientLink}
          ></Column>
          <Column
            caption='# of Allocating Entities'
            allowSearch={false}
            allowFiltering={false}
            allowSorting={false}
            dataField='entitiesCount'
            width='14%'
            alignment='center'
          ></Column>
          <Column
            caption='# of Investors'
            allowSearch={false}
            allowFiltering={false}
            allowSorting={false}
            dataField='investorsCount'
            width='14%'
            alignment='center'
          ></Column>
          <Column
            caption='# of Contacts'
            allowSearch={false}
            allowFiltering={false}
            allowSorting={false}
            dataField='contactsCount'
            width='14%'
            alignment='center'
          ></Column>
          <Column
            caption='Action'
            allowSearch={false}
            allowFiltering={false}
            allowSorting={false}
            cellRender={setActionColumn}
            width='8%'
            alignment='center'
          ></Column>
          <GroupPanel visible={true} />
          <Toolbar>
            <Item location='before'>
              <Button
                onClick={onPositionSortingChanged}
                variant='outlined'
                size='small'
                className='button-align'
              >
                Client
                <span className='material-icons-outlined button-font'>
                  {sortOrder === 'desc' ? 'south' : 'north'}
                </span>
              </Button>
            </Item>
            <Item name='searchPanel' />
          </Toolbar>
        </DataGrid>
      </Container>
      <Drawer
        open={showEditClient.visible}
        anchor={'right'}
        onClose={() => setShowEditClient({ visible: false })}
      >
      <AddClient
        groupId={showEditClient.groupId}
        mode={showEditClient.mode}
        onCancel={() => setShowEditClient({ visible: false })}
      ></AddClient>
      </Drawer>
      <Drawer
        anchor={'right'}
        open={investorDrawerActive['pos']}
        onClose={toggleInvestorDrawer(false, defaultData, '')}
        className='cui-c_drawer'
      >
        <div className='cui-c_drawer-container'>
          <div className='cui-c_drawer-header'>
            <h4>{investorDrawerActive['name']}</h4>
            <Button
              size='small'
              onClick={toggleInvestorDrawer(false, defaultData, '')}
            >
            <span className='material-icons-outlined'>close</span>
            </Button>
          </div>
        </div>
      </Drawer>
    </div>
  )
}
