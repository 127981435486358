import { useMemo } from 'react'
import { useHasPermission } from '../../access-manager/use-permission'
import * as permissions from '../../model/permissions'
import { PermissionRequestLevels } from '../../access-manager/permission-models'

/** Hook that will provide functions used to indicate if and how the current user
 *   can have access o document status and investor status page actions items , given their IPM number or ID. */
export const useClientGroupPermissionFunctions = () => {
  // Get the permissions for the current user.
  const hasPermission = useHasPermission()

  const result = useMemo(() => {
    const userCanAccessAllocatingEntity = (partnershipId: number) => {
      return hasPermission({
        level: PermissionRequestLevels.Entity,
        permissionName: permissions.PAGE_VISIBILITY_COMPOSITEDOCS.name,
        entityOrClientId: partnershipId,
      })
    }

    const userCanEditClientTemplate = (clientId: number) => {
      return hasPermission({
        level: PermissionRequestLevels.Client,
        permissionName: permissions.CLIENTSTATUS_EDIT_CLIENTEMAILTEMPLATE.name,
        entityOrClientId: clientId,
      })
    }

    const userCanAccessInvestorReport = (clientId: number) => {
      return hasPermission({
        level: PermissionRequestLevels.Client,
        permissionName: permissions.ENTITY_REPORT_ACTIVITY.name,
        entityOrClientId: clientId,
      })
    }

    return {
      /** Given a specified partnership ID, returns a boolean value indicating whether or not
       *   the user can Access the AllocatingEntity Documents Page  */
      userCanAccessAllocatingEntity,
      /** Given a specified client ID, returns a boolean value indicating whether or not
       *   the user can edit non-identity details information for the client.  */
      userCanEditClientTemplate,
      /** Given a specified client ID, returns a boolean value indicating whether or not
       *   the user can edit identity information for the client.  */
      userCanAccessInvestorReport,
    }
  }, [hasPermission])

  return result
}
