import { useContext, useMemo } from 'react'
import {
  PermissionSet,
  PermissionTypes,
} from '../api-client/entity-manager-client-v3'
import { AccessManagerContext } from './access-provider'
import {
  PermissionRequestSet,
  PermissionRequest,
  PermissionRequestLevels,
  IndividualPermission,
} from './permission-models'

/** Given an array of objects, flattens all values of all objects within the array into a single array. */
function decomposePermissionSet(permissionRequestSet: PermissionRequestSet) {
  // Return value.
  const result = new Array<any>()

  // For every object in the array, flatten the values into an array, and add its elements to the result.
  permissionRequestSet.forEach((x) => {
    var values = Object.values(x)
    result.push(...values)
  })

  // Return the result.
  return result
}

/** When checking a set of permissions in the `usePermissions` hook, this specifies whether to return if either
 *   ALL or ANY permission checks are a match. */
export type PermissionCheckTypes = 'all' | 'any'

/** Ensures that a specified permission request meets the necessary requirements, and throws if it does not. */
export const validatePermissionRequest = (request: PermissionRequest) => {
  if (!request) {
    throw new Error(`A permission request was expected but got none.`)
  }

  // If the request type is Any, then we shouldn't have an IPM/Id number.
  if (request.level === PermissionRequestLevels.Any) {
    if (!!request.ipmNumber || !!request.entityOrClientId) {
      throw new Error(
        `Permission requests of level Any should not have the entityOrClientId or ipmNumber properties set.`
      )
    }
  }

  // Ensure we don't have BOTH a clientId and an ipmNumber.
  if (!!request.ipmNumber && !!request.entityOrClientId) {
    throw new Error(
      `Permission set for ${
        (request as any).permissionName
      } may not specify both entityOrClientId and ipmNumber.`
    )
  }

  // Ensure at least one id is set if the request type isn't any.
  if (request.level !== PermissionRequestLevels.Any) {
    if (!request.ipmNumber && !request.entityOrClientId) {
      throw new Error(
        `Either the ipmNumber or the entityOrClientId property is missing for this permission request (${request.permissionName}).`
      )
    }
  }

  // Do nothing, we're good!
}

/** Returns a boolean value indicating whether or not a specified permission request level is a match to the permission type on a permisison set. */
const isPermissionLevelMatch = (
  requestLevel: PermissionRequestLevels,
  permissionType: PermissionTypes
) => {
  if (requestLevel === PermissionRequestLevels.Any) {
    return true
  }

  if (
    requestLevel === PermissionRequestLevels.Client &&
    permissionType === PermissionTypes.Client
  ) {
    return true
  }

  if (
    requestLevel === PermissionRequestLevels.Entity &&
    permissionType === PermissionTypes.Entity
  ) {
    return true
  }

  return false
}

/** Function that will check the access of a single PermissionRequest object.
 *   The permissionSet parameter expects the value obtained from the permissionData for
 *   a specific permission name.  If the set is not provided, then that permission doesn't
 *   exist, and any request to this method should return false.
 */
const hasAuthorization = (
  request: PermissionRequest,
  permissionSet: PermissionSet[] | undefined
) => {
  // Ensure the request is valid.
  validatePermissionRequest(request)

  // If we don't have a permission set, then we don't have access.
  if (!permissionSet) {
    return false
  }

  // If the request type is Any, then all we need is to have a permission set.
  if (request.level === PermissionRequestLevels.Any) {
    return true
  }

  // Translate the permission type to the appropriate level.
  let requestLevel: PermissionTypes
  switch (request.level) {
    case PermissionRequestLevels.Client:
      requestLevel = PermissionTypes.Client
      break
    case PermissionRequestLevels.Entity:
      requestLevel = PermissionTypes.Entity
      break
    default:
      throw new Error(`Invalid permission level: ${request.level}`)
  }

  if (!!request.entityOrClientId) {
    // Check the case where we have the client ID or entity ID.
    return permissionSet.some(
      (x) => x.entityId === request.entityOrClientId && x.level === requestLevel
    )
  } else if (!!request.ipmNumber) {
    // Check the case where we have the IPM number.
    return permissionSet.some(
      (x) => x.ipmCode === request.ipmNumber && x.level === requestLevel
    )
  } else {
    // If we don't have either of the above values, then this request is invalid.
    throw new Error(
      `At least one of entityOrClientId or ipmNumber must be specified.`
    )
  }
}

/** Accepts a set of PermissionRequest objects, and returns a boolean value indicating whether or not the user has access to some/all of the requested resources.  */
export const usePermissions = (
  permissionRequestSet: PermissionRequestSet,
  checkType: PermissionCheckTypes = 'all'
) => {
  // Get the context with the permission data.
  const permissionData = useContext(AccessManagerContext)

  // Get the values of all of the requests as a flat array to be used in the useMemo dependency array.
  const decomposedVariables = decomposePermissionSet(permissionRequestSet)

  return useMemo<boolean>(() => {
    // If we have no permission data yet, then we can't check anything.
    if (!permissionData) {
      return false
    }

    // Depending on the type of the request, return the results of the check.
    if (checkType === 'all') {
      return permissionRequestSet.every((x) =>
        hasAuthorization(x, permissionData[x.permissionName])
      )
    } else if (checkType === 'any') {
      return permissionRequestSet.some((x) =>
        hasAuthorization(x, permissionData[x.permissionName])
      )
    } else {
      throw new Error(`Invalid check type: ${checkType}`)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionData, checkType, ...decomposedVariables])
}

/** Returns method for checking permission existences for entities. */
export const useHasPermission = () => {
  // Get the permission data from the context.
  const permissionData = useContext(AccessManagerContext)

  // Cache the results.
  const result = useMemo(() => {
    // Return value.
    const buildResults = new Array<IndividualPermission>()

    // For every permission request, get the permission data and add it to the result.
    for (let n in permissionData) {
      const permissionSet = permissionData[n]
      if (!permissionSet) {
        continue
      }

      // Add the permissions for this set, if there are
      //  any children.
      if (permissionSet.length > 0) {
        buildResults.push(
          ...permissionSet.map((y) => ({
            ...y,
            permissionName: n,
          }))
        )
      } else {
        // There are no clients/entities, meaning this permission is probably
        //  an application level role.  We need to include a blank set.
        buildResults.push({
          permissionName: n,
          level: PermissionTypes.Application,
        })
      }
    }

    /** Returns a boolean value indicating whether or not a permission exists
     *   for a specified specified entity/client, permission level, and name. */
    const hasPermission = (request: PermissionRequest) => {
      if (!!request.entityOrClientId && !!request.ipmNumber) {
        throw new Error(
          `entityOrClientId and ipmNumber cannot BOTH be specified for a permission request.`
        )
      }
      return buildResults.some(
        (x) =>
          x.permissionName === request.permissionName &&
          isPermissionLevelMatch(request.level, x.level!) &&
          (request.level === PermissionRequestLevels.Any ||
            (!!request.entityOrClientId &&
              request.entityOrClientId === x.entityId) ||
            (!!request.ipmNumber && request.ipmNumber === x.ipmCode))
      )
    }

    return hasPermission
  }, [permissionData])

  // Return the result.
  return result
}

/** Hook used to determine whether or not permission have been loaded from the server. */
export const useIsPermissionsLoaded = () => {
  // Get the context with the permission data.
  const permissionData = useContext(AccessManagerContext)

  // If we have data, then they've been loaded.  Otherwise, they haven't.
  return !!permissionData
}

/** This export is only used for testing the internal `hasAuthorization` method, and should not
 *   be used in production code. */
export const hasAuthorization_TEST = hasAuthorization

/** This export is only used for testing the internal `decomposePermissionSet` method, and should not
 *   be used in production code. */
export const decomposePermissionSet_TEST = decomposePermissionSet

/** This export is only used for testing the internal `isPermissionLevelMatch` method, and should not
 *   be used in production code. */
export const isPermissionLevelMatch_TEST = isPermissionLevelMatch
