import { ButtonFunction } from '../modal/modalBase'
import { File } from '../../api-client/investor-portal-client'
import { ScrollableMessage } from './scrollableMessage'
import { FormattedDialog } from '../modal/formattedDialog'
import { useEffect, useState } from 'react'
import { getPublishFiles } from './fileUtilities'
import { useFileClientApi } from '../../hooks/use-file-api'
import { CircularProgress } from '@mui/material'
import './publishWarning.scss'

export interface PublishWarningProps {
  onConfirm: () => void
  onCancel: () => void
  selectedFiles: File[]
  isVisible: boolean
}

export const PublishWarning = ({
  onConfirm,
  onCancel,
  selectedFiles,
  isVisible,
}: PublishWarningProps) => {
  const { getDuplicateUnmappedFiles } = useFileClientApi()
  const [duplicateFiles, setDuplicateFiles] = useState<File[]>([])
  const [isLoading, setIsloading] = useState(false)

  useEffect(() => {
    if (isVisible) {
      getDuplicateUnmappedZipFiles()
    }
  }, [isVisible, selectedFiles])

  // Get the unmapped duplicate files
  const getDuplicateUnmappedZipFiles = async () => {
    setIsloading(true)
    const filesNotPublished = getPublishFiles(selectedFiles)
    const selectedFileIds = filesNotPublished.map(x => x.id!)
    const allDuplicateFiles = await getDuplicateUnmappedFiles(selectedFileIds)
    setDuplicateFiles(allDuplicateFiles)
    setIsloading(false)
  }
  /** Create button for the dailogue box */
  const buttonFunctions: ButtonFunction[] = [
    {
      label: 'Cancel',
      onClick: () => onCancel(),
      isDefaultAction: true,
      buttonProps: {
        stylingMode: 'contained',
        type: 'normal',
        width: 120,
        'data-testid': 'cancel-button',
      },
    },
    {
      label: 'Confirm',
      onClick: () => onConfirm(),
      isDefaultAction: false,
      buttonProps: {
        stylingMode: 'contained',
        type: 'default',
        width: 120,
        'data-testid': 'confirm-button',
      },
    },
  ]

  return (
    <div>
      <FormattedDialog
        dialogType='general'
        visible={isVisible}
        title={'Inactivation and File Linking'}
        buttonFunctions={buttonFunctions}
        maxWidth={500}
        maxHeight={400}
        disableScrollbar={true}
      >
        <div className='publish-warning-model'>
          <ScrollableMessage
            staticContent={
              <>
                <p>
                  Note that unmapped .zip files are linked to investors outside
                  of the normal SSN/EIN processing logic used for .pdf files. As
                  such there is inherent risk in using the .zip process. Upon
                  publishing, previous unmapped zip files matching the tax year,
                  document type, and linked investor will be inactivated.
                </p>
                <p>
                  Please confirm that you have reviewed and verified all linking
                  to investors in the .zip file you are publishing, and that you
                  understand the inactivation process.
                </p>
              </>
            }
            scrollableContent={
              <div>
                {isLoading && (
                  <div className='progress-icon'>
                    <CircularProgress
                      color='info'
                      size='20px'
                      style={{ margin: '2px' }}
                    />
                  </div>
                )}
                {!isLoading && (
                  <div className='files-list'>
                    {duplicateFiles.length > 0 &&
                      duplicateFiles.map((f) => (
                        <div data-testid='file-item' key={f.id!.toString()}>
                          {f.externalFileName}
                        </div>
                      ))}
                  </div>
                )}
              </div>
            }
          ></ScrollableMessage>
        </div>
      </FormattedDialog>
    </div>
  )
}
