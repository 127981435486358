import { filesClientApi } from '../api-client/investor-portal-client-runtime'
import {
  ProvisionRequestDTO,
  ProvisionUserByEmailRequest,
  UserActivityMessage,
  VerifyUserRequest,
} from '../api-client/investor-portal-client'
import { User } from 'oidc-client-ts'
import { wrapApiWithCommonErrorHandlers } from './api-error-wrapper'

/** User API Wrapper */
export const useUserApi = () => {

  /** Returns a UserActivityMessage from a user object. */
  function getUserActivityMessage(user: User): UserActivityMessage {
    // Split out the parts of the user object.
    const { access_token, profile } = user
    // Decode the token.
    const parsedAccessToken = getTokenInfo(access_token)

    // Return the formatted result.
    return {
      email: parsedAccessToken.sub,
      firstName: profile.given_name,
      lastName: profile.family_name,
    } as UserActivityMessage
  }

  function getTokenInfo(token: string): any {
    const splitToken = token.split('.')
    let payload = splitToken[1].replace('-', '+').replace('_', '/')
    payload = payload.padEnd(
      payload.length + ((4 - (payload.length % 4)) % 4),
      '='
    )
    return JSON.parse(window.atob(payload))
  }

  /** Add/Update user info */
  const addUpdateUserProfile = async (user: User, abortSignal?: AbortSignal) => {
    return filesClientApi.user_Login(getUserActivityMessage(user), abortSignal)
  }

  /** Verify user by email address */
  const verifyUserByEmail = async (email: string) => {
    const verifyUserRequest: VerifyUserRequest = {
      emailAddress: email,
    }
    return filesClientApi.user_VerifyUserByEmail(verifyUserRequest)
  }

  /** Provision User By Email address */
  const provisionUserByEmail = async (
    email: string,
    uniqueOperationId: string,
    entityId: number,
    abortSignal?: AbortSignal
  ) => {
    const provisionUserRequest: ProvisionUserByEmailRequest = {
      emailAddress: email,
      uniqueRequestToken: uniqueOperationId,
      entityId: entityId,
    }
    return filesClientApi.provisionUsers_ProvisionUserByEmail(
      provisionUserRequest,
      abortSignal
    )
  }

    /** Verify user by email address */
    const existingInvestorSource = async (email: string) => {
      return filesClientApi.user_ExistingInvestorSource(email)
    }

    /** Provision selected user */
    const provisionSelectedUser = async (provisionInvestors: ProvisionRequestDTO[], abortSignal?: AbortSignal) => {
      const uniqueOperationId = Math.floor(Math.random() * 100000).toString()
      return filesClientApi.provisionUsers_ProvisionExistingInvestor(uniqueOperationId,provisionInvestors, abortSignal)
    }

  return wrapApiWithCommonErrorHandlers({
    addUpdateUserProfile,
    verifyUserByEmail,
    provisionUserByEmail,
    existingInvestorSource,
    provisionSelectedUser
  })
}
