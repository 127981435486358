import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import './childFileTable.scss'
import {
  DataGrid,
  Column,
  Selection,
  Item,
  Toolbar,
  LoadPanel,
  Scrolling,
  Paging,
  Sorting,
  SearchPanel,
  HeaderFilter,
} from 'devextreme-react/data-grid'
import { Grid, Icon } from '@mui/material'
import { ContextMenu, Button, Popover, Tooltip } from 'devextreme-react'
import { Link, useParams } from 'react-router-dom'
import { useRecoilValue, useRecoilState } from 'recoil'
import { File, LinkageType } from '../../api-client/investor-portal-client'
import {
  getInvestorName,
  getInvestorId,
  removeWhitespacesDashes,
  verifyMatchResult,
  formatDate,
  getHeaderFilterDates,
  getValueForFileTag,
  getFormattedDateWithNeverOption,
  getFormattedDateWithOutTimeStampWithNeverOption,
  getMacthingCriteria,
  getFileDisplayStatus,
  isFileFlagSet,
} from './fileUtilities'
import { MenuProps, ICellData, dgheight } from '../../model/file-model'
import '../../sass/components/grid.scss'
import { useFileClientApi } from '../../hooks/use-file-api'
import {
  childFileListAtom,
  parentFileListAtom,
  searchTextAtom,
} from '../../state/atom'
import { CounterDisplay } from '../reuasble-components/fileCountDisplay'
import { EventInfo } from 'devextreme/events'
import dxDataGrid, { SelectionChangedInfo } from 'devextreme/ui/data_grid'
import {
  addToolTipOnCellPrepared,
  MISMATCH_FILTER_TEXT,
} from '../utility/column-utility'
import InfoIcon from '@mui/icons-material/Info'
import { DeleteChildFileConfirmation } from './deleteChildFileConfirmation'
import { getParentFileListRoute } from '../utility/route-creation'
import { FileStatus } from '../../api-client/investor-portal-client'
import { DateFormat } from '../../model/date-formats'
import { SendEmailConfirmation } from './sendEmailConfirmation'
import { RelinkChildFileConfirmation } from './relinkChildFileConfirmation'
import { HeaderFilterDataSourceOptions } from '../../../types/types'
import {
  commonFileTags,
  CommonFilterHeader,
  DisplayFileStatus,
  QuickEditEntryModel,
} from '../../client-models/clientmodels'
import { QuickEditEntry } from './quickEditEntry'
import { useCommonDialogs } from '../modal/commonDialog/common-dialog-operations'
import { CopyClipboard } from '../../common-components/copyClipboard'
import { ResendEmailConfirmation } from './resendEmailConfirmation'
import { ClearFiltersButton } from '../../common-components/clear-filter/clear-filters-button'
import { useNavigate } from '../../hooks/useNavigationGuard'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { PDFPreview } from './file-preview'
import { LinkInvestorDialog } from './link-investor-dialog'
import { PublishFiles } from './publishFiles'
import { DISMOUNT_ABORT_REASON } from '../utility/abort-constants'
import { FileFlagProperties } from '../../api-client/file-flags-override'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined'
import { useAuthorizedPermission } from '../../access-manager/use-authorized-permission'
import * as Permissions from '../../model/permissions'
import {
  permissionRequestClientById,
  permissionRequestEntityById,
} from '../../access-manager/permission-request-creation'
import { useHasPermission } from '../../access-manager/use-permission'
import { PermissionInfo } from '../../model/permissions'

/** Set context menu text and css class */
export function renderMenuItem(e: MenuProps) {
  return (
    <>
      <span className='material-icons-outlined'>{e.icon}</span>
      {e.items ? <span className='dx-icon-spinright' /> : null}
      {e.text}
    </>
  )
}

export const ChildFilesTable = () => {
  const { parentFileId, partnershipId, groupId } = useParams() as {
    parentFileId: string
    partnershipId: string
    groupId: string
  }
  const ref = useRef<DataGrid>(null)
  const { files, isLoading } = useRecoilValue(childFileListAtom)
  const [filterString, setFilterString] = useRecoilState(searchTextAtom)
  const defaultSearchText = filterString ?? ''
  const parentFileIdValue = parseInt(parentFileId)
  const groupIdValue = parseInt(groupId)
  const partnershipIdValue = parseInt(partnershipId)
  const navigate = useNavigate()
  const clientApi = useFileClientApi()
  const [documentCount, setDocumentCount] = useState<number>(0)
  const [popOverTarget, setPopOverTarget] = useState<any>()
  const [popOverVisible, setPopOverVisible] = useState<boolean>(false)
  const [popOverText, setPopOverText] = useState<string>()
  const [tooltipTarget, setTooltipTarget] = useState<any>()
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false)
  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showMailModal, setShowMailModal] = useState(false)
  const [showReLinkModal, setShowReLinkModal] = useState(false)
  const [showQEEModal, setShowQEEModal] = useState(false)
  const [quickEditData, setQEEData] = useState<QuickEditEntryModel>()
  const [showLinkInvestorDialog, setShowLinkInvestorDialog] =
    useState<boolean>(false)
  const [fileInfo, setFileInfo] = useState<File>()
  const [showResendEmail, setShowResendEmail] = useState<boolean>(false)
  const [previewerVisible, setPreviewerVisible] = useState<boolean>(false)
  const [previewFile, setPreviewFile] = useState<{
    fileName: string
    fileId: number
  }>()
  const commonDialog = useCommonDialogs()
  const emailInProgressText = 'Investor Email In Progress'
  const errorText = 'Error - '
  const { files: parentFileList } = useRecoilValue(parentFileListAtom)
  const parentFile = parentFileList.find((x) => x.id === parentFileIdValue)

  const [publishDialogVisible, setPublishDialogVisible] = useState(false)
  const [isPublishSucceeded, setPublishSucceeded] = useState(false)

  /** Create Request to check whether the Page it authorized when retrieved
   Redirect to the Landing Page if there is no permission */
  useAuthorizedPermission([
    permissionRequestEntityById(
      Permissions.PAGE_VISIBILITY_DOCS,
      partnershipIdValue
    ),
  ])

  // Get the function to check permissions.
  const hasPermissions = useHasPermission()

  // Get all of the permissions needed for this page.  They should all basically
  //  be the same construction, with different permission names.
  const permissionMap = new Map<PermissionInfo, boolean>()

  // Start with just the entity-level permissions.
  ;[
    Permissions.INVESTORDOCS_SEND_EMAIL,
    Permissions.COMPOSITEDOCS_PUBLISH,
    Permissions.INVESTORDOCS_RESEND_EMAIL,
    Permissions.COMPOSITEDOCS_RELINK,
    Permissions.INVESTORDOCS_DOWNLOAD,
    Permissions.INVESTORDOCS_DELETE,
    Permissions.INVESTORDOCS_EDIT_LINK,
  ].forEach((p) => {
    permissionMap.set(
      p,
      hasPermissions(permissionRequestEntityById(p, partnershipIdValue))
    )
  })

  // Get the items that are client-level.
  ;[Permissions.ENTITY_EDIT_INVESTOR].forEach((p) => {
    permissionMap.set(
      p,
      hasPermissions(permissionRequestClientById(p, groupIdValue))
    )
  })

  /** Close the publish moadal and reset the selected files */
  const publishModalClose = () => {
    setPublishDialogVisible(false)
  }

  /** Close the publish modal,reset the selected files and grid selection */
  const publishModalConfirm = () => {
    setPublishDialogVisible(false)
  }

  useEffect(() => {
    const abortController = new AbortController()

    /** If there's any change in the Parent FileId, load the parent file data from the API */
    clientApi.getChildFiles(
      parentFileIdValue,
      groupIdValue,
      abortController.signal
    )

    return () => {
      abortController.abort(DISMOUNT_ABORT_REASON)
    }
  }, [groupIdValue, parentFileIdValue])

  // set isUnmappedZipFile to true if any of the files is unmappedZipVerified
  const isUnmappedZipFile: boolean = files.some((s) =>
    isFileFlagSet(s.flags!, FileFlagProperties.IsUnmappedZipFile)
  )

  //set isUnmappedZipFileVerified to true if the ParentFile is Verified or Downloaded
  const isUnmappedZipFileVerified: boolean = isFileFlagSet(
    parentFile?.flags!,
    FileFlagProperties.IsZipVerified
  )

  useEffect(() => {
    /** Clear Filter in DataGrid if there's any change in ParentFileId */
    ref.current?.instance.clearFilter('header')
  }, [parentFileIdValue])

  /** Bind context menu with the grid column */
  const setActionColumn = (cellData: ICellData<File>) => {
    const file = cellData.data!
    // Create the ID for the button, so we can attach our context menu to it.
    const buttonId = `btnMenuAction-${file.id}`
    // Disabling individual investor action menu items based on parent file's publish status
    const isSendEmailDisabled: boolean =
      !file.published! ||
      file.status === FileStatus.EmailInProgress ||
      file.status === FileStatus.UploadInProgress ||
      file.status === FileStatus.UploadError ||
      file?.emails === undefined ||
      file?.emails?.length === 0
    // Disabling 'Edit Email Template', 'Re-link Investors', 'Replace Document' action menu items based on parent file's inactive status
    const isDisabled: boolean = file.status === FileStatus.Inactive
    //Disable preview button when externalFileId is 0 (no file found in document manager)
    const disableDownload: boolean =
      file.status === FileStatus.Inactive || file.externalFileId === 0
    const isQEEDisabled: boolean = file.partnerEntityId ? false : true
    // Buttons for the context menu, with the appropriate actions.
    const contextMenu: MenuProps[] = [
      {
        text: 'Send Email',
        icon: 'mail',
        disabled:
          isSendEmailDisabled ||
          !permissionMap.get(Permissions.INVESTORDOCS_SEND_EMAIL),
        action: () => {
          setSelectedFiles([file])
          setShowMailModal(true)
        },
      },
      {
        text: 'Re-link Investor',
        icon: 'link',
        disabled:
          isDisabled || !permissionMap.get(Permissions.COMPOSITEDOCS_RELINK),
        action: () => {
          setSelectedFiles([file])
          setShowReLinkModal(true)
        },
      },
      {
        text: 'Quick Edit Entity',
        icon: 'flash_on',
        disabled:
          isQEEDisabled || !permissionMap.get(Permissions.ENTITY_EDIT_INVESTOR),
        action: () => {
          const quickEditData: QuickEditEntryModel = {
            fileId: file.id!,
            entityName: getValueForFileTag(
              commonFileTags.EntityName,
              file.tags
            )!,
            emails: file.emails!,
            partnerEntityId: file.partnerEntityId!,
            einNumber: getValueForFileTag(commonFileTags.PartnerId, file.tags)!,
          }
          setShowQEEModal(true)
          setQEEData(quickEditData)
        },
      },
      {
        visible: false,
        text: 'Replace Document',
        icon: 'undo',
        disabled: isDisabled,
      },
      {
        text: 'Download',
        icon: 'file_download',
        disabled:
          disableDownload ||
          !permissionMap.get(Permissions.INVESTORDOCS_DOWNLOAD),
        action: () => {
          if (!disableDownload) {
            handleDownloadClick(file)
          }
        },
      },
      {
        text: 'Delete File',
        icon: 'delete',
        disabled: !permissionMap.get(Permissions.INVESTORDOCS_DELETE),
        action: () => {
          setSelectedFiles([file])
          setShowDeleteModal(true)
        },
      },
    ]

    return (
      <>
        <Button
          id={buttonId}
          icon='overflow'
          data-testid='context-button'
          stylingMode='text'
        ></Button>
        <ContextMenu
          dataSource={contextMenu}
          showEvent='click'
          target={`#${buttonId}`}
          itemRender={renderMenuItem}
          onItemClick={(e) => {
            const item = e.itemData as MenuProps | undefined
            if (item?.action) {
              item.action()
            }
          }}
        />
      </>
    )
  }

  /** Set PDF Preview Column in Datagrid */
  const setPreviewColumn = (cellData: ICellData<File>) => {
    const file = cellData.data!
    // Create the ID for the button, so we can attach our context menu to it.
    const buttonId = `btnPreview-${file.id}`
    //Disable preview button when externalFileId is 0 (no file found in documnet manager)
    const previewDisabled: boolean =
      file?.status === FileStatus.Inactive || file.externalFileId === 0
    return (
      <Button
        id={buttonId}
        data-testid='preview-button'
        stylingMode='text'
        disabled={previewDisabled}
        onClick={() => {
          setPreviewFile({
            fileId: file?.id!,
            fileName: file?.externalFileName!,
          })
          setPreviewerVisible(true)
        }}
      >
        <Icon className='preview-btn' component={VisibilityOutlinedIcon}></Icon>
      </Button>
    )
  }

  /**Function to Execute when publish is clicked */
  const doPublish = () => {
    if (parentFile) {
      const fileStatus = getFileDisplayStatus(parentFile)
      if (
        fileStatus === DisplayFileStatus.NotReadyToPublish ||
        fileStatus === DisplayFileStatus.Inactive
      ) {
        commonDialog.showDialog({
          content: 'The file is not ready to publish.',
          dialogType: 'error',
        })
      } else if (
        fileStatus === DisplayFileStatus.PublishedWithError ||
        fileStatus === DisplayFileStatus.Published
      ) {
        commonDialog.showDialog({
          content: 'The file is already published.',
          dialogType: 'error',
        })
      } else {
        setPublishDialogVisible(true)
      }
    }
  }

  /** When any selections are made remove the selections when the files are successfully published */
  useEffect(() => {
    if (isPublishSucceeded) {
      ref.current?.instance.clearSelection()
    }
  }, [isPublishSucceeded, parentFile])

  // Buttons for the context menu, with the appropriate actions.
  const masterActionMenu: MenuProps[] = useMemo(() => {
    //disable Master Action Menu on page load(default)
    let isEmailDisabled: boolean = true
    let isDeleteDisabled: boolean = true
    let isReLinkInvDisabled: boolean = true
    let isDownloadDisabled: boolean = true
    let isResendEmailDisabled: boolean = true
    let isPublishDisabled: boolean =
      !parentFile ||
      parentFile.published ||
      parentFile.status === FileStatus.UploadError ||
      parentFile.clientInfo?.errorCount! >=
        parentFile.clientInfo?.childCount! ||
      parentFile.status === FileStatus.Inactive ||
      parentFile.clientInfo?.childFilesInactive! ||
      (!isUnmappedZipFileVerified && isUnmappedZipFile) ||
      isLoading

    //when selected fies are more then one
    if (selectedFiles.length > 0) {
      isEmailDisabled = false
      isDeleteDisabled = false
      isReLinkInvDisabled = false
      isDownloadDisabled = false

      //checking atleat one published item which can have mail id else disable the send email button
      isEmailDisabled = !selectedFiles.some(
        (f) =>
          f.published &&
          f.status !== FileStatus.EmailInProgress &&
          f.emails &&
          f.emails.length > 0
      )
      // Enable re-send email button if atleast one file status is 'EmailInProgress',
      // Otherwise disable re-send email button
      isResendEmailDisabled = !selectedFiles.some(
        (f) => f.status === FileStatus.EmailInProgress
      )
      //getting the inactive files, if found disable send email and re-link investors
      const inactiveFiles = selectedFiles.filter(
        (item) => item.status === FileStatus.Inactive
      )
      if (inactiveFiles.length === selectedFiles.length) {
        isEmailDisabled = true
        isReLinkInvDisabled = true
        isDownloadDisabled = true
        isResendEmailDisabled = true
      }

      // Check if all selected files are either inactive or have externalFileId of 0
      const allFilesInvalid = selectedFiles.every(
        (x) => x.status === FileStatus.Inactive || x.externalFileId === 0
      )

      // Disable the download button if all selected files are invalid
      if (allFilesInvalid) {
        isDownloadDisabled = true
      }
    }

    return [
      {
        text: 'Publish',
        icon: 'publish',
        disabled:
          isPublishDisabled ||
          !permissionMap.get(Permissions.COMPOSITEDOCS_PUBLISH),
        action: () => {
          doPublish()
        },
      },
      {
        text: 'Send Email',
        icon: 'mail',
        disabled:
          isEmailDisabled ||
          !permissionMap.get(Permissions.INVESTORDOCS_SEND_EMAIL),
        action: () => {
          if (!isEmailDisabled) {
            setShowMailModal(true)
          }
        },
      },
      {
        text: 'Resend Stuck Email',
        icon: 'autorenew',
        disabled:
          isResendEmailDisabled ||
          !permissionMap.get(Permissions.INVESTORDOCS_RESEND_EMAIL),
        action: () => {
          if (!isResendEmailDisabled) {
            setShowResendEmail(true)
          }
        },
      },
      {
        text: 'Re-link Investors',
        icon: 'link',
        disabled:
          isReLinkInvDisabled ||
          !permissionMap.get(Permissions.COMPOSITEDOCS_RELINK),
        action: () => {
          setShowReLinkModal(true)
        },
      },
      {
        text: 'Download',
        icon: 'file_download',
        disabled:
          isDownloadDisabled ||
          !permissionMap.get(Permissions.INVESTORDOCS_DOWNLOAD),
        action: (selectedFiles: File[]) => {
          if (!isDownloadDisabled) {
            handleMaterActionDownload(selectedFiles)
          }
        },
      },
      {
        text: 'Delete File',
        icon: 'delete',
        disabled:
          isDeleteDisabled ||
          !permissionMap.get(Permissions.INVESTORDOCS_DELETE),
        action: () => {
          setShowDeleteModal(true)
        },
      },
    ]

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    parentFile,
    isUnmappedZipFileVerified,
    isUnmappedZipFile,
    selectedFiles,
    isLoading,
  ])

  /** Download the selected file in Checkbox */
  const handleDownloadClick = async (selectedFile: File) => {
    try {
      await clientApi.downloadFile(selectedFile)
    } catch (err) {
      commonDialog.showDialog({
        title: 'Download Error',
        dialogType: 'error',
        content: `Error downloading file: ${err}`,
      })
    }
  }

  /** Download the selected files in Checkbox */
  const handleMaterActionDownload = async (selectedFiles: File[]) => {
    try {
      // remove incative files from download
      const downloadFiles = selectedFiles.filter(
        (item) =>
          item.status !== FileStatus.Inactive && item.externalFileId !== 0
      )
      await clientApi.bulkDownloadFiles(downloadFiles)
      // reset the checkbox selection
      ref.current?.instance.clearSelection()
    } catch (err) {
      commonDialog.showDialog({
        title: 'Download Error',
        dialogType: 'error',
        content: `Error downloading file: ${err}`,
      })
    }
  }

  /** Get the selected files in Checkbox */
  const onSelectionChanged = (data: SelectionChangedInfo<File, number>) => {
    if (data.selectedRowsData.length > 0) {
      setSelectedFiles(data.selectedRowsData)
    } else {
      setSelectedFiles([])
    }
  }

  const handleSearchChange = useCallback((value: string) => {
    setFilterString(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /** Get the list of records displayed in the child datagrid */
  const onContentReady = (e: EventInfo<dxDataGrid>) => {
    setDocumentCount(e.component.totalCount())
  }

  /** Set PopOver configuration to display */
  const setPopOverDisplay = (
    showPopOver: boolean,
    text: string,
    target: string
  ) => {
    setPopOverVisible(showPopOver)
    setPopOverText(text)
    setPopOverTarget(target)
  }

  /** Set Tooltip configuration to display */
  const setTooltipDisplay = (
    text: string,
    target: string,
    showTooltip: boolean
  ) => {
    setPopOverText(text)
    setTooltipTarget(target)
    setTooltipVisible(showTooltip)
  }

  /**Render tooltip text and align center */
  const renderTooltip = () => {
    return <div className='content-center'>{popOverText}</div>
  }

  /**Set flag to open modal windows to set the correct partner entity*/
  const handleSelectPartnerEntities = (file: File) => {
    setFileInfo(file)
    setShowLinkInvestorDialog(true)
  }

  /** Set partner name column value for sorting  */
  const setPartnerNameSortData = (file: File) => {
    // return getValueForFileTag(commonFileTags.PartnerName, file.tags)
    let result: string = ''
    let statusMessage = file.statusMessage!
    const emailStatus =
      file.status?.includes('Email') && !/link(ed)?\s+to/i.test(statusMessage)
    const inactive = file.status === 'Inactive'
    const missingFile = file.externalFileId === 0
    if (
      file.multipleEntitiesFound === true &&
      !emailStatus &&
      !inactive &&
      !missingFile
    ) {
      result = statusMessage
    } else if (/link(ed)?\s+to/i.test(statusMessage)) {
      let partnerName = statusMessage
        ?.replace('Linked to ', '')
        .replace('Linked To ', '')
        .trim()
      result = partnerName!
    } else {
      result = statusMessage
    }
    return result
  }

  /** Set Email Sent column value for sorting  */
  const setEmailSentSortData = (file: File) => {
    if (!file?.notificationDate!) {
      // we want error to be at last, if the value is "Error" then return max value while sorting in ascending
      if (file?.status === FileStatus.EmailError) {
        return Number.MAX_VALUE
      }
      // we want never to be at top, if the value is null for notificationDate then return min value while sorting in ascending
      return Number.MIN_VALUE
    } else {
      return new Date(file?.notificationDate).valueOf()
    }
  }

  /** Set LinkageType for File in Datagrid */
  const setLinkType = (file: File): string => {
    const linkageType = file?.linkageType

    switch (linkageType) {
      case LinkageType.ManuallyLinked:
        return 'Manual'
      case LinkageType.AutoLinked:
        return 'Auto'
      case LinkageType.SmartLinked:
        return 'Smart'
      case LinkageType.Unknown:
        if (file.partnerEntityId === 0) {
          return ' '
        } else {
          return 'N/A'
        }
      default:
        throw new Error('Unexpected Linkage Type')
    }
  }

  // Styles for manual linking that are enabled (have Permission)
  const commonLinkStyles = {
    opacity: 1,
    pointerEvents: 'auto' as const,
    cursor: 'pointer',
  }

  // Styles for links that are disabled(no permission)
  const disabledLinkStyles = {
    opacity: 0.5,
    pointerEvents: 'none' as const,
    cursor: 'default',
  }

  /** Set partner name for the column, if multiple partner found then display
   * Mutiple Investors Found as hyperlink and display tooltip
   */
  const setPartnerName = (cellData: ICellData<File>, canEditLink: boolean) => {
    if (cellData.rowType === 'data') {
      let file = cellData.data!
      let statusMessage = file.statusMessage!
      const emailStatus =
        file.status?.includes('Email') && !/link(ed)?\s+to/i.test(statusMessage)
      const inactive = file.status === 'Inactive'
      const missingFile = file.externalFileId === 0
      if (
        file.multipleEntitiesFound &&
        !emailStatus &&
        !inactive &&
        !missingFile
      ) {
        const partnerName = statusMessage?.replace(/^Linked to /i, '').trim()
        const matchResult = verifyMatchResult(file, partnerName!)

        if (
          matchResult ||
          partnerName.includes('Multiple Partner EIN/SSN Found.')
        ) {
          return (
            <Link
              to=''
              title={statusMessage}
              onClick={() => handleSelectPartnerEntities(file)}
              style={canEditLink ? commonLinkStyles : disabledLinkStyles}
            >
              {statusMessage}
            </Link>
          )
        } else {
          return setLinkedInvestor(
            `linkedInvestor-${file.id}`,
            MISMATCH_FILTER_TEXT,
            statusMessage,
            canEditLink,
            true,
            file
          )
        }
      } else if (/link(ed)?\s+to/i.test(statusMessage)) {
        const partnerName = statusMessage.replace(/^Linked to /i, '').trim()
        const matchResult = verifyMatchResult(file, partnerName)

        if (matchResult) {
          return (
            <span
              style={{ opacity: canEditLink ? 1 : 0.5 }}
              title={partnerName}
            >
              {partnerName}
            </span>
          )
        } else {
          return setLinkedInvestor(
            `linkedInvestor-${file.id}`,
            MISMATCH_FILTER_TEXT,
            partnerName,
            canEditLink
          )
        }
      } else if (statusMessage?.includes('New version is available')) {
        return (
          <span
            style={{ opacity: canEditLink ? 1 : 0.5 }}
            title={statusMessage}
          >
            {statusMessage}
          </span>
        )
      } else {
        return setLinkedInvestor(
          `linkedInvestor-${file.id}`,
          statusMessage,
          statusMessage,
          canEditLink
        )
      }
    }
  }

  /** Set Linked Investor Column text and display the tool tip */
  const setLinkedInvestor = (
    elementId: string,
    tooltipData: string,
    columnText: string,
    canEditLink?: boolean,
    isInvestorLinkingLink?: boolean,
    file?: File
  ) => {
    const handleMouseEnter = () => {
      if (!isUnmappedZipFile) {
        setTooltipDisplay(tooltipData, `#${elementId}`, true)
      }
    }

    return (
      <div
        id={elementId}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={() => setTooltipDisplay('', '', false)}
      >
        {!isUnmappedZipFile && (
          <InfoIcon
            sx={{
              color: '#5A5A5A',
              fontSize: 20,
              position: 'relative',
              top: '5px',
              marginRight: '5px',
            }}
          />
        )}
        {isInvestorLinkingLink ? (
          <Link
            to=''
            onClick={() => handleSelectPartnerEntities(file!)}
            style={canEditLink ? commonLinkStyles : disabledLinkStyles}
          >
            {columnText}
          </Link>
        ) : (
          <span style={canEditLink ? commonLinkStyles : disabledLinkStyles}>
            {columnText}
          </span>
        )}
      </div>
    )
  }

  /** Set the email id for the column, if multiple emails found then display
   * Multiple Emails Found as hyperlink and display the email(s) on mouse over as tooltip
   */
  const setInvestorEmail = (cellData: ICellData<File>) => {
    if (cellData.rowType === 'data' && cellData.data?.emails) {
      let toolTip = ''
      const controlId = 'email-' + cellData.data?.id
      if (cellData.data?.emails?.length === 1) {
        toolTip = cellData.data?.emails[0]
        return (
          <div>
            <CopyClipboard
              text={toolTip}
              tooltipText='Email(s) Copied!'
              targetControl={'#' + controlId}
              width={125}
            />
            <span
              id={controlId}
              onMouseEnter={() =>
                setPopOverDisplay(true, toolTip, '#' + controlId)
              }
              onMouseLeave={() => setPopOverDisplay(false, '', '')}
            >
              {toolTip}
            </span>
          </div>
        )
      } else if (cellData.data?.emails?.length > 1) {
        const controlId = 'email-' + cellData.data?.id
        toolTip = cellData.data?.emails[0]
        let emails = toolTip
        for (let index = 1; index < cellData.data?.emails?.length; index++) {
          const email = cellData.data.emails[index]
          toolTip += '\n' + email
          emails += ';' + email
        }
        return (
          <div>
            <CopyClipboard
              text={emails}
              tooltipText='Email(s) Copied!'
              targetControl={'#' + controlId}
              width={125}
            />
            <Link
              to=''
              id={controlId}
              onMouseEnter={() =>
                setPopOverDisplay(true, toolTip, '#' + controlId)
              }
              onMouseLeave={() => setPopOverDisplay(false, '', '')}
            >
              Multiple Emails Found
            </Link>
          </div>
        )
      }
    }
    return ''
  }

  //close the model and set setSelectedFiles state to empty
  const modalClose = () => {
    setShowDeleteModal(false)
    setShowMailModal(false)
    setShowReLinkModal(false)
    setSelectedFiles([])
    //Reset the grid selection
    ref.current?.instance.clearSelection()
  }

  /** Close the send mail modal, reset the selected files and grid selection */
  const sendMailClose = () => {
    setShowMailModal(false)
    setShowResendEmail(false)
    setSelectedFiles([])
    ref.current?.instance.clearSelection()
  }

  /** Close the relink modal,reset the selected files and grid selection */
  const relinkChildFilesClose = () => {
    setShowReLinkModal(false)
    setSelectedFiles([])
    ref.current?.instance.clearSelection()
  }

  /** Close the QEE modal,reset the selected file */
  const quickEditClose = () => {
    setShowQEEModal(false)
    setQEEData(undefined)
  }

  // Create the filter items for investor email column
  const emailFilterFn = (options: HeaderFilterDataSourceOptions) => {
    options.dataSource.postProcess = (items: CommonFilterHeader[]) => {
      const allEmails: string[] | undefined = []
      items.forEach((item, i) => {
        const emails = item.value as string[] | undefined
        if (emails) {
          emails.forEach((email) => {
            allEmails.push(email)
          })
        }
      })
      // Remove duplicates from allEmails
      const uniqueAllEmails = Array.from(new Set(allEmails))
      const result: CommonFilterHeader[] = uniqueAllEmails.map((email) => ({
        key: email,
        value: email,
        text: email,
      }))
      return result
    }
  }

  /** calculateFilterExpressionInvestorEmail is for filter with single or multiple emails */
  function calculateFilterExpressionInvestorEmail(
    this: Column,
    value: any,
    selectedFilterOperations: string,
    target: string
  ) {
    // If this is the headerFilter, then we want to modify it.
    if (target === 'headerFilter') {
      return [
        [getFilterMailValue, 'contains', getFilterMailValue(value)],
        'or',
        [getFilterMailValue, '=', getFilterMailValue(value)],
      ]
    }

    // Anything else, we should return as-is.
    return this.defaultCalculateFilterExpression(
      value,
      selectedFilterOperations,
      target
    )
  }

  /** Helper method to get the value used in filtering investor email on the data grid */
  function getFilterMailValue(rowData: File | string) {
    if (typeof rowData === 'string') {
      return rowData
    } else {
      return rowData.emails ? rowData.emails.toString() : ''
    }
  }

  /** Set Email sent date by formatting the date using momment.js library
   * Display the notification date field as tooltip
   */
  const setEmailSentDate = (cellData: ICellData<File>) => {
    if (cellData.rowType === 'data') {
      const file = cellData.data
      if (file?.status === FileStatus.EmailInProgress) {
        return <span title={emailInProgressText}>{emailInProgressText}</span>
      } else if (!file?.notificationDate!) {
        if (file?.status === FileStatus.EmailError) {
          return <span title='Error'>{'Error'}</span>
        }
        return <span title='never'>{'never'}</span>
      } else {
        const notificationDate = new Date(file?.notificationDate)
        // append 'Error' text to the email sent column data if there's any email error
        const displayDate =
          (file?.status === FileStatus.EmailError ? errorText : '') +
          formatDate(notificationDate)
        const titleDate = getFormattedDateWithNeverOption(
          notificationDate,
          DateFormat.longDateTimeFormat
        )
        return <span title={titleDate}>{displayDate}</span>
      }
    }
    return ''
  }

  /** Calculate the email sent date- from notification date field based on the
   * Files status - EmailInProgress, EmailError  */
  const calculateEmailSent = (cellData: File) => {
    if (cellData.status === FileStatus.EmailInProgress) {
      return emailInProgressText
    } else {
      if (
        !cellData.notificationDate &&
        cellData.status === FileStatus.EmailError
      ) {
        return 'Error'
      }
      return (
        (cellData.status === FileStatus.EmailError ? errorText : '') +
        getFormattedDateWithNeverOption(
          cellData.notificationDate,
          DateFormat.isoDateTimeFormat
        )
      )
    }
  }

  /** Create the filter items for email sent column */
  const dateFilterFn = (options: HeaderFilterDataSourceOptions) => {
    options.dataSource.paginate = false
    options.dataSource.postProcess = getHeaderFilterDates
  }

  /** Calculate Email sent column Filter expression for 'EmailSent' column filter */
  function calculateEmailSentDateFilterExpression(
    this: Column,
    value: any,
    selectedFilterOperations: string,
    target: string
  ) {
    if (target === 'headerFilter') {
      return [[getFormattedDate, '=', value]]
    }

    return this.defaultCalculateFilterExpression(
      value,
      selectedFilterOperations,
      target
    )
  }

  function getFormattedDate(rowData: File) {
    return getFormattedDateWithOutTimeStampWithNeverOption(
      rowData.notificationDate,
      DateFormat.shortDateFormat
    )
  }

  /** Calculate downloaded column Filter expression */
  function calculateDownloadedFilterExpression(
    this: Column,
    value: any,
    selectedFilterOperations: string,
    target: string
  ) {
    if (target === 'headerFilter') {
      return [
        [
          (rowData: File): boolean => !!rowData.downloadedByInvestor,
          '=',
          value,
        ],
      ]
    }

    return this.defaultCalculateFilterExpression(
      value,
      selectedFilterOperations,
      target
    )
  }

  // Create filter items for downloaded column
  const downloadedFilterFn = (data: HeaderFilterDataSourceOptions) => {
    data.dataSource.postProcess = (results) => {
      // filter hearder filter value that has (blanks) in text from result
      results = results.filter((r) => r.text !== '(Blanks)')

      // push the intended return value for the text "Downloaded" and "NotDownloaded"
      results.push({
        text: 'Downloaded',
        value: true,
      })
      results.push({
        text: 'Not Downloaded',
        value: false,
      })
      return results
    }
  }

  // Create the filter items for linked investor columns.
  const linkedInvestorFilterFn = (options: HeaderFilterDataSourceOptions) => {
    //filtering the files from recoil state where statusMessage have 'linked to' text
    const filterdMultipleEntityItems = files.filter((x) =>
      x.statusMessage?.toLowerCase().includes('linked to')
    )

    //checking for mismatch for linked investor which is not matching with current investor
    const isMismatch = filterdMultipleEntityItems?.some(
      (file: File) =>
        getInvestorName(file)?.toLowerCase() !==
        file.statusMessage?.toLowerCase().replace('linked to ', '').trim()
    )

    // using isMismatchFilterAdded flag to avoid to duplicate addition of MismatchFilterText as filter item because
    // post process is using lazy loading and at a time its only loading maximum 20 item
    let isMismatchFilterAdded = false
    options.dataSource.postProcess = (items: CommonFilterHeader[]) => {
      const result = new Array<CommonFilterHeader>()
      //adding the filter item if found the mismatched files if linked investor is not matching with current investor
      if (isMismatch && !isMismatchFilterAdded) {
        result.push({
          key: MISMATCH_FILTER_TEXT,
          value: MISMATCH_FILTER_TEXT,
          text: MISMATCH_FILTER_TEXT,
        })
        isMismatchFilterAdded = true
      }
      items.forEach((item: CommonFilterHeader) => {
        const linkedInvestor = item.value as string[] | undefined
        if (linkedInvestor) {
          result.push(item)
        }
      })

      // If any of the file is unmappedZipFile remove 'Investor Name and Linked Investor do not match' from the filter
      if (isUnmappedZipFile) {
        const index = result.findIndex((i) => i.value === MISMATCH_FILTER_TEXT)
        // Remove 'Investor Name and Linked Investor do not match' from the array by index
        result.splice(index, 1)
      }

      return result
    }
  }

  /** Calculate Matching Criteria colum Filter expression */
  function calculateMatchingCriteriaFilterExpression(
    this: any,
    value: any,
    selectedFilterOperations: string,
    target: string
  ) {
    // if the target is header filter and the value is 'Blank', calculate and return value for it
    if (target === 'headerFilter' && value === 'Blank') {
      return [[getMacthingCriteria, '=', undefined]]
    }

    // Anything else, we should return as-is.
    return this.defaultCalculateFilterExpression(
      value,
      selectedFilterOperations,
      target
    )
  }

  /** customizing Matching Criteria Header filter to show value for Blank  */
  const matchingCriteriaHeaderFilter = (
    data: HeaderFilterDataSourceOptions
  ) => {
    data.dataSource.postProcess = (results) => {
      // filter hearder filter value that has (blanks) in text from result
      results = results.filter((r) => r.text !== '(Blanks)')

      // push the intended return value for the text 'Blank'
      results.push({
        text: 'Blank',
        value: 'Blank',
      })
      return results
    }
  }

  /** Calculate Link Type column Filter expression */
  function calculateLinkTypeFilterExpression(
    this: any,
    value: any,
    selectedFilterOperations: string,
    target: string
  ) {
    // if the target is header filter and the value is empty string, calculate and return value for it
    if (target === 'headerFilter' && value === '') {
      return [[setLinkType, '=', undefined]]
    }

    return this.defaultCalculateFilterExpression(
      value,
      selectedFilterOperations,
      target
    )
  }

  const linkTypeHeaderFilter = (data: HeaderFilterDataSourceOptions) => {
    data.dataSource.postProcess = (results) => {
      // Check if "Blank" is already present
      const blankExists = results.some((r) => r.text === ' ')

      // Remove empty string entries
      const filteredResults = results.filter((r) => r.text.trim() !== '')

      // If "Blank" is not present, add the "Blank" entry
      if (blankExists) {
        filteredResults.push({
          text: 'Blank',
          value: ' ',
        })
      }

      return filteredResults
    }
  }

  /** calculateFilterExpressionLinkedInvestor is for filter with single or multiple emails */
  function calculateFilterExpressionLinkedInvestor(
    this: any,
    value: any,
    selectedFilterOperations: string,
    target: string
  ) {
    // If this is the headerFilter, then we want to modify it.
    if (target === 'headerFilter') {
      // condition for value='Investor Name and Linked Investor do not match' and filterType should not be 'exclude'
      if (value === MISMATCH_FILTER_TEXT && this.filterType !== 'exclude') {
        return [[getFilterLinkedInvestorValueForMismatch, '=', true]]
        // checking the filterValues from the current filter, it should not have the the text 'Investor Name and Linked Investor do not match'
        // and filterType should be exclude
      } else if (
        !this.filterValues.includes(MISMATCH_FILTER_TEXT) &&
        this.filterType === 'exclude'
      ) {
        return [
          [getFilterLinkedInvestorValueForMismatch, '=', false],
          'and',
          [
            [
              getFilterLinkedInvestorValue,
              'contains',
              getFilterLinkedInvestorValue(value),
            ],
            'or',
            [
              getFilterLinkedInvestorValue,
              '=',
              getFilterLinkedInvestorValue(value),
            ],
          ],
        ]
      } else {
        //default return
        return [
          [
            getFilterLinkedInvestorValue,
            'contains',
            getFilterLinkedInvestorValue(value),
          ],
          'or',
          [
            getFilterLinkedInvestorValue,
            '=',
            getFilterLinkedInvestorValue(value),
          ],
        ]
      }
    }

    // Anything else, we should return as-is.
    return this.defaultCalculateFilterExpression(
      value,
      selectedFilterOperations,
      target
    )
  }

  /** Helper method to get the value used in filtering for linked investor on the data grid. */
  function getFilterLinkedInvestorValue(rowData: File | string) {
    if (typeof rowData === 'string') {
      return rowData
    } else {
      return rowData.statusMessage
    }
  }

  /** Helper method to get the value used in filtering for linked investor mismatch on the data grid. */
  function getFilterLinkedInvestorValueForMismatch(rowData: File | string) {
    if (typeof rowData === 'string') {
      return false
    } else {
      if (rowData.statusMessage?.toLowerCase().includes('linked to')) {
        if (
          getInvestorName(rowData)?.toLowerCase() !==
          rowData.statusMessage?.toLowerCase().replace('linked to ', '').trim()
        ) {
          return true
        }
      }
    }
    return false
  }

  /** set downloaded checkbox column of datagrid */
  const setDownloadedCheckBox = (cellData: ICellData<File>) => {
    const isDownloaded: boolean = !!cellData.data?.downloadedByInvestor
    return (
      <div className='downloaded-icon'>
        {isDownloaded && <CheckCircleOutlineOutlinedIcon color='primary' />}
        {!isDownloaded && <RadioButtonUncheckedOutlinedIcon color='primary' />}
      </div>
    )
  }

  /** Set downloaded column value for sorting  */
  const setDownloadSortData = (cellData: File): number => {
    if (!!cellData.downloadedByInvestor) {
      return Number.MIN_VALUE
    }
    return Number.MAX_VALUE
  }

  /** Set the background color based on status value
   * Display the status field as tooltip
   */
  const setStatusStyle = useCallback(() => {
    if (parentFile) {
      var status = getFileDisplayStatus(parentFile)
      let cellClass = ''
      if (
        status === DisplayFileStatus.Published ||
        status === DisplayFileStatus.PublishedWithError
      ) {
        cellClass = 'statusColumnGreen'
      } else if (
        status === DisplayFileStatus.ReadyToPublish ||
        status === DisplayFileStatus.ReadyToPublishWithError
      ) {
        cellClass = 'statusColumnBlue'
      } else {
        cellClass = 'statusColumnGrey'
      }
      //if statusMessage has some value then we are showing infoIcon with tooltip
      if (status) {
        return (
          <span title={status} className={cellClass}>
            {status}
          </span>
        )
      }
    }
    return ''
  }, [parentFile])

  useEffect(() => {
    // Refresh the data grid when the status changes to update the action menu button states.
    // The Status changes when it is published which is recognized by isPublishSucceeded
    // The refresh is dependent on parentFile to ensure it only occurs with status changes,
    //  preventing excessive refreshes that may happen if childFiles were used as the trigger.

    ref.current?.instance.refresh()
  }, [isPublishSucceeded, parentFile])

  return (
    <div className='ip-table'>
      <DataGrid
        ref={ref}
        dataSource={files}
        keyExpr='id'
        showBorders={true}
        columnAutoWidth={false}
        height={dgheight}
        repaintChangesOnly={true}
        showColumnLines={false}
        showRowLines={true}
        onContentReady={onContentReady}
        onSelectionChanged={onSelectionChanged}
        onCellPrepared={addToolTipOnCellPrepared}
        wordWrapEnabled={false}
        allowColumnResizing={true}
        noDataText={
          isLoading && files.length === 0 && documentCount === 0
            ? 'Loading...'
            : !isLoading && (files.length === 0 || documentCount === 0)
            ? 'No Data Found.'
            : ''
        }
      >
        <SearchPanel
          visible={true}
          defaultText={removeWhitespacesDashes(defaultSearchText)}
          text={removeWhitespacesDashes(filterString)}
          width={400}
          placeholder='Search...'
          onTextChange={handleSearchChange}
        />
        <Scrolling mode='virtual' rowRenderingMode='virtual' />
        <Paging defaultPageSize={8} />
        <Selection mode='multiple' showCheckBoxesMode={'always'} />
        <Sorting mode='single' />
        <LoadPanel enabled={true} />
        <HeaderFilter visible={true} allowSearch={true} />
        <Column
          cellRender={setPreviewColumn}
          allowSearch={false}
          allowSorting={false}
          width={'4%'}
          allowFiltering={false}
          alignment='center'
        />
        <Column
          dataField='externalFileName'
          caption='File Name'
          dataType='string'
          allowSearch={true}
          allowSorting={true}
          width={'16%'}
          defaultSortOrder='asc'
          allowFiltering={true}
        />
        {/* For unbound column specifically required 'allowSearch = true'  */}
        {/* Investor Name is visible and searchable if the parentFile is not UnmappedZipFile */}
        <Column
          calculateCellValue={getInvestorName}
          caption='Investor Name'
          dataType='string'
          allowSearch={!isUnmappedZipFile}
          allowSorting={true}
          width={'16%'}
          allowFiltering={true}
          visible={!isUnmappedZipFile}
        />
        {/* Doc - Match Criteria is visible and searchable if the parentFile is UnmappedZipFile */}
        <Column
          calculateCellValue={getMacthingCriteria}
          caption='Doc - Match Criteria'
          dataType='string'
          allowSearch={isUnmappedZipFile}
          allowSorting={true}
          width={'7%'}
          allowFiltering={true}
          calculateFilterExpression={calculateMatchingCriteriaFilterExpression}
          visible={isUnmappedZipFile}
        >
          <HeaderFilter dataSource={matchingCriteriaHeaderFilter} />
        </Column>
        {/* Entity Manager - Match Criteria is visible and searchable if the parentFile is UnmappedZipFile */}
        <Column
          dataField='entityMatchingCriteria'
          caption='Entity Manager - Match Criteria'
          dataType='string'
          allowSearch={isUnmappedZipFile}
          allowSorting={true}
          width={'9%'}
          allowFiltering={true}
          calculateFilterExpression={calculateMatchingCriteriaFilterExpression}
          visible={isUnmappedZipFile}
        >
          <HeaderFilter dataSource={matchingCriteriaHeaderFilter} />
        </Column>
        {/* Investor SSN# or EIN# column is not visible in grid but it's searchable */}
        <Column
          calculateCellValue={getInvestorId}
          caption='SSN/EIN'
          dataType='string'
          allowSearch={true}
          allowSorting={true}
          visible={false}
          allowFiltering={true}
        />
        <Column
          calculateSortValue={setPartnerNameSortData}
          calculateCellValue={setPartnerNameSortData}
          caption='Linked Investor'
          dataType='string'
          allowSearch={true}
          data-includeTooltip={isUnmappedZipFile}
          allowSorting={true}
          cellRender={(cellData) =>
            setPartnerName(
              cellData,
              permissionMap.get(Permissions.INVESTORDOCS_EDIT_LINK)!
            )
          }
          width={'12%'}
          allowFiltering={true}
          calculateFilterExpression={calculateFilterExpressionLinkedInvestor}
        >
          <HeaderFilter dataSource={linkedInvestorFilterFn} />
        </Column>
        <Column
          dataField='linkageType'
          caption='Link Type'
          dataType='string'
          width={'7%'}
          allowSorting={true}
          allowFiltering={true}
          calculateCellValue={setLinkType}
          allowSearch={true}
          calculateFilterExpression={calculateLinkTypeFilterExpression}
        >
          <HeaderFilter dataSource={linkTypeHeaderFilter} />
        </Column>
        <Column
          caption='Investor Email'
          dataType='string'
          dataField='emails'
          allowSorting={true}
          cellRender={setInvestorEmail}
          data-includeTooltip={false}
          width={'16%'}
          allowFiltering={true}
          calculateFilterExpression={calculateFilterExpressionInvestorEmail}
        >
          <HeaderFilter dataSource={emailFilterFn} />
        </Column>
        <Column
          caption='Email Template'
          dataType='string'
          dataField='emailTemplate'
          allowSorting={true}
          width={'8%'}
        />
        <Column
          caption='Email Sent'
          dataType='string'
          calculateCellValue={calculateEmailSent}
          allowSearch={true}
          allowSorting={true}
          cellRender={setEmailSentDate}
          data-includeTooltip={false}
          calculateSortValue={setEmailSentSortData}
          width={'9%'}
          allowFiltering={true}
          calculateFilterExpression={calculateEmailSentDateFilterExpression}
        >
          <HeaderFilter dataSource={dateFilterFn} />
        </Column>
        <Column
          caption='Downloaded'
          alignment='center'
          allowSearch={false}
          allowSorting={true}
          allowFiltering={true}
          cellRender={setDownloadedCheckBox}
          calculateSortValue={setDownloadSortData}
          calculateFilterExpression={calculateDownloadedFilterExpression}
          width={'7%'}
        >
          <HeaderFilter allowSearch={false} dataSource={downloadedFilterFn} />
        </Column>
        <Column
          caption='Action'
          cellRender={setActionColumn}
          allowSearch={false}
          allowSorting={false}
          width={'5%'}
          allowFiltering={false}
        />
        <Toolbar>
          <Item location='before'>
            <Grid
              container
              item
              xs={10}
              minWidth={800}
              justifyContent={'flex-start'}
            >
              <Grid item xs={0.5}>
                <Button
                  onClick={() =>
                    navigate(getParentFileListRoute(groupId, partnershipId))
                  }
                  stylingMode='outlined'
                  data-testid='btnBack'
                >
                  <span className='dx-icon-arrowleft'></span>
                </Button>
              </Grid>
              <Grid item xs={2} className='child-file-toolbar'>
                <CounterDisplay
                  count={documentCount}
                  isVisible={!isLoading}
                  title='Documents'
                />
              </Grid>
              <Grid item xs={6} className='file-status-indicator'>
                {setStatusStyle()}
              </Grid>
            </Grid>
          </Item>
          {/* CLEAR FILTERS */}
          <Item name='clearFilters'>
            <ClearFiltersButton gridRef={ref} />
          </Item>
          <Item name='searchPanel' />
          <Item location='after'>
            <Grid container minWidth={60} justifyContent='flex-end'>
              <Button
                stylingMode='outlined'
                id='masterActionBtn'
                data-testid='btnMasterAction'
              >
                <span className='dx-icon-overflow'></span>
              </Button>
              <ContextMenu
                width={150}
                dataSource={masterActionMenu}
                showEvent='mouseenter'
                target='#masterActionBtn'
                itemRender={renderMenuItem}
                onItemClick={(e) => {
                  const item = e.itemData as MenuProps | undefined
                  if (item?.action) {
                    item.action(selectedFiles)
                  }
                }}
              />
            </Grid>
          </Item>
        </Toolbar>
      </DataGrid>
      <Popover
        target={popOverTarget}
        visible={popOverVisible}
        width={280}
        contentRender={renderTooltip}
      ></Popover>
      <Tooltip
        target={tooltipTarget}
        visible={tooltipVisible}
        contentRender={renderTooltip}
      />
      <DeleteChildFileConfirmation
        files={selectedFiles}
        isVisible={showDeleteModal}
        onCancel={modalClose}
        onConfirm={modalClose}
        parentFileId={parentFileIdValue}
      ></DeleteChildFileConfirmation>
      <SendEmailConfirmation
        files={selectedFiles}
        isVisible={showMailModal}
        entityGroupId={groupIdValue}
        onCancel={modalClose}
        onConfirm={sendMailClose}
      ></SendEmailConfirmation>
      <RelinkChildFileConfirmation
        files={selectedFiles}
        isVisible={showReLinkModal}
        entityGroupId={groupIdValue}
        onCancel={modalClose}
        onConfirm={relinkChildFilesClose}
      ></RelinkChildFileConfirmation>
      {showQEEModal && (
        <QuickEditEntry
          detail={quickEditData!}
          onCancel={quickEditClose}
          onSave={quickEditClose}
          selectionCacheId={undefined}
          onComplete={quickEditClose}
        ></QuickEditEntry>
      )}
      {showLinkInvestorDialog && (
        <LinkInvestorDialog
          onSave={() => setShowLinkInvestorDialog(false)}
          onCancel={() => setShowLinkInvestorDialog(false)}
          file={fileInfo}
          isUnmappedZip={isUnmappedZipFile}
        ></LinkInvestorDialog>
      )}
      <ResendEmailConfirmation
        isVisible={showResendEmail}
        files={selectedFiles}
        entityGroupId={groupIdValue}
        onConfirm={sendMailClose}
      ></ResendEmailConfirmation>
      {previewerVisible && (
        <PDFPreview
          fileName={previewFile?.fileName!}
          fileId={previewFile?.fileId!}
          onCancel={() => setPreviewerVisible(false)}
        />
      )}
      {parentFile && (
        <PublishFiles
          selectedFiles={[parentFile!]}
          isVisible={publishDialogVisible}
          onCancel={publishModalClose}
          onConfirm={publishModalConfirm}
          setPublishSucceededinParent={setPublishSucceeded}
        ></PublishFiles>
      )}
    </div>
  )
}
